<template>
  <div class="loader-1 text-center d-flex justify-items-center" v-if="$store.state.isLoading">
    <b-spinner class="m-auto" label="Busy"></b-spinner>
  </div>
</template>

<script>
export default {

};
</script>

<style>
.loader-1{
  z-index: 9999;
  position: fixed;
  top:0;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  opacity: .7;

}
</style>