<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t("title.province") }}</h4>
          </div>
          <div class="col-sm-6 text-right">
            <ol class="breadcrumb justify-content-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>
                  {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.province") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-12 mx-auto mt-5">
            <div class="row align-items-center mb-4">
              <div class="col-3 mr-auto" v-if="hasPermission('create-province')">
                <router-link to="/province/create" class="btn btn-primary rounded-pill">
                  <i class="nav-icon fa fa-plus-square mr-2"></i>
                  {{ $t("button.add_new") }}
                </router-link>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <select class="custom-select search_item rounded-pill" v-model="country_id">
                  <option selected disabled :value="null">
                    {{ $t("title.select_country") }}
                  </option>
                  <option :value="0">
                    <router-link to="?test">{{ $t("title.all_country") }}</router-link>
                  </option>
                  <option v-for="(c, index) in countries" :key="index" :value="c.id">
                    <router-link to="">{{ c[`name_${$i18n.locale}`] }}</router-link>
                  </option>
                </select>
              </div>
            </div>

            <table class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th style="width: 100px">{{ $t("land-detail.no") }}</th>
                  <th v-column-sortable:[`name_${$i18n.locale}`]>
                    {{ $t("title.province_name") }}
                  </th>
                  <th v-column-sortable:country_id>
                    {{ $t("title.country") }}
                  </th>
                  <th>{{ $t("title.land_count") }}</th>
                  <th v-if="
                    hasPermission('detail-province') ||
                    hasPermission('update-province') ||
                    hasPermission('delete-province')
                  ">
                    {{ $t("title.action") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="(p, index) in countriesFilter" :key="index">
                  <td>{{ currentPage * 10 - 10 + (index + 1) }}</td>
                  <td>{{ p[`name_${$i18n.locale}`] }}</td>
                  <td>
                    <span v-for="c in countries" :key="c.id">
                      <p v-if="c.id == p.country_id">
                        {{ c[`name_${$i18n.locale}`] }}
                      </p>
                    </span>
                  </td>
                  <td>{{ countriesFilter[index]["lands_count"] }}</td>
                  <td v-if="
                    hasPermission('detail-province') ||
                    hasPermission('update-province') ||
                    hasPermission('delete-province')
                  ">
                    <router-link :to="{ name: 'provinceDetail', params: { id: p.id } }" class="btn btn-info btn-sm mr-2"
                      v-if="hasPermission('detail-province')">
                      {{ $t("title.detail") }}
                    </router-link>
                    <router-link :to="{ name: 'province', params: { id: p.id } }" class="btn btn-primary btn-sm"
                      v-if="hasPermission('update-province')">
                      <i class="fas fa-edit"></i>
                    </router-link>
                    <button class="btn btn-danger btn-sm ml-2 show_confirm" @click="deleteProvince(p.id)"
                      :disabled="p.lands != 0" v-if="hasPermission('delete-province')">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr v-if="!countriesFilter.length">
                  <td colspan="5" class="text-center" v-if="isLoading">
                    <b-spinner label="Spinning"></b-spinner>
                  </td>
                  <td colspan="5" class="text-center" v-else>
                    {{ $t("title.no_result") }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="countriesFilter != ''">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage">
              </b-pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import columnSortable from "vue-column-sortable";
import hasPer from "../../services/permission";

export default {
  data() {
    return {
      country_id: null,
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      resultQuery: [],
    };
  },

  computed: {
    /****
     * province data
     */
    provinces: {
      get() {
        return this.$store.state.province.provinces;
      },
    },
    /********
     * countries data
     */
    countries: {
      get() {
        return this.$store.state.country.countries;
      },
    },
    /******
     * permssion data
     */
    permission: {
      get() {
        return this.$store.state.permission;
      },
    },
    /*****
     * search by country
     */
    countriesFilter() {
      var results = this.provinces.filter((pro) => {
        if (this.country_id) {
          return pro.country_id === this.country_id;
        } else {
          return this.provinces;
        }
      });
      var datas = results.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
      this.resultQuery = datas;
      return datas;
    },
    /******
     * count pagination
     */
    totalRows() {
      if (this.country_id) {
        return this.resultQuery.length;
      } else {
        return this.provinces.length;
      }
    },
    permission: {
      get() {
        return this.$store.state.permission
      }
    }
  },

  mounted() {
    this.getprovinces();
    this.getCountries();
    /********
   * check permssion
   */
    if (this.permission.length) {
      if (!this.hasPermission("province-lists")) {
        this.$router.push({ path: "/dashboard" });
      }
    }
  },
  methods: {
    /****
     * method check permission
     */
    hasPermission(per) {
     return hasPer(this.permission,per);
    },
    /******
     * get all data from provices
     */
    getprovinces() {
      this.$store.dispatch("province/getProvinces");
    },
    /******
     * get all data from countries
     */
    getCountries() {
      this.$store.dispatch("country/getCountries");
    },
    /*******
     * Delete one record
     */
    deleteProvince(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.dispatch("province/onDelete", id);
        }
      });
    },
    /***
     * sort data head table
     */
    orderBy(fn) {
      this.provinces.sort(fn);
    },
  },
  directives: {
    columnSortable,
  },
};
</script>

<style>
</style>
