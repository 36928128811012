<template>
  <div class="position-relative">
    <div class="content-header">
      <div class="container-xxl">
        <div class="row">
          <div class="col-sm-6 d-flex align-items-center">
            <router-link :to="{name:'DashboardDetail',params:{id:land.province_id}}"><BackIcon/></router-link>
            <h4 class="m-0 text-dark">{{ $t("title.detail") }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>
                  {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/lands" class="text-dark">
                  {{ $t("title.list_land") }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.detail") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row my-5">
          <Slide />
          <div class="col-lg-4 col-12 mt-lg-0 mt-4">
            <h4 class="mb-2 pb-2">
              <strong>{{ $t("title.land_news") }}</strong>
            </h4>
            <table class="table">
              <tr>
                <th class="mr-1">លេខប័ណ្ណកាន់កាប់ <br>({{ land.type_land_title }}) : </th>
                <td v-if="land.land_title_number">
                  {{ land.land_title_number }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("Name") }} : </th>
                <td v-if="[`location_${$i18n.locale}`]">
                  {{ land[`land_name_${$i18n.locale}`] }}
                </td>
              </tr>
              <tr>
                <th class="mr-1">
                  {{ $t("land-detail.description.size") }} :
                </th>
                <td v-if="land.square_meter">
                  {{ land.square_meter | comma }} ម៉ែត្រការ៉េ
                </td>
              </tr>
              <tr>
                <th class="mr-1">
                  {{ $t("land-detail.localtion") }} :
                </th>
                <td v-if="land.square_meter">
                  {{ land[`location_${$i18n.locale}`] }}
                </td>
              </tr>
            </table>
            <div class="content"></div>
          </div>
        </div>

        <div class="row overflow-hidden">
          <div class="col-md-8 col-12 overflow-auto">
            <table class="table table-striped table-bordered">
              <thead>
                <tr class="text-center">
                  <th style="width: 100px">{{ $t("land-detail.no") }}</th>
                  <th>{{ $t("title.land_reference") }}</th>
                  <th style="width: 100px">{{ $t("land-detail.download") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, index) in docLands" :key="index">
                  <td class="text-center">{{ d.no }}</td>
                  <td class="px-3" v-if="[`title_${$i18n.locale}`]">
                    {{ d[`title_${$i18n.locale}`] }}
                  </td>
                  <td class="text-center">
                    <router-link :to="{ path: `/land/${land.id}/doc/${index}` }" class="btn btn-primary btn-sm">
                      {{ $t('button.open') }}
                    </router-link>
                  </td>
                </tr>
                <tr v-if="docLands == 0">
                  <td colspan="4" class="text-center">
                    {{ $t("title.no_result") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <input type="hidden" v-model="role_user">
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import Slide from "../../components/land-slide.vue";
import UrlForShow from "../../services/show-url";
import Loader from '../../components/loader.vue';
import hasPer from "../../services/permission";
import BackIcon from "@/components/my/icons/BackIcon"

export default {
  components: {
    Slide,
    Loader,
    BackIcon
  },
  data() {
    return {
      doc_land: {
        name: '',
        file: [],
        land_type_id: ''
      },
      landType: [],
      path: UrlForShow(),
      role_user: '',
      isLoading: false,
      land_type_name: '',
      old_name: '',
      file_id: '',
      isDownload: false
    };
  },
  computed: {
    /******
 * permssion data
 */
    permission: function () {
      return this.$store.state.permission;
    },
    land:function(){
      return this.$store.state.lands.land;
    },
    docLands:function(){
      return this.$store.state.lands.documents;
    }
  },
  mounted() {
    this.getLand();
    if (this.permission.length) {
      /***
       * check permission land detail 
       */
      if (!this.hasPermission("detail-land")) {
        this.$router.push({ path: "/dashboard" });
      }
    }
  },

  methods: {
    /****
   * method check permission
   */
    hasPermission(per) {
      return hasPer(this.permission, per)
    },
    /******
     * get a land
     */
    getLand() {
      this.$store.dispatch('lands/getLand');
    },
    dateTime(value) {
      return moment(value).format("DD-MMM-YY");
    }
  },
};

</script>

<style lang="scss">
.landSlide {
  &_item {
    width: 100%;
    height: 300px;
    overflow: hidden;
    padding: 0 5px;

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }

  .slick-prev {
    left: 10px;
    z-index: 9;
  }

  .slick-next {
    right: 10px;
  }
}

.td-name {
  white-space: pre-line;
}

.pdf-download {
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}
</style>