<template>
  <div>
    <div class="content-header" style="font-size: 14px !important;">
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-sm-6 d-flex m-0">
            <p class="m-0 text-dark">{{ doc_link[`title_${$i18n.locale}`] }}</p>  

              <button @click="download({ id: doc_link.id, label: doc_link[`title_${$i18n.locale}`] + `.pdf` })"
              style="font-size: 10px; margin-left: 2rem;"
                class="btn btn-primary btn-xs pdf-download">
                <i class="fa fa-download" aria-hidden="true"></i> Download
                <span v-if="isDownload">
                  <b-spinner label="Text Centered"></b-spinner>
                </span>
              </button>

          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>
                  {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="`/land/detail/${landID}`" class="text-dark">
                  {{ land_name }}
                </router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
   
    <embed :src='path + doc_link.link_file + `#toolbar=0`' type="application/pdf" width="100%" height="800px">
  </div>
</template>

<script>
import UrlForShow from "../../services/show-url";

export default {
  data() {
    return {
      path: UrlForShow(),
      doc_link: [],
      landID: this.$route.params.landID,
      land_name: '',
      isLoading: false,
      isDownload:false
    }
  },

  mounted() {
    this.getLand()
  },
  
  methods: {
    getLand() {
      this.isLoading = true;
      axios
        .get(`/lands/${this.$route.params.landID}`)
        .then((res) => {
          this.land_name = res.data[`land_name_${this.$i18n.locale}`];
          var land_doc = res.data.doc_files[this.$route.params.id];
          this.doc_link = land_doc;
        })
        .catch((err) => { })
        .finally(() => {
          this.isLoading = false;
        });
    },

    download({ id, label }) {
      this.isDownload = true;
      axios.get(`/download/${id}`, {
        responseType: "blob"
      }).then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();

        this.$refs['preview'].hide();
      })
      .catch((err) =>{})
      .finally(() => {
        this.isDownload = false
      })
    }
  }
}
</script>

<style>

</style>