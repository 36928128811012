// import './css/main.css'
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import en from './lang/en.json'
import kh from './lang/kh.json'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import VueSwal from 'vue-swal';
Vue.use(VueCookies, { expire: '7d'})
require("./assets/plugins/LC-Lightbox-LITE/css/lc_lightbox.min.css");
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-advanced-cropper/dist/style.css';
import '../src/assets/css/lightbox.css';
import 'bootstrap'

import store from './store';

Vue.use(VueI18n)
Vue.use(VueSwal);
Vue.use(IconsPlugin)
Vue.use(BootstrapVue)

window.$ = window.jQuery = require('jquery');
window.$ = $.extend(
    require('./assets/plugins/LC-Lightbox-LITE/js/lc_lightbox.lite'),
    require('jquery-ui'),
  );


export const messages = {
  en,
  kh
}

export const i18n = new VueI18n({
  locale: 'kh',
  messages
})
if ($cookies.get("token")) {
  store.dispatch('getUser')
}
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
