<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">{{ $t("title.districts") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">{{
                    $t("title.home")
                }}</router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ $t("title.districts") }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <section class="content position-relative mt-5">
      <div class="container-fluid">
        <div class="row align-items-center mb-2">
          <div class="col-md-10 col-12 mx-auto">
            <div class="row">
              <div class="col-sm-2 col-12 mr-auto">
                <div class="form-group mb-0 my-2" v-if="hasPermission('create-district')">
                  <router-link to="/district/create" class="btn btn-sm btn-primary rounded-pill px-4 py-2">
                    <i class="nav-icon fa fa-plus-square mr-2"></i>
                    {{ $t("button.add_new") }}
                  </router-link>
                </div>
              </div>
              <div class="col-sm-8 col-12 text-right">
                <input type="text" v-model="searchQuery">
              </div>
              <div>
                show
                <select @change.prevent="onShowEntry($event)">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="all">all</option>
                </select>
                entries
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-12 mx-auto overflow-auto">
            <table class="table table-bordered table-striped overflow-hidden">
              <thead>
                <tr class="text-center">
                  <th style="width: 100px">{{ $t("land-detail.no") }}</th>
                  <th v-column-sortable:province_id>
                    {{ $t("land-detail.province") }}
                  </th>
                  <th v-column-sortable:[`name_${$i18n.locale}`]>
                    {{ $t("title.name") }}
                  </th>
                  <th>
                    {{ $t('title.land_count') }}
                  </th>
                  <th style="width: 250px"
                    v-if="hasPermission('detail-district') || hasPermission('update-district') || hasPermission('delete-district')">
                    {{ $t("title.action") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="(l, index) in lists" :key="index">
                  <td>{{ currentPage * 10 - 10 + (index + 1) }}</td>
                  <td>
                    {{ l[`pro_name_${$i18n.locale}`] }}
                  </td>
                  <td>
                    <span v-if="[`name_${$i18n.locale}`]">
                      {{ l[`name_${$i18n.locale}`] }}
                    </span>
                  </td>
                  <td> {{ l.lands_count }}</td>
                  <td
                    v-if="hasPermission('detail-district') || hasPermission('update-district') || hasPermission('delete-district')">
                    <div class="d-flex justify-content-center">
                      <router-link :to="{ name: 'districtShow', params: { id: l.id } }" class="btn btn-info btn-sm mr-2"
                        v-if="hasPermission('detail-district')">
                        {{ $t("title.detail") }}
                      </router-link>
                      <router-link :to="{ name: 'districtEdit', params: { id: l.id } }"
                        class="btn btn-primary btn-sm mr-2" v-if="hasPermission('update-district')">
                        <i class="fas fa-edit"></i>
                      </router-link>
                      <button class="btn btn-sm btn-danger" @click="onDelete(l.id)"
                        v-if="hasPermission('delete-district')">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="!lists.length" class="text-center">
                 
                  <td colspan="11"e>
                    {{ $t("title.no_result") }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="lists != ''">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage">
              </b-pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import columnSortable from "vue-column-sortable";
import hasPer from "../../services/permission";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      searchQuery: null,
      resultQuery: [],
    }
  },

  computed: {
    permission: {
      get() {
        return this.$store.state.permission
      }
    },
    districts: {
      get() {
        return this.$store.state.district.districts
      }
    },
    lists() {
      if (this.searchQuery) {
        var items = this.districts.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.name_en.toLowerCase().includes(v))
            || this.searchQuery
              .toLowerCase()
              .split(" ")
              .every(v => item.name_kh.toLowerCase().includes(v))
            ||
            this.searchQuery
              .toLowerCase()
              .split(" ")
              .every(v => item.pro_name_kh.toLowerCase().includes(v))
            ||
            this.searchQuery
              .toLowerCase()
              .split(" ")
              .every(v => item.pro_name_en.toLowerCase().includes(v));
        });

        var datas = items.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
        this.resultQuery = datas;
        return datas;
      } else {
        const items = this.districts;
        return items.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
      }
    },
    totalRows() {
      if (this.searchQuery) {
        return this.resultQuery.length
      } else {
        return this.districts.length;
      }
    },
  },

  mounted() {
    this.getDistricts();
    /*******
     * check permission
     */
    if (this.permission.length) {
      if (!this.hasPermission('district-lists')) {
        this.$router.push({ path: "/dashboard" });
      }
    }
  },

  methods: {
    /*******
     * get a permission for check
     */
    hasPermission(per) {
      return hasPer(this.permission, per);
    },
    /******
     * get all data provices
     */
    getDistricts() {
      this.$store.dispatch('district/getDistricts');
    },
    /*********
     * Delete one record
     */
    onDelete(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.dispatch('district/onDelete', id)
        }
      });
    },
    orderBy(fn) {
      this.districts.sort(fn);
    },
    /***
     * show number entry of table
     */
    onShowEntry($n) {
      this.currentPage=1;
      var i = $n.target.value;
      if (i == 'all') {
        this.perPage = this.districts.length
      } else {
        this.perPage = i;
      }
    }
  },
  directives: {
    columnSortable,
  }
}
</script>

<style>
</style>