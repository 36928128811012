<template>
  <div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row" v-if="!isUpdate">
          <div class="col-sm-8 col-12 mt-5 mx-auto">
            <div v-if="message" class="alert alert-success" v-text="message"></div>
            <h3 class="mb-5"><b>{{ $t('user_profile') }}</b></h3>
            <p><label>{{ $t("title.name") }} : {{ user.name }}</label></p>
            <p><label>{{ $t("title.email") }} : {{ user.email }}</label></p>
            <p><label>Amount Login : {{ count_login }}</label></p>
            <p><label>Last Login : {{ user.last_active }}</label></p>
            <p class="mt-4">
            <button type="submit" class="btn btn-primary" @click="openUpdate" >
            {{ $t("title.update") }}
            </button>
          </p>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-sm-8 col-12 mt-5 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">{{ $t("title.user") }}</h3>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-6">
                    <label for="exampleInputEmail1">
                      {{ $t("title.name") }}
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="user.name"
                    />
                    <span class="text-danger" v-if="errors.name">
                      {{ errors.name[0] }}
                    </span>
                  </div>
                  <div class="col-6">
                    <label for="exampleInputEmail1"
                      >{{ $t("title.email")
                      }}<span class="text-danger">*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      v-model="user.email"
                    />
                    <span class="text-danger" v-if="errors.email">
                      {{ errors.email[0] }}
                    </span>
                  </div>
                  <div class="col-6 mt-3">
                    <label for="exampleInputPassword1">{{
                      $t("title.password")
                    }}</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="user.password"
                    />
                  </div>
                  <div class="col-6 mt-3">
                    <label for="exampleInputPassword1">
                      ផ្លាស់ប្តូរទំហំអក្សរ
                    </label>
                    <select class="custom-select rounded-0" v-model="user.font_size">
                      <option v-for="font in fontsize" :key="font"
                        :selected="font == user.font_size"
                      >
                        {{ font }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="errors.font_size">
                      {{ errors.font_size[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click="updateUser"
                >
                  {{ $t("title.update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count_login: null,
      fontsize: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
    };
  },

  computed: {
    /**
     * get current user
    */
    user:{
      get(){
        return this.$store.state.user;
      }
    },
    /**
     * get error message
     */
    errors: {
      get() {
        return this.$store.state.errors;
      },
    },
    /**
     * get message after update success
     */
    message: {
      get() {
        return this.$store.state.message;
      }
    },
    
    isUpdate: {
      get() {
        return this.$store.state.isUpdate;
      }
    }
  },

  mounted() {
    axios.get(`/users/${this.user.id}`).then((res) => {
      this.count_login = res.data.user_login_count;
    })
  },

  methods: {
    openUpdate() {
      this.$store.dispatch("updating", true);
    },

    updateUser() {
      var user = {
        name: this.user.name,
        email: this.user.email,
        font_size: this.user.font_size,
        password: this.user.password,
      }

      this.$store.dispatch('onUpdateProfile', user);

      // axios.post(`/profile`, this.user)
      //   .then((res) => {
      //     window.location.reload();
      //     this.message = res.data.success;
      //       setTimeout(() => {
      //         this.message = '';
      //       }, 5000);
      //   })
      //   .catch((err) => {
      //     if (err.response.data.errors.name) {
      //       this.errName = err.response.data.errors.name[0];
      //     }
      //     if (err.response.data.errors.email) {
      //       this.errEmail = err.response.data.errors.email[0];
      //     }
      //     if (err.response.data.errors.font_size) {
      //       this.err_font = err.response.data.errors.font_size[0];
      //     }
      //   });
    },
  },
};
</script>

<style>
</style>