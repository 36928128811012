<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">User Role</h4>
          </div>
          <div class="col-sm-6 text-right">
            <ol class="breadcrumb justify-content-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>{{ $t('title.home') }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/users" class="text-dark font-weight-bold">
                  <i class="fas fa-users mr-1"></i>{{ $t("title.user") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/roles" class="text-dark font-weight-bold">
                  User Role
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.update") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-12 mx-auto mt-5">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Edit Role</h3>
              </div>
              <div class="card-body">
                <div class="form-group row mb-4">
                  <div class="col-md-8 col-12">
                    <label for="">{{ $t('title.name') }}</label>
                    <input type="text" class="form-control" v-model="role.name">
                    <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                  </div>
                </div>

                <div class="form-group row">
                  <div v-for="(per, p) in perms" :key="p" class="col-md-2 py-1 border rounded m-1">
                    <h3 class="p-1">{{ p.charAt(0).toUpperCase() + p.slice(1).replace(/([A-Z])/g, ' $1').trim() }}</h3>
                    <div v-for="(item, i) in per" class="form-group form-check pl-5">
                      <input type="checkbox" v-model="role.permissions" :true-value="[]" :value="item.id"
                        class="form-check-input" />
                      <label class="form-check-label">{{ item.description }} </label>
                    </div>
                  </div>
                </div>
                 <span class="text-danger" v-if="errors.permissions">{{ errors.permissions[0] }}</span>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-primary" @click="updateRole">{{ $t('title.update') }}</button>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import hasPer from '../../../services/permission';

export default {
  data() {
    return {
      permiss: [],
      user_permis: [],
      role_permis: [],
      land_permis: [],
      landType_permis: [],
      country_permis: [],
      province_permis: [],
      district_permis: [],
      owner_permis: [],
      role: {
        name: '',
        permissions: []
      },
      permiss_checked: [],
      isLoading: false,
      errName: '',
      errPermiss: ''
    }
  },
  computed: {
    /******
  * permssion data
  */
    permission: {
      get() {
        return this.$store.state.permission;
      },
    },
    perms: {
      get() {
        return this.$store.state.permissions.permissions;
      },
    },
    errors: function () {
      return this.$store.state.roles.errors;
    }
  },
  mounted() {
    this.getPermission();
    this.getRole();
    /***
    * check permmsion create land
    */
    if (this.permission.length) {
      if (!this.hasPermission("edit-role")) {
        this.$router.push({ path: "/dashboard" });
      }
    }
  },

  methods: {
    /****
   * method check permission
   */
    hasPermission(per) {
      return hasPer(this.permission, per)
    },

    /***
     * get all permission
     */
    getPermission() {
      this.$store.dispatch('permissions/getPermissions');
    },

    getRole() {
      this.$store.dispatch('loading', true);
      axios.get(`/roles/${this.$route.params.id}`).then((res) => {
        this.role = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.$store.dispatch('loading', false);
      })
    },

    updateRole() {
     this.$store.dispatch('roles/onUpdate',this.role)
    }
  }
}
</script>

<style>
</style>