<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0">{{ $t('Edit Land Type') }}</h4>
          </div>
            <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <i class="fa fa-home"></i>
                <router-link to="/dashboard">
                {{$t("title.home")}}</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/landTypes">
                {{$t("title.land_type")}}</router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ $t('Edit Land Type') }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-5" v-if="isLoading">
      <b-spinner  label="Text Centered"></b-spinner>
    </div>

    <!-- Main content -->
    <section class="content position-relative" v-else>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-10 my-5 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">{{ $t('title.land_type') }}</h3>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-6">
                    <label for="">{{ $t('title.name') }}</label>
                    <input type="text" class="form-control" v-model="landType.name">
                    <span class="text-danger">{{ err_name }}</span>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary" @click="updateLandType">{{ $t('title.update')}}</button>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-6 col-8 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">{{ $t('title.land_type') }}</h3>
                <router-link :to="{ name: 'DocTitleCreate'}" class="btn btn-secondary btn-smt float-right">
                  <i class="fa fa-plus-square mr-2"></i> {{$t('Add Document Title')}}
                </router-link>
              </div>
              <div class="card-body">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr class="text-center">
                      <th class="w-25">{{ $t('land-detail.no') }}</th>
                      <th class="w-50">{{ $t('title.name') }}</th>
                      <th class="w-25">{{ $t('title.action') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="d in landType.doc_titles" :key="d.id">
                      <td class="text-center">{{ d.position }}</td>
                      <td class="td-name">{{ d[`title_${$i18n.locale}`] }}</td>
                      <td class="text-center">
                        <router-link :to="{ path: `/doc_title/${landType.id}/edit/${d.id}`}" class="btn btn-primary btn-sm">
                          <i class="fas fa-edit"></i>
                        </router-link>
                        <button class="btn btn-danger btn-sm ml-2 show_confirm" @click="deleteDoc(d.id)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                    <tr v-if="landType.doc_titles == 0">
                      <td colspan="3" class="text-center">{{ $t('title.no_result') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      landType: {
        name:''
      },
      Document_title: {
        title:''
      },
      err_name: '',
      isLoading: false
    }
  },

  mounted() {
    this.getLandType();
    if($cookies.get("token")) {
      axios.get("/user").then((res) => {
        let no_role = res.data['roles'].length == 0;
        if(no_role) {
          this.$router.push({ path: '/dashboard' });
        }

        var permiss = res.data['roles'][0]['permissions'];
        var datas = permiss.map((per) => ({[per.name]: per.name }));
      
        let newData = {};
        datas.forEach((item) => {
          let key = Object.keys(item)[0];
          newData[key] = item[key];
        });

        var exists = Object.keys(newData).some((k) => {
          return newData[k] == "update-land-type";
        });
        
        if(exists == false) {
          this.$router.push({ path: '/dashboard' });
        }
        
      })
      .catch((err) => { });
    }
  },

  methods: {
    getLandType() {
      this.isLoading = true
      axios.get(`/land-type/${this.$route.params.id}`, this.landType)
      .then((res) => {
        this.landType = res.data;
        this.landType.name = res.data[`name_${this.$i18n.locale}`];
        
      })
      .catch((err) => { })
      .finally(() => {
        this.isLoading = false
      })
    },

    updateLandType() {
      axios.put(`/land-type/${this.$route.params.id}?lang=${this.$i18n.locale}`, this.landType )
      .then((res) => {
        this.getLandType();
        swal({
          title: "Update Success",
          icon: "success",
          button: "Close",
          timer: 3000
        });
      })
      .catch((err) => {
        if(err.response.data.errors.name){
          this.err_name = err.response.data.errors.name[0];
        }
      })
    },

    deleteDoc(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete(`/document-title/${id}?land_type_id=${this.$route.params.id}`).then((res)=>{
            let i = this.landType.doc_titles.map(data => data.id).indexOf(id);
            this.landType.doc_titles.splice(i, 1);
          })
        }
      });
    }
  }

}
</script>

<style>
  .td-name {
    white-space: pre-line;
  }
</style>