<template>
  <div class="position-relative"> 
    <div class="content-header">
      <div class="container-xl">
        <div class="row">
          <div class="col-sm-6 d-inline-flex d-sm-flex align-items-center gap-2">
            <router-link to="/lands" :title="$t('back')" ><BackIcon/></router-link>
            <span class="font-weight-bold">{{ $t('title.list_land') }}</span>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>
                  {{ $t("title.home") }}</router-link
                >
              </li>
              <li class="breadcrumb-item">
                 <router-link to="/lands" class="text-dark font-weight-bold">
                  {{ $t('title.list_land') }}
                  </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.create") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- Main content -->
    <section class="content position-relative mt-4">
      <div class="container-xl">
       
        <form @submit.prevent="onCreate">
                <div class="card card-dark">
                  <div class="card-header">
                    <h3 class="card-title">
                      {{ $t('create_land') }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <div class="col-md-6" v-if="mainlLands.length">
    
                        <label for="">{{ $t('main_land') }}</label>
                        <input type="hidden" v-model="land.user_role">
                        <select class="custom-select rounded-0" v-model="land.associate" @change="changeMain($event)">
                          <option selected value="main">
                            {{ $t('main_land') }}
                          </option>
                          <option v-for="land in mainlLands" :key="land.id" :value="land.id"
                            :selected="land.id == land.associate">
                            {{ land[`land_name_${$i18n.locale}`] }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-3" v-if="hasPermission('md-owner') && hasPermission('ceo-owner')">
                        <label for="">{{ $t('Land Owner') }}</label>
                        <select class="custom-select rounded-0" v-model="land.user_role">
                          <option selected disabled value="">{{ $t('title.select_owner') }}</option>
                          <option value="md" :selected="land.user_role == 'md'">JENNY CHEA</option>
                          <option value="ceo" :selected="land.user_role == 'ceo'">CHEA HUY</option>
                        </select>
                        <span class="text-danger" v-if="errors.user_role">
                          {{ errors.user_role[0] }}
                        </span>
                      </div>
                      <div class="col-md-3">
                        <label for="">លេខប័ណ្ណកាន់កាប់</label>
                        <input type="text" class="form-control" v-model="land.land_title_number">
                        <span class="text-danger" v-if="errors.land_title_number">
                          {{ errors.land_title_number[0] }}
                        </span>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label for="">ប្រភេទប័ណ្ណកាន់កាប់</label>
                        <select class="form-control" v-model="land.type_land_title">
                          <option value="">choose</option>
                          <option value="ប្លង់រឹងQR">ប្លង់រឹងQR</option>
                          <option value="ប្លង់រឹង">ប្លង់រឹង</option>
                          <option value="ប្លង់ទន់">ប្លង់ទន់</option>
                          <option value="ប្លង់ស្ត្រាតា">ប្លង់ស្ត្រាតា</option>
                        </select>
                        <span class="text-danger" v-if="errors.type_land_title">
                          {{ errors.type_land_title[0] }}
                        </span>
                      </div>
                      <div class="col-md-8 mt-3">
                        <label for="">{{ $t("land-detail.land_name") }}</label>
                        <input type="text" class="form-control" v-model="land.land_name" />
                        <span class="text-danger" v-if="errors.land_name">
                          {{ errors.land_name[0] }}
                        </span>
                      </div>


                      <div class="col-md-4 mt-3">
                        <label for="">{{ $t("land-detail.province") }}</label>
                        <select class="custom-select rounded-0" v-model="land.province_id" v-bind:class="{ isMainLand }"
                          @change="getDisByProvince($event)">
                          <option disabled value="null">{{ $t('title.select_province') }}</option>
                          <option v-for="p in provinces" :key="p.id" :value="p.id">
                            {{ p[`name_${$i18n.locale}`] }}
                          </option>
                        </select>
                        <span class="text-danger" v-if="errors.province_id">
                          {{ errors.province_id[0] }}
                        </span>
                      </div>

                      <div class="col-md-4 mt-3">
                        <label for="">{{ $t('title.districts') }}</label>
                        <select class="custom-select rounded-0" v-bind:class="{ isProvince }" v-model="land.districts_id">
                          <option :value="null" selected v-if="isProvince">{{ $t("title.no_result") }}</option>
                          <option selected disabled :value="null" v-else>{{ $t('title.select_district') }}</option>
                          <option v-for="d in districts" :key="d.id" :value="d.id" :selected="d.id == land.districts_id">
                            {{ d[`name_${$i18n.locale}`] }}
                          </option>
                        </select>
                        <span class="text-danger" v-if="errors.districts_id">
                          {{ errors.districts_id[0] }}
                        </span>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label for="">
                          {{ $t("land-detail.description.size") }} m<sup>2</sup>
                        </label>
                        <input type="number" class="form-control" v-model="land.square_meter" />
                        <span class="text-danger" v-if="errors.square_meter">
                          {{ errors.square_meter[0] }}
                        </span>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="">{{ $t("land-detail.localtion") }}</label>
                        <textarea class="form-control" rows="2" v-model="land.location"></textarea>
                        <span class="text-danger" v-if="errors.localtion">
                          {{ errors.localtion[0] }}
                        </span>
                      </div>
                      <div class="col-12 mt-3">
                        <label for="">Google Map</label>
                        <input type="text" class="form-control" v-model="land.link_google_map" />
                      </div>
                      <div class="col-12 mt-3">
                        <label for="">{{ $t("title.description") }}</label>
                        <textarea class="form-control" rows="2" v-model="land.desc"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary mr-2">
                      {{ $t("title.save") }}
                    </button>
                  </div>
                </div>
              </form>
      </div>
      
    </section>
  </div>
</template>

<script>
import BackIcon from '../../components/my/icons/BackIcon.vue';
import hasPer from '../../services/permission';


export default {
    data() {
        return {
            land: {
                land_name: "",
                location: "",
                province_id: null,
                districts_id: null,
                transfer_from: "",
                desc: "",
                square_meter: "",
                link_google_map: "",
                land_title_number: "",
                type_land_title: "",
                associate: "main",
                user_role: ""
            },
            isProvince: true,
            isMainLand: false,
            owner: localStorage.getItem("owner")
        };
    },
    computed: {
        /******
         * provinces data
         */
        provinces: {
            get() {
                return this.$store.state.province.provinces;
            }
        },
        /**
         * get error message
         */
        errors: {
            get() {
                return this.$store.state.lands.errors;
            },
        },
        /******
         * permssion data
         */
        permission: {
            get() {
                return this.$store.state.permission;
            },
        },
        /******
         * main land data
         */
        mainlLands: {
            get() {
                return this.$store.state.lands.mainLands;
            },
        },
        /******
         * districts data
         */
        districts: {
            get() {
                return this.$store.state.district.disByProvince;
            },
        }
    },
    mounted() {
        this.getMainLand();
        this.getProvinces();
        /***
         * check permmsion create land
         */
        if (this.permission.length) {
            if (!this.hasPermission("create-land")) {
                this.$router.push({ path: "/dashboard" });
            }
        }
    },
    methods: {
        /****
         * method check permission
         */
        hasPermission(per) {
            return hasPer(this.permission, per);
        },
        /*******
         * get all province
        */
        getProvinces() {
            this.$store.dispatch("province/getProvinces");
        },
        /*******
         * get main land
        */
        getMainLand() {
            this.$store.dispatch("lands/getMainLands");
        },
        /*******
         * on chnage province
        */
        getDisByProvince(event) {
            var id = event.target.value;
            if (id == "all" || !id) {
                this.isProvince = true;
            }
            else {
                this.isProvince = false;
            }
            this.land.districts_id = null;
            this.getDistrict(id);
        },
        /*******
         * Add new reco
        */
        onCreate() {
            if (this.hasPermission("md-owner") && !this.hasPermission("ceo-owner")) {
                this.land.user_role = "md";
            }
            else if (this.hasPermission("ceo-owner") && !this.hasPermission("md-owner")) {
                this.land.user_role = "ceo";
            }
            this.$store.dispatch("lands/onCreate", [this.land, this.$i18n.locale]);
        },
        /***
         * change main land
         */
        changeMain(event) {
            var id = event.target.value;
            axios.get(`/lands/${id}?owner=${this.owner}`).then((res) => {
                if (id != "main") {
                    this.isMainLand = true;
                    this.land.province_id = res.data["province_id"];
                    this.land.districts_id = res.data["districts_id"];
                    this.land.user_role = res.data["user_role"];
                    this.getDistrict(this.land.province_id);
                }
                else {
                    this.isMainLand = false;
                    this.isProvince = true;
                    this.land.province_id = null;
                    this.land.districts_id = null;
                    this.land.user_role = null;
                }
            }).catch((err) => { });
        },
        /**********
         * get district
         */
        getDistrict(id) {
            this.$store.dispatch("district/getDisByProvince", id);
        }
    },
    components: { BackIcon }
}
</script>

<style land="scss">
  .isProvince, 
  .isMainLand {
    opacity: 0.6;
    pointer-events:none;
  }
</style>