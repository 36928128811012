<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t("title.province") }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i> {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/provinces" class="text-dark font-weight-bold">
                  {{ $t("title.province") }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.update") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-10 mt-5 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Edit</h3>
              </div>
              <form @submit.prevent="onUpdate">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-6">
                      <label for="">{{ $t("title.name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="provinces[`name_${$i18n.locale}`]"
                      />
                      <span class="text-danger" v-if="errors.name">
                        {{ errors.name[0] }}</span
                      >
                    </div>
                    <div class="col-6">
                      <label for="">{{ $t("title.country") }}</label>
                      <select
                        class="custom-select rounded-0"
                        v-model="provinces.country_id"
                      >
                        <option
                          v-for="c in countries"
                          :key="c.id"
                          v-bind:value="c.id"
                          :selected="c.id == provinces.country_id"
                        >
                          {{ c[`name_${$i18n.locale}`] }}
                        </option>
                        <span class="text-danger" v-if="errors.country_id">
                          {{ errors.country_id[0] }}</span
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">
                    {{ $t("title.update") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import hasPer from '../../services/permission';

export default {
  data() {
    return {};
  },
  computed: {
    /********
     * province data
     */
    provinces: {
      get() {
        return this.$store.state.province.province;
      },
    },
    /**
     * get error message
     */
    errors: {
      get() {
        return this.$store.state.province.errors;
      },
    },
    /********
     * countries data
     */
    countries: {
      get() {
        return this.$store.state.country.countries;
      },
    },
    /******
     * permssion data
     */
    permission: {
      get() {
        return this.$store.state.permission;
      },
    },
  },

  mounted() {
    this.getProvince();
    this.getCountries();
     /********
     * check permssion
     */
    if (this.permission.length) {
      if (!this.hasPermission("province-lists")) {
        this.$router.push({ path: "/dashboard" });
      }
    }
   
  },
  
  methods: {
    /****
     * method check permission
     */
    hasPermission(per) {
     return hasPer(this.permission,per);
    },
    /******
     * get all data from countries
     */
    getCountries() {
      this.$store.dispatch("country/getCountries");
    },
    /*******
     * Change record
     */
    onUpdate() {
      var pro = {
        name: this.provinces[`name_${this.$i18n.locale}`],
        country_id: this.provinces.country_id,
      };
      this.$store.dispatch("province/onUpdate", [pro, this.$i18n.locale]);
    },
    
    /**
     * show  one record
     */
    getProvince() {
      this.$store.dispatch("province/getProvince");
    },
  },
};
</script>

<style></style>
