<template>
  <div class="position-relative">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t("title.country") }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i> {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/countries" class="text-dark font-weight-bold">
                  {{ $t("title.country") }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ $t("title.update") }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-10 mt-5 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">
                  {{ $t("title.update") }} {{ $t("title.country") }}
                </h3>
              </div>
              <form @submit.prevent="onUpdate">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-6">
                      <label for="">{{ $t("title.en_name") }}</label>
                      <input type="text" class="form-control" v-model="countries[`name_${$i18n.locale}`]" />
                      <span class="text-danger" v-if="errors.name">{{
                          errors.name[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">
                    {{ $t("title.update") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import hasPer from '../../services/permission';

export default {
  data() {
    return {
      country: {
        name: "",
      },
    };
  },
  computed: {
    /*****************
     * permission for this page
     */
    permission: {
      get() {
        return this.$store.state.permission;
      },
    },
    /**
     * get error message
     */
    countries: {
      get() {
        return this.$store.state.country.country;
      },
    },
    errors: {
      get() {
        return this.$store.state.country.errors;
      },
    },
  },

  mounted() {
    this.getCountry();
    this.country.name = this.countries[`name_${this.$i18n.locale}`];
     /***
     * Check is permission redirecet to dashboard
     */
    if (this.permission.length) {
      if (!this.hasPermission("update-country")) {
        this.$router.push({ path: "/dashboard" });
      }
    }
  },
  methods: {
    /******
     * Method for check permission
     */
    hasPermission(per) {
      return hasPer(this.permission, per);
    },
    /*****
     * get one country
     */
    getCountry() {
      this.$store.dispatch("country/getCountry");
    },
    /***************
     * Change record
     */
    onUpdate() {
      this.$store.dispatch("country/onUpdate", [
        this.$i18n.locale,
        {
          name: this.countries[`name_${this.$i18n.locale}`],
        },
      ]);
    },
  },
};
</script>

<style>
</style>
