const storage = {
    namespaced: true,
    state: {
        owner:""
    },
    mutations: {
        /*******
         * set data in storage
         */
        setOwner(state){
            state.owner=localStorage.getItem('owner');
        }
    },
    getters: {},
    actions: {
        /*********
         * get owner form storage
         */
        getOwner({commit}){
            commit('setOwner')
        }
    }
}
export default storage;