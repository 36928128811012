<template>
  <div class="login">
    <div class="login_content">
      <div class="logo text-center">
        <img alt="Logo" src="../../assets/images/logo_main.png" />
      </div>
      <div class="text-center pt-3">
        <label for="">Reset Password</label>
      </div>

      <div class="login_form">
        <div class="form-group">
          <label for="email" class="d-block">{{ $t("title.email") }}</label>
          <input type="email" v-model="formReset.email"
            disabled placeholder="user@example.com"
            autocomplete="off" />
          <p class="text-center text-danger" v-if="errors.email">{{errors.email[0]}}</p>
        </div>
        <div class="form-group position-relative">
          <label for="email" class="d-block">New Password</label>
          <input v-if="showPassword" type="text" class="input" v-model="formReset.password" />
          <input v-else type="password" v-model="formReset.password" placeholder="New Password" autocomplete="off" />

          <p class="text-center text-danger" v-if="errors.password">{{errors.password[0]}}</p>
          <div class="control">
            <button class="button w-auto" @click="toggleShow">
              <span class="icon is-small is-right">
                <i class="fas" :class="{'fa-eye-slash': showPassword, 'fa-eye': !showPassword}"></i>
              </span>
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="email" class="d-block">Confirm Password</label>
          <input type="password" v-model="formReset.password_confirmation"
            placeholder="Confirm Password" autocomplete="off" />
        </div>
        <div class="mt-4 text-center">
          <button type="submit" @click="resetPassword" class="w-auto">Reset Password</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formReset:{
        toke:"",
        email:"",
        password:"",
        password_confirmation:""
      },
      showPassword: false,
      password: null,
    };
  },

  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    errors:{
      get(){
        return this.$store.state.auth.errors;
      }
    }

  },

  mounted(){
    let params = (new URL(location.href)).searchParams;
    this.formReset.token = params.get('token');
    this.formReset.email=params.get('email');
  },

  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    resetPassword() {
      this.$store.dispatch('auth/onResetPassword',this.formReset);
    },
  },
};
</script>

<style>
input:disabled {
  background-color: rgb(247, 243, 243);
}
</style>