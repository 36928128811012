<template>
  <div class="position-relative">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t("title.province") }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>
                  {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/provinces" class="text-dark font-weight-bold">
                  {{ $t("title.province") }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.create") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-10 mt-4 mx-auto">
            <form @submit.prevent="onCreate">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">
                    {{ $t("title.create") }} {{ $t("title.province") }}
                  </h3>
                </div>
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-6">
                      <label for="">{{ $t("title.en_name") }}</label>
                      <input type="text" class="form-control" v-model="province.name" />
                      <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                    </div>
                    <!-- <div class="col-6">
                    <label for="">{{ $t("title.kh_name") }}</label>
                    <input type="email" class="form-control" v-model="province.name.kh" />
                  </div> -->
                    <div class="col-6">
                      <label for="">{{ $t("title.country") }}</label>
                      <select class="custom-select rounded-0" v-model="province.country_id">
                        <option selected disabled :value="null">
                          Please select country
                        </option>
                        <option v-for="c in countries" :key="c.id" :value="c.id">
                          {{ c[`name_${$i18n.locale}`] }}
                        </option>
                      </select>
                      <span class="text-danger" v-if="errors.country_id">
                        {{ errors.country_id[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">
                    {{ $t("title.save") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import hasPer from '../../services/permission';

export default {
  data() {
    return {
      province: {
        name: "",
        country_id: null,
      },
    };
  },
  computed: {
    /**
     * get error message
     */
    errors() {
      return this.$store.state.province.errors;
    },
    /********
     * countries data
     */
    countries() {
      return this.$store.state.country.countries;
    },
    /******
     * permssion data
     */
    permission() {
      return this.$store.state.permission;
    },
  },

  mounted() {
    this.getCountries();
    /********
    * check permssion
    */
    // if (this.permission.length) {
    //   if (!this.hasPermission("create-province")) {
    //     this.$router.push({ path: "/dashboard" });
    //   }
    // }
  },
  methods: {
    /****
     * method check permission
     */
    hasPermission(per) {
      hasPer(this.permission, per)
    },
    /******
     * get all data from countries
     */
    getCountries() {
      this.$store.dispatch("country/getCountries");
    },
    /*******
     * Add new reco
     */
    onCreate() {
      this.$store.dispatch("province/onCreate", [this.province, this.$i18n.locale]);
    },
  },
};
</script>

<style>
</style>
