<template>

    <!-- <b-tree-view :data="treeData"></b-tree-view> -->
    <nav class="mt-2 ">
      <ul class="nav nav-pills nav-sidebar flex-column block">
        <!-- <li class="nav-header">{{ $t("land-detail.localtion") }}</li> -->
        <li v-for="(item, index) in sideBar" :key="index"
          :class="['nav-item',{'menu-open' : $route.meta.type == item.name ? 'menu-open' : '' }]"
        >
          <a  v-if="!item.link" class="nav-link" >
            <i :class="item.icon"></i>
            <p>
              {{ $t(item.title) }}

              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <router-link :to="item.link" class="nav-link" v-if="item.link" >
            <i :class="item.icon"></i>
            <p>
              {{ $t(item.title) }}
            </p>
          </router-link>

          <ul class="nav nav-treeview">
            <li class="nav-item" v-for="(sub, index) in item.sub_item" :key="index">
              <router-link :to="sub.link" class="nav-link text-black">
                <i :class="sub.icon"></i> {{ $t(sub.title) }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

</template>

<script>
import $ from "jquery";
import { bTreeView } from "bootstrap-vue-treeview";

export default {
  components: {
    bTreeView,
  },
  data() {
    return {
      menuOpen:"",
      sideBar: [
        // { title: "title.country", icon: "nav-icon fa fa-globe mr-2",link:"test" },
        {
          title: "title.country",
          icon: "nav-icon fa fa-globe mr-2",
          name:'countries',
          sub_item: [
            {
              title: "title.lists",
              icon: "nav-icon fa fa-list mr-2",
              link: "/countries",
            },
            {
              title: "button.add_new",
              icon: "nav-icon fa fa-plus-square mr-2",
              link: "/country/create",
            },
          ],
        },
        {
          title: "title.province",
          icon: "nav-icon fa fa-map mr-2",
          name:'provinces',
          sub_item: [
            {
              title: "title.lists",
              icon: "nav-icon fa fa-list mr-2",
              link: "/provinces",
            },
            {
              title: "button.add_new",
              icon: "nav-icon fa fa-plus-square mr-2",
              link: "/province/create",
            },
          ],
        },
        {
          title: "title.land_type",
          icon: "nav-icon fa fa-tag mr-2",
          name:"landTypes",
          sub_item: [
            {
              title: "title.lists",
              icon: "nav-icon fa fa-list mr-2",
              link: "/landTypes",
            },
            {
              title: "button.add_new",
              icon: "nav-icon fa fa-plus-square mr-2",
              link: "/landType/create",
            },
          ],
        },
        {
          title: "title.list_land",
          icon: "nav-icon fa fa-folder mr-2",
          name:"lands",
          sub_item: [
            {
              title: "title.lists",
              icon: "nav-icon fa fa-list mr-2",
              link: "/lands",
            },
            {
              title: "button.add_new",
              icon: "nav-icon fa fa-plus-square mr-2",
              link: "/land/create",
            },
          ],
        },
        {
          title: "title.user",
          icon: "nav-icon fa fa-users mr-2",
          name:"users",
          sub_item: [
            {
              title: "title.lists",
              icon: "nav-icon fa fa-list mr-2",
              link: "/users",
            },
            {
              title: "button.add_new",
              icon: "nav-icon fa fa-plus-square mr-2",
              link: "/user/create",
            },
          ],
        },
      ],
    };
  },

  mounted() {
    const trees = $('ul[data-widget="treeview"]');
    trees.Treeview("init");
    this.menuOpen = this.$route.path.substring(1).split('/')[0];
  },
};
</script>

<style>
a{
  cursor: pointer;
}
</style>