import router from "@/router";
const role = {
    namespaced: true,
    state: {
        roles: [],
        role: [],
        errors:[],
    },
    mutations: {
        /****
         * set all data in roles state
         */
        setRoles(state, roles) {
            state.roles = roles
        },
        /****
         * set a data in role state
         */
        setRole(state, role) {
            state.role = role;
        },
        /*******
         * set errors
         */
        setErrors(state,errors){
            state.errors=errors;
        }
    },
    getters: {
    },
    actions: {
        /***
         * get all data from roles
         */
        getRoles({ commit }) {
            this.dispatch("loading", true);
            axios.get('/roles').then((res) => {
                commit('setRoles', res.data)
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.dispatch("loading", false);
            })
        },
        /******
         * add new record to roles
         */
        onCreate({commit},data) {
            this.dispatch('loading', true);
            axios.post(`roles`, data).then((res) => {
                if (res.status == 201) {
                    let lastItem = res.data.id;
                    router.push({ path: `/role/edit/${lastItem}` });
                    commit('setErrors',[])
                }
            }).catch((err) => {
                commit('setErrors',err.response.data.errors)
            }).finally(() => {
                this.dispatch('loading', false);
            })
        },
        /***
         * get single data from role
         */
        getRole({ commit }) {
            this.dispatch("loading", true);
            axios.get(`/roles/${router.currentRoute.params.id}`).then((res) => {
                commit('setRole', res.data)
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.dispatch("loading", false);
            })
        },
        /*****
         * update a record
         */
        onUpdate({ commit }, data) {
            this.dispatch('loading', true);
            axios.put(`/roles/${router.currentRoute.params.id}`, data)
              .then((res) => {
                router.push({ name: 'Roles' });
                commit('setErrors',[])
              })
              .catch((err) => {
                commit('setErrors',err.response.data.errors)
              }).finally(() => {
                this.dispatch('loading', false);
              })
        },
        /*****
         * update a record
         */
        onDelete({ commit }, id) {
            this.dispatch("loading", true);
            axios.delete(`/roles/${id}`).then((res) => {
                this.dispatch('roles/getRoles');
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.dispatch("loading", false);
            })
        }


    }
}
export default role;