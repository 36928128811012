<template>
  <div class="card card-dark">
    <div class="card-header d-flex align-items-center">
      <h3 class="card-title">{{ $t("land-detail.document") }}</h3>
      <div class="ml-auto ">
        <b-button pill variant="outline-light" size="sm" @click="show">
          <i class="fa fa-plus-square mr-2"></i>Add Single File
        </b-button>
        <b-button pill size="sm" @click="showAddMultiFile" class="ml-1">
          <i class="fa fa-plus-square mr-2"></i>Add Multi File
        </b-button>
      </div>

      <!-- <b-dropdown pill size="sm" text="Add File" class="ml-auto text-write rounded-pill"  variant="outline-secondary">
          <b-dropdown-item  @click="show" >Add Single File</b-dropdown-item>
          <b-dropdown-item @click="showAddMultiFile">Add Multi File</b-dropdown-item>
        </b-dropdown> -->
    </div>
    <div class="card-body table-responsive">
      <table class="table table-bordered table-striped overflow-hidden">
        <thead>
          <tr class="text-center">
            <th style="width: 100px">{{ $t("land-detail.no") }}</th>
            <th>{{ $t("land-detail.title") }}</th>
            <th class="w-25">{{ $t('title.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, index) in docLands" :key="index">
            <td class="text-center">{{ d.no }}</td>
            <td class="px-3" v-if="[`title_${$i18n.locale}`]">
              {{ d[`title_${$i18n.locale}`] }}
            </td>
            <td class="text-center">
              <button @click="previewPopup(index)" class="btn btn-sm btn-info mr-2">
                {{ $t("title.detail") }}
              </button>
              <button @click="updateShow(d.id)" class="btn btn-sm btn-primary mr-2">
                {{ $t('title.update') }}
              </button>
              <button @click="onDelete(d.id)" class="btn btn-sm btn-danger">
                {{ $t('title.delete') }}
              </button>
            </td>
          </tr>
          <tr v-if="docLands.length == 0">
            <td colspan="4" class="text-center">
              {{ $t("title.no_result") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- /***********
      Modal insert or update
      */ -->
    <!-- <input type="file" v-model="pdf_file" ref="fileInput"> -->
    <b-modal ref="add-file" :title="modal_title" hide-footer centered :visible="popup"
      @hide="$store.commit('documents/setPopup', false)">
      <form @submit.prevent="onSave">
        <div v-bind:class="{ isUpload }">
          <div class="row">
            <div class="my-3">
              <label for="">{{ $t('land-detail.no') }}(1.0)</label>
              <input type="number" step="0.1" class="form-control" :placeholder="$t('land-detail.no')"
                v-model="doc_land.no">
              <span class="text-danger" v-if="errors.no">{{ errors.no[0] }}</span>
            </div>
            <div class="form-group">
              <label>{{ $t('land-detail.title') }}</label>
              <textarea type="text" class="form-control" v-model="doc_land.title"
                :placeholder="$t('land-detail.title')"></textarea>
              <span class="text-danger" v-if="errors.title">{{ errors.title[0] }}</span>
            </div>

            <div class="form-group" v-if="!doc_land.link_file">
              <b-form-file v-model="doc_land.link_file" accept=".pdf" placeholder="Choose a file here...">
              </b-form-file>
           
            </div>
            <div class="form-group" v-else>
              <b-button @click="onRemove"><i class="fas fa-times"></i> Remove file</b-button>
              <span class="text-danger" v-if="errors.link_file">{{ errors.link_file[0] }}</span>
            </div>
          </div>
        </div>
        <div v-if="isUpload" class="mt-3">
          <b-progress :max="max" show-progress animated>
            <b-progress-bar :value="progress" :label="`${progress}%`"></b-progress-bar>
          </b-progress>
        </div>
        <b-button type="submit" class="mt-4" variant="outline-primary">{{ $t('title.save') }}</b-button>
      </form>
    </b-modal>

    <b-modal ref="add-multi-file" title="Add Multi File" 
    :visible="propupM"
    @hide="propupM"
    @cancel="reset"
    @close="reset"
    ok-title="Save" centered size="lg" hide-footer  >
      <div class="form-group">
        <label for="fileupload"
          style="width: 100%; padding: 20px; text-align: center; border:2px dotted gray; cursor: pointer;">Select PDF Only 10 file</label>
        <b-form-file type="file" class="form-control d-none" id="fileupload" v-model="pdf_file" name="pdf_file" ref="pdf_file"
          accept=".pdf" multiple @change="pdfData($event)" />
      </div>
      <table class="table table-bordered" v-if="pdfs_base64.length">
        <thead>
          <tr class="text-center">
            <th style="width: 100px">{{ $t("land-detail.no") }}</th>
            <th>{{ $t("land-detail.title") }}</th>
            <th class="w-25">{{ $t('title.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" class="text-center" v-if="loading_file">
              <b-spinner label="Loading..."></b-spinner>
            </td>
          </tr>
          <tr v-for="(pdf, i) in pdfs_base64" :key="i">
            <td style="width: 20px;">
              <input type="number" :value="pdf.no" step="0.1" @input="changeNo(i, 'no', $event.target.value)"
                class="form-control">
            </td>
            <td>
              <textarea type="text" class="form-control" :value="pdf.name"
                @input="changeNo(i, 'name', $event.target.value)"></textarea>
            </td>
            <td class="text-right"> <button class="btn btn-danger btn-sm" @click="remove(i)"><i
                  class="fa fa-times"></i></button> </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" v-if="pdfs_base64.length">
        <b-button @click="saveMultiFile($route.params.id)" variant="outline-primary">{{ $t('title.save')
          }}</b-button>
      </div>
    </b-modal>
    <!-- /*******
      Modal preview
      */ -->
    <b-modal ref="preview-dpf" size="xl" :title='title' hide-footer centered>
      <embed :src='path + url_link + `#toolbar=0`' type="application/pdf" width="100%" height="550px">
    </b-modal>
  </div>
</template>



<script>
import { mapState } from "vuex";
import UrlForShow from "../../../services/show-url";

export default {
  data() {
    return {
      loading_file:false,
      popupMuli:false,
      pdf_file:null,
      files: "",
      pdfs_base64: [],
      isUpdate: false,
      modal_title: "",
      path: UrlForShow(),
      doc_land: {
        no: "",
        title: '',
        link_file: null,
      },
      title: '',
      url_link: '',
      doc_id: null,
      max: 100,
    }
  },

  computed: {
    ...mapState({
      docLands: state => state.lands.documents,
      isUpload: state => state.documents.isUpload,
      progress: state => state.documents.progress,
      popup: state => state.documents.popup,
      errors: state => state.documents.errors,
      doc_file: state => state.documents.document
    }),
    propupM(){
      return this.popupMuli;
    }
   
    
  },
  mounted() {
    this.getLandDocument();
  },
  methods: {
  pdfData($e) {
      const files = $e.target.files;
      if (files.length<=10) {
        for (let index = 0; index < files.length; index++) {
        try {
          const element = files[index];
          var reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onload = (i) => {
            if (this.pdfs_base64.length<=10) {
              this.pdfs_base64.push({ no: index + 1, name: files[index].name.replace('.pdf', '').trim(), file: i.target.result });
            }
          };
        } catch (error) {
          console.log(error);
        }
        
      }
      } else {
        alert("Cannot upload files max then 10 file.");
      }
      
    },
    remove(key) {
      this.pdfs_base64.splice(key, 1);
    },
    changeNo(key, field, $value) {
      this.pdfs_base64[key][field] = $value;
    },

    reset() {
      this.pdfs_base64 = [];
    },
    saveMultiFile() {
      this.$store.dispatch("loading", true);

      axios.post(`${process.env.VUE_APP_API_URL}/document-item/${this.$route.params.id}`,{pdfs_base64:this.pdfs_base64})
        .then((response) => {
          this.$store.dispatch('lands/getLand');
          this.$store.dispatch("loading", false);
          this.popupMuli=false;
          this.reset();
        })
        .catch((err)=>{
          console.log(err.status);
        }).finally(()=>{
        this.$store.dispatch("loading", false);
        });
      // this.$store.dispatch('documents/saveMultiFile', [this.$route.params.id,this.pdfs_base64]);
    },
    showAddMultiFile() {
      this.$refs['add-multi-file'].show();
      this.popupMuli=true;
    },

    /****
     * get a file
     */
    getOneDocument(id) {
      this.$store.dispatch("loading", true);
      axios.get(`document-files/${id}`).then((res) => {
        this.doc_land.no = res.data.no;
        this.doc_land.title = res.data[`title_${this.$i18n.locale}`];
        this.doc_land.link_file = res.data.link_file;
      
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.$store.dispatch("loading", false);
      })
    },
    /******
     * remove file 
     */
    onRemove() {
      this.doc_land.link_file = null;
    },
    /**
     * get document from land with relationship
     */
    getLandDocument() {
      this.$store.dispatch('lands/getLand');
    },
    /******
     * show popup modal create upload document
     */
    show() {
      this.isUpdate = false;
      this.modal_title = "Add Document";
      this.doc_land = [];
      this.$store.commit('documents/setErrors', []);
      this.$store.dispatch('documents/onPopup', true);
    },
    /********
     * show popup modal Edit document file
     */
    updateShow(id) {
      this.isUpdate = true;
      this.modal_title = "Edit Document";
      this.$store.dispatch('documents/onPopup', true);
      this.doc_id = id;
      this.getOneDocument(id);
    },
    /****
     * preview file PDF
     */
    previewPopup(el) {
      this.$refs["preview-dpf"].show();
      this.no = this.docLands[el].no;
      // this.title = this.docLands[el][`title_${this.$i18n.locale}`];
      this.url_link = this.docLands[el]['link_file'];
    },
    /*******
     * On insert or update document file
     */
    onSave() {
      let formData = new FormData();
      formData.append("no", this.doc_land.no);
      formData.append("title", this.doc_land.title);
      formData.append("link_file", this.doc_land.link_file);
      formData.append("land_id", this.$route.params.id);
      if (!this.isUpdate) {
        this.$store.dispatch('documents/onCreate', formData);
      } else {
        var id = this.doc_id;
        this.$store.dispatch('documents/onUpdate', [id, formData, this.$i18n.locale]);
      }
    },
    /***
     * on remove a record
     */
    onDelete(id) {
      this.$store.dispatch('documents/onDelete', id);
    }
  }
}
</script>

<style>
.isUpload {
  opacity: 0.6;
  pointer-events: none;
}

.card-header a {
  color: #000000 !important;
  /* background-color: #343a40;
  border-color: #343a40; */
}
</style>