<template>
    <div>
        <div class="content-header">
            <div class="container">
                <div class="row">
                    <div class="col-sm-8 col-12">
                        <div class="d-flex align-items-center">
                            <router-link v-if="associateLand[0]" :title="$t('back')" :to="{name:'DashboardDetail',params:{id:associateLand[0].province_id}}" class="d-flex align-items-center font-weight-bold">
                                <BackIcon />
                            </router-link>
                            <router-link v-else class="m-0" to="/dashboard"> <BackIcon /></router-link>
                            <h4 class="m-0 text-dark font-weight-bold">{{ district[`name_${$i18n.locale}`] }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="content position-relative my-4">
            <div class="container shadow rounded-2">
                
                <ul id="associateLand" v-if="associateLand">
                    <li v-for="(mainLand, index) in associateLand" :key="index">
                        <div class="popup_item p-2">
                            <p class="mb-md-0 text-left"> 
                                {{ mainLand[`land_name_${$i18n.locale}`] }}
                            </p>
                          
                            <div class="d-flex align-items-center">
                                
                                <router-link :to="{ name: 'map-view', params: { id: mainLand.id } }"
                                    class="px-3 d-block mr-1 border rounded-pill">
                                    {{ $t("title.location_land") }}
                                </router-link>
                                <router-link :to="{ name: 'detailLand', params: { id: mainLand.id } }"
                                    class="px-3 d-block mr-1 border rounded-pill">
                                    {{ $t("land-detail.document") }}
                                </router-link>
                            </div>
                        </div>
                        <ol v-if="mainLand.children">
                            <li class="subLand" v-for="(land, index) in mainLand.children" :key="index">
                                <div class="popup_item">
                                    <p class="mb-md-0 text-left"> 
                                        {{ land[`land_name_${$i18n.locale}`] }}
                                    </p>
                                 
                                    <div class="d-flex align-items-center">
                                        <router-link v-if="land.link_google_map" :to="{ name: 'map-view', params: { id: land.id } }"
                                            class="px-3 d-block mr-1 border rounded-pill d-flex align-items-center">
                                            {{ $t("title.location_land") }}
                                        </router-link>
                                        <router-link :to="{ name: 'detailLand', params: { id: land.id } }"
                                            class="px-3 d-block mr-1 border rounded-pill">
                                            {{ $t("land-detail.document") }}
                                        </router-link>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </li>
                </ul>
                <div class="text-center" v-else>

                    {{ $t("title.no_result") }}

                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BackIcon from '../components/my/icons/BackIcon.vue';
import tooltip from '../components/my/tooltip.vue';

export default {
    components: { BackIcon,tooltip },
    computed: {
        /******
         * land data by district
         */
        associateLand() {
            return this.$store.state.lands.associateLand;
        },
        district(){
            return this.$store.state.district.district;
        }
    },
    mounted() {
        this.$store.dispatch("lands/getAssociateLand", this.$route.params.id);
        this.$store.dispatch("district/getDistrict",this.$route.params.id);
    }

}

</script>

<style lang="scss">
    #associateLand>li {
        list-style: number;
        // border: 1px solid #e0edf1;
        // border-radius: 5px;
        padding-left: 10px;
    }
    ol>li {
        list-style: square;
    }
</style>