<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 col-12 mx-auto mt-5">
        <div class="result-search">
            <h2 class="nav-item">Result : {{ resultSearch }}</h2>
            <input type="hidden" v-model="search">
            <ul class="mt-5">
              <li v-for="i of resultQuery" :key="i.id">{{ i.title[$i18n.locale] }}</li>
              <li v-if="!resultQuery.length"> No results, sorry! </li>
            </ul>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      resultSearch: '',
      resources: [
        { id: 1, title: { en: "javascript for dummies",  kh: "javascript តេស" } },
        { id: 2, title: { en: "vue for dummies",  kh: "vue តេស" } },
        { id: 3, title: { en: "css for dummies",  kh: "css តេស" } },
        { id: 4, title: { en: "window for dummies",  kh: "window តេស" } },
        { id: 5, title: { en: "html for dummies",  kh: "html តេស" } },
      ],
    };
  },
  created() {
    this.search = window.location.href.split("=").pop();
    this.resultSearch = this.search.split('-').join(' ');
  },
  computed: {
    resultQuery() {
      if (this.search) {
        return this.resources.filter(item => {
          return this.search
            .toLowerCase()
            .split('-')
            .every(v => item.title.en.includes(v));
        });
      } else {
        return this.resources;
      }
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss">
  .result-search {
    ul {
      list-style: none;
    }
    li {
      font-size: 20px;
      margin: 10px 0;
    }
  }
</style>