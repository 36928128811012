
import router from '@/router';
const country = {
    namespaced: true,
    state: {
        countries: [],
        country: [],
        provinces: [],
        errors: [],
    },
    mutations: {
        /**************
         * set data in countries
         */
        setCountries(state, countries) {
            state.countries = countries
        },
        /**************
         * set single data in country
         */
        setCountry(state, country) {
            state.country = country
        },
        /**************
         * set single data in country
         */
         setProvinces(state, provinces) {
            state.provinces = provinces
        },
        /*****
         * set error 
         */
        setErrors(state, error) {
            state.errors = error;
        }
    },
    getters: {},
    actions: {
        /*************
         * get data all
         */
        getCountries({ commit }) {
            this.dispatch('loading', true);
            this.dispatch("storage/getOwner");
            var owner = this.state.storage.owner;

            axios.get(`/countries?owner=${owner}`).then((res) => {
                commit('setCountries', res.data);
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.dispatch('loading', false);
            });
        },
        /************
         * Add new record
         */
        onCreate({ commit }, [data, locale]) {
            this.dispatch('loading', true);
            axios.post(`/countries?lang=${locale}`, data).then((res) => {
                router.push({ path: "/countries" });
                commit('setErrors', []);
            }).catch((err) => {
                commit('setErrors', err.response.data.errors);
            }).finally(() => {
                this.dispatch('loading', false);
            });
        },
        /***********
         * get one country
         */
        getCountry({ commit }) {
            this.dispatch('loading', true);
            this.dispatch("storage/getOwner");
            var owner = this.state.storage.owner;

            axios.get(`/countries/${router.currentRoute.params.id}?owner=${owner}`)
                .then((res) => {
                    commit('setCountry', res.data);
                    commit('setProvinces', res.data['provinces']);
                })
                .catch((err) => {
                    console.log(err);
                }) 
                .finally(() => {
                    this.dispatch('loading', false);
                });
        },
        /**********
         * Update record
         */
        onUpdate({ commit }, [locale,data]) {
            this.dispatch('loading', true);
            axios.put(`/countries/${router.currentRoute.params.id}?lang=${locale}`, data)
                .then((res) => {
                    router.push({ path: "/countries" })
                }).catch((err) => {
                    commit('setErrors', err.response.data.errors);
                })
                .finally(() => {
                    this.dispatch('loading', false);
                });
        },
        /***********
         * Destroy one cord
         */
        onDelete({ commit }, id) {
            this.dispatch('loading', true);
            axios.delete(`/countries/${id}`).then((res) => {
                this.dispatch('country/getCountries')
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.dispatch('loading', false);
            })
        }
    },
};
export default country;