<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t("title.user") }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i> {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/users" class="text-dark font-weight-bold">{{
                    $t("title.user")
                }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.update") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-10 mt-5 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">{{ $t("title.user") }}</h3>
              </div>
              <form @submit.prevent="onUpdate">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-6">
                      <label for="exampleInputEmail1">{{ $t("title.name")
                      }}<span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="user.name" />
                      <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                    </div>
                    <div class="col-6">
                      <label for="exampleInputEmail1">{{ $t("title.email")
                      }}<span class="text-danger">*</span></label>
                      <input type="email" class="form-control" v-model="user.email" />
                      <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span>
                    </div>
                    <div class="col-6 mt-3">
                      <label for="exampleInputEmail1">User Role</label>
                      <select class="custom-select rounded-0" v-model="user.roles">
                        <option v-for="r in roles" :value="r.id" :key="r.id">
                          {{ r.name }}
                        </option>
                      </select>
                      <span class="text-danger"></span>
                    </div>
                    <div class="col-6 mt-3">
                      <label for="exampleInputPassword1">{{
                          $t("title.password")
                      }}</label>
                      <input type="password" class="form-control" v-model="user.password" />
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">
                    {{ $t("title.update") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import hasPer from '../../services/permission';

export default {
  data() {
    return {

    };
  },
  computed: {
    permission: {
      get() {
        return this.$store.state.permission;
      }
    },
    roles: {
      get() {
        return this.$store.state.roles.roles;
      }
    },
    user: {
      get() {
        return this.$store.state.users.user;
      },
    },
    errors: {
      get() {
        return this.$store.state.users.errors;
      }
    },
  },

  mounted() {
    this.getRoles();
    this.getUser();
    /***
   * check permission redirect
   */
    if (this.permission.length) {
      if (!this.hasPermission('edit-user')) {
        this.$router.push({ name: "Dashboard" });
      }
    }
  },

  methods: {
    /***
    * Check permission
    */
    hasPermission(per) {
      return hasPer(this.permission, per);
    },
    /*******
     * get all roles
     */
    getRoles() {
      this.$store.dispatch('roles/getRoles');
    },
    /*****
     * create one record
     */
    onUpdate() {

      const data = {
        name: this.user.name,
        email: this.user.email,
        roles: this.user.roles,
        password: this.user.password
      }
      this.$store.dispatch('users/onUpdate', data);
    },
    /****
     * get a user
     */
    getUser() {
      this.$store.dispatch('users/getUser');
    },
  },
};
</script>

<style>
</style>