<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t("title.country") }}</h4>
          </div>
          <div class="col-sm-6 text-right">
            <ol class="breadcrumb justify-content-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i> {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.country") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-12 mx-auto mt-5">
            <span v-if="hasPermission('create-country')">
              <router-link to="/country/create" class="btn btn-primary mb-4 rounded-pill">
                <i class="nav-icon fa fa-plus-square mr-2"></i> {{ $t("button.add_new") }}
              </router-link>
            </span>
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th style="width: 100px">{{ $t("land-detail.no") }}</th>
                  <th v-column-sortable:name_en class="w-50">
                    {{ $t("title.countries_name") }}
                  </th>
                  <th>{{ $t("title.land_count") }}</th>
                  <th
                    class="w-25"
                    v-if="
                      hasPermission('detail-country') ||
                      hasPermission('update-country') ||
                      hasPermission('delete-country')
                    "
                  >
                    {{ $t("title.action") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="(c, index) in countries" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ c[`name_${$i18n.locale}`] }}</td>
                  <td>{{ c.lands_count }}</td>
                  <td
                    v-if="
                      hasPermission('detail-country') ||
                      hasPermission('update-country') ||
                      hasPermission('delete-country')
                    "
                  >
                    <span v-if="hasPermission('detail-country')">
                      <router-link
                        :to="{ name: 'countryDetail', params: { id: c.id } }"
                        class="btn btn-info btn-sm mr-2"
                      >
                        {{ $t("title.detail") }}
                      </router-link>
                    </span>
                    <span v-if="hasPermission('update-country')">
                      <router-link
                        :to="{ name: 'country', params: { id: c.id } }"
                        class="btn btn-primary btn-sm"
                      >
                        <i class="fas fa-edit"></i>
                      </router-link>
                    </span>
                    <span v-if="hasPermission('delete-country')">
                      <button
                        class="btn btn-danger btn-sm ml-2 show_confirm"
                        @click="onDelete(c.id)"
                        :disabled="c.provinces.length != 0"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </span>
                  </td>
                </tr>
                <tr v-if="!countries.length">
                  <td colspan="4" class="text-center">
                    {{ $t("title.no_result") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import columnSortable from "vue-column-sortable";
import hasPer from "../../services/permission";

export default {
  data() {
    return {
      lands_count: [],
    };
  },
  computed: {
    countries: {
      get() {
        return this.$store.state.country.countries;
      },
    },
    user: {
      get() {
        return this.$store.state.user;
      },
    },
    permission: {
      get() {
        return this.$store.state.permission;
      },
    },
  },

  mounted() {
    this.getCountries();
    if (this.permission.length) {
      if (!this.hasPermission("country-lists")) {
        this.$router.push({ path: "/dashboard" });
      }
    }
  },

  methods: {
    /***
     * Method for check permission
     */
    hasPermission($per) {
      return hasPer(this.permission,$per);
    },
    
    /**********
     * get all data countries
     */
    getCountries() {
      this.$store.dispatch("country/getCountries");
    },
    /******
     * Deleted one record
     */
    onDelete(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.dispatch("country/onDelete", id);
        }
      });
    },

    orderBy(fn) {
      this.countries.sort(fn);
    },
  },
  directives: {
    columnSortable,
  },
};
</script>

<style></style>
