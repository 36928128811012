<template>
  <div>
    <table class="table table-bordered table-striped">
      <thead>
        <tr class="text-center">
          <th>{{ $t('land-detail.no') }}</th>
          <th v-column-sortable:[`land_name_${$i18n.locale}`]>
            {{ $t("title.location_land") }}

          </th>
          <th v-column-sortable:square_meter>
            {{ $t('land-detail.description.size') }}
          </th>
          <th v-if="hasPermission('update-land') || hasPermission('delete-land')">{{ $t('title.action') }}</th>
        </tr>
      </thead>
      <tbody class="text-center" v-for="(l, index) in lands" :key="l.id">
        <tr>
          <td class="text-left">{{ index+1 }}</td>
          <td>
            {{ l[`land_name_${$i18n.locale}`] }}
          </td>
          <td>
            <span v-if="l.square_meter">
              {{ l.square_meter | comma }}
            </span>
          </td>
          <td v-if="hasPermission('update-land') || hasPermission('delete-land')">
            <router-link :to="{ name: 'landEdit', params: {id: l.id} }" 
                class="btn btn-primary btn-sm mr-2"
                v-if="hasPermission('update-land')">
              <i class="fas fa-edit"></i>
            </router-link>
            <button class="btn btn-sm btn-danger" 
                  @click="deleteLand(l.id)"
                  :disabled="l.children != 0"
                  v-if="hasPermission('delete-land')">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
        <tr v-for="(c, c_index) in l.children"
                  :key="c_index">
          <td class="text-left">{{ (index + 1) }}.{{ c_index+1 }}</td>
          <td>
            {{ c[`land_name_${$i18n.locale}`] }}
          </td>
          <td>
            <span v-if="c.square_meter">
              {{ c.square_meter | comma }}
            </span>
          </td>
          <td v-if="hasPermission('update-land') || hasPermission('delete-land')">
            <router-link :to="{ name: 'landEdit', params: {id: c.id} }" 
                class="btn btn-primary btn-sm mr-2"
                v-if="hasPermission('update-land')">
              <i class="fas fa-edit"></i>
            </router-link>
            <button class="btn btn-sm btn-danger" 
                  @click="deleteLand(c.id)"
                  v-if="hasPermission('delete-land')">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-if="lands.length == 0">
        <tr>
          <td colspan="4" class="text-center" v-if="isLoading">
            <b-spinner label="Spinning"></b-spinner>
          </td>
          <td colspan="4" class="text-center" v-else>
            {{ $t('title.no_result') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import columnSortable from 'vue-column-sortable';
import hasPer from "../services/permission";

export default {
  data() {
    return {
      isLoading:false
    }
  },

  computed: {
    /******
     * lands data
     */
    lands: {
      get() {
        return this.$store.state.province.lands;
      },
    },

    /******
     * permssion data
     */
    permission: {
      get() {
        return this.$store.state.permission;
      },
    },
  }, 

  mounted() {
    this.getProvince();
  },
  methods: {
    /****
     * method check permission
     */
    hasPermission(per) {
     return hasPer(this.permission,per);
    },

    getProvince(){
      this.$store.dispatch("province/getProvince");
    },

    async deleteLand(id) {
      await swal({
        content: {
          element: "input",
          attributes: {
            placeholder: "Type your password"
          },
        },
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((value) => {
        var data = { password: value};
          axios.delete(`/lands/${value}/${id}`, data).then((res) => {
            if (res.data.status == 200) {
              swal({
                icon: "success",
                dangerMode: true,
                title: "Deleted Successfully"
              }).then((willDelete) => {
                if (willDelete) {
                  this.getProvince();
                }
              })
              
            } else {
              swal({
                icon: "error",
                dangerMode: true,
                title: "Deleted Fail",
                text: "Wrong Password"
              })
            }
            
          });
      });
    },

    orderBy(fn) {
      this.lands.sort(fn);
    }
  },
  directives: {
    columnSortable,
  }
}
</script>

<style>

</style>