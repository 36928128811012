const permissions = {

    namespaced: true,
    state: {
        permissions: [],
    },
    mutations: {
        /**
         * set permission
         */
        setPermissions(state, permissions) {
            state.permissions = permissions
        }
    },
    getters: {

    },
    actions: {
        /*******
        * get all permission
        */
        getPermissions({ commit }) {
            this.dispatch('loading', true);
            axios.get('permission').then((res) => {
                commit('setPermissions', res.data);
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.dispatch('loading', false);
            });
        }
    }
}

export default permissions;