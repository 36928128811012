<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t('title.list_land') }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/landType" class="text-dark font-weight-bold">
                  <i class="fa fa-map-pin mr-1"></i> {{ $t('title.list_land') }}
                  </router-link>
                </li>
              <li class="breadcrumb-item active">{{ $t('title.create')}}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <section class="content position-relative mt-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-10 my-5 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Document Title</h3>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-2">
                    <label for="">លេខរៀង</label>
                    <input type="text" class="form-control" v-model="Doc_title.position">
                    <span class="text-danger">{{ err_no }}</span>
                  </div>
                  <div class="col-6">
                    <label for="">{{ $t('title.name') }}</label>
                    <textarea class="form-control" rows="3" v-model="Doc_title.title"></textarea>
                    <input type="hidden" class="form-control" v-model="Doc_title.land_type_id">
                    <span class="text-danger">{{ err_title }}</span>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary" @click="createDocTitle">{{ $t('title.save')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Doc_title: {
        title: '',
        land_type_id: this.$route.params.id
      },
      err_title: '',
      err_no:""
    }
  },

  mounted() {
    axios.get(`/land-type`)
    .then((res) => {
      if(res.data != 'no-permission') {
        this.landType = res.data;
      }else {
        this.$router.push({ path: '/dashboard' });
      }
    })
  },

  methods: {
    createDocTitle() {
      axios.post(`/document-title`, this.Doc_title).then((res) => {  
        this.$router.push({ path: `/landType/edit/${this.$route.params.id}` });
      }).catch((err) => {
        if(err.response.data.errors.title){
          this.err_title = err.response.data.errors.title[0];
          this.err_no = err.response.data.errors.no[0];
        }
      })
    }
  }
}
</script>

<style>

</style>