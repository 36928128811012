var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('h4',{staticClass:"m-0 text-dark"},[_vm._v("User Role")]),_c('div',{staticClass:"d-block"},[(_vm.hasPermission('create-role'))?_c('router-link',{staticClass:"btn btn-sm btn-primary mt-2",attrs:{"to":"/role/create"}},[_c('i',{staticClass:"nav-icon fa fa-plus-square mr-2"}),_vm._v(" "+_vm._s(_vm.$t("button.add_new"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-6 text-right"},[_c('ol',{staticClass:"breadcrumb justify-content-end"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{staticClass:"text-dark font-weight-bold",attrs:{"to":"/dashboard"}},[_c('i',{staticClass:"fas fa-home mr-1"}),_vm._v(_vm._s(_vm.$t("title.home"))+" ")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{staticClass:"text-dark font-weight-bold",attrs:{"to":"/users"}},[_c('i',{staticClass:"fas fa-users mr-1"}),_vm._v(_vm._s(_vm.$t("title.user"))+" ")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("User Role")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-12 mx-auto mt-5"},[_c('table',{staticClass:"table table-bordered table-striped",attrs:{"id":"user_list"}},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v(_vm._s(_vm.$t("land-detail.no")))]),_c('th',{directives:[{name:"column-sortable",rawName:"v-column-sortable:name",arg:"name"}]},[_vm._v(_vm._s(_vm.$t("title.name")))]),(
                  _vm.hasPermission('edit-role') ||
                  _vm.hasPermission('delete-role')
                )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("title.action"))+" ")]):_vm._e()])]),_c('tbody',[_vm._l((_vm.roles),function(r,index){return _c('tr',{key:r.id,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(r.name))]),(_vm.hasPermission('edit-role') || _vm.hasPermission('delete-role'))?_c('td',[(_vm.hasPermission('edit-role'))?_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":{ name: 'roleEdit', params: { id: r.id } }}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(
                    _vm.hasPermission('delete-role') &&
                    r.display_name != 'super-user'
                  )?_c('button',{staticClass:"btn btn-danger btn-sm ml-2 show_confirm",on:{"click":function($event){return _vm.deleteRole(r.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()],1):_vm._e()])}),(!_vm.roles.length)?_c('tr',{staticClass:"text-center"},[(_vm.isLoading)?_c('td',{attrs:{"colspan":"11"}},[_c('b-spinner',{attrs:{"label":"Spinning"}})],1):_c('td',{attrs:{"colspan":"4"}},[_vm._v(_vm._s(_vm.$t("title.no_result")))])]):_vm._e()],2)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }