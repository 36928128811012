<template>
  <div id="app" :class="$i18n.locale" :style="{ fontSize: user.font_size + 'pt !important' }" class="overflow-hidden">
    <router-view />
    <loader />
  </div>

</template>
<script>
import loader from './components/loader.vue'
import store from './store';
export default {
  components: {
    loader
  },
  data() {
    return {
      // isLoading:false,
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      }
    },
    permission: {
      get() {
        return this.$store.state.permission;
      }
    }
  },
  mounted() {
    /*****
     * get user logged
     */
    // if($cookies.get("token")) {
    //   this.getUser();
    // }
  },
  methods: {
    // getUser(){
    //   store.dispatch('getUser');
    // }
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "khmer os";
  src: url(./assets/fonts/khmer-os-system/KhmerOS_sys.ttf);
}

#app,
#modal-land,
#modal-district {
  font-size: 16pt !important;
}

.kh,
a,
.popup_content,
.modal {
  font-family: "khmer os" !important;

}

html {
  // width:100%;
  // max-width:100vh;
  background-color: gray;
}

.breadcrumb>li {
  list-style: none !important;
}


</style>
