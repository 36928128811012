<template>
    <div>
        <div class="content-header">
            <div class="container">
                <div class=" d-flex align-items-center">
                    <router-link to="/dashboard" :title="$t('back')"><BackIcon/></router-link>
                    <h4 class="m-0 text-dark font-weight-bold">{{ province[`name_${$i18n.locale}`] }}</h4>
                </div>
            </div>
        </div>
        <section class="content position-relative my-4">
            <div class="container text-center shadow p-2">
              <template v-if="is_pp">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2243 1859">
                <g id="Web_1920_1" data-name="Web 1920 – 1">
                  <!-- <rect  width="2243" height="1859"  fill="#fff"/> -->
                  <g id="Phnom_Penh_district" transform="translate(200.958 -0.328)">
                    <g id="chbar_ompov" data-name="chbar ompov" class="path_zoon" transform="translate(1211.071 1058.719)"
                      @click.prevent="lans_popup(10)">
                      <path id="Path_4" data-name="Path 4"
                        d="M1624.354,1269.581l30.816,3.508,26.622-1.4,20.655-1.058,30.816-1.048h36.773l16.108-8.055,26.27,11.563,34.666,5.6,25.211,3.5,26.269,8.054,25.211,8.054-14.707,19.264-10.5,24.163-12.61,18.558-29.767,27.318-16.109,26.612-4.546,17.156,3.5,19.264,4.556,31.168,4.546,30.815-2.449,22.066V1552.9l5.957,18.558v18.205l1.048,26.269,113.465,7.006-31.864,12.6-29.767,13.659-22.066,15.06-19.264,16.109-12.6,17.156,4.546,20.665h-32.217l-17.157-1.048h-23.114l-19.617-1.4h-37.822l-25.211,1.4-27.318,4.556-22.056,2.1-16.109,3.5-21.713,5.958-28.719,9.1-27.318-4.546-26.612-2.459-17.157-2.1-18.215-5.958-20.665-13.658-17.157-19.607-16.108-25.211-12.61-19.616-11.552-19.264-12.611-23.1-12.6-28.719-10.514-20.665-19.254-33.265-4.909-20.665-27.318-81.248-12.6-55.331-21.713-25.211-1.4-17.157-1.048-17.167,15.05-19.606,34.324-16.109,21.713-16.108,42.378-67.589,23.114,12.6,18.205,9.113,26.612,12.6,24.162,16.108,21.714,10.514,27.317,8.054,26.612,4.546,26.269,3.508,24.163,4.547"
                        transform="translate(-1292.72 -1187.99)" fill="#dceaf7" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g transform="matrix(1, 0, 0, 1, -1412.23, -1058.54)">
                        <text id="_11" data-name="ច្បារអំពៅ" transform="translate(1619 1446)" fill="#090909"
                          font-size="56" font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">ច្បារអំពៅ</tspan>
                        </text>
                      </g>
                      <g id="number" transform="translate(229.039 239.946)">
                        <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="42.117" cy="42.959" rx="42.117" ry="42.959"
                          transform="translate(0 0)" fill="#fff" v-if="result_district[10]" />
                        <text id="_2-2" data-name="2" transform="translate(30.316 54.777)" font-size="40"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[10]">{{ result_district[10] }}</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="chroy_jongva" data-name="chroy jongva" class="path_zoon" transform="translate(807.291 35.067)"
                      @click.prevent="lans_popup(11)">
                      <path id="Path_5" data-name="Path 5"
                        d="M1222.393,172.516l25.211,2.452h76l19.264,1.05,25.211,5.6,18.558,12.606,5.6,19.611,4.556,18.561,12.61,17.16,22.056,24.164,17.167,17.16,11.552,23.813,18.215,3.5,24.163,1.4L1498,337.81l-8.054,24.164-7.006,24.164-5.6,22.763-5.947,31.168-6.653,20.662-7.006,19.262L1447.568,503.1l-20.654,21.713-12.964,29.766-9.1,23.113-22.762,63.036-16.108,18.561-11.562,20.662-11.552,14.708-12.61,18.561-8.055,18.21-9.1,29.766-4.556,16.109-2.45,19.611-6.653,22.763,6.653,28.717,7.006,21.712,4.557,22.063,20.655,30.817,1.048,27.667,9.455,21.712,10.161,27.666,4.557,19.265,3.5,19.606,3.5,27.671,2.459,27.308,4.546,73.546-19.606,5.958-17.167-5.958-14-10.161-12.611-12.6-13.659-19.616-10.15-17.157-16.109-23.115-12.611-22.761-16.109-24.163-10.5-21.713V994.083l-6.653-27.316L1235,935.6V913.888l-3.508-17.16-4.546-24.164-8.054-21.712-10.161-17.16-12.6-15.059-10.513-13.658-18.205-18.56-25.221-14.708L1126.085,755.6l-24.163-10.506-16.108-6.654-18.205-7-28.719-11.557-24.163-10.155-20.664-11.557L976.9,678.554,960.8,660.343l-12.607-18.56-12.607-20.662-15.059-17.16L907.915,584.7l-15.759-22.062,7.7-24.164h89.651l38.875,2.45,43.769-1.05,28.719,1.05,8.054-68.989,1.048-20.662,7.006-20.662,20.665-17.16,16.108-13.658,13.659-19.61,4.546-26.265,7.006-25.214,6.653-17.16,20.664-26.615,5.958-26.265,3.5-24.164-1.4-18.21V196.68l8.054-24.164"
                        transform="translate(-892.156 -172.516)" fill="#cfe1f2" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g transform="matrix(1, 0, 0, 1, -1008.39, -34.74)">
                        <text id="_12" data-name="ជ្រោយចង្វារ" transform="translate(1249 346)" fill="#090909"
                          font-size="56" font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">ជ្រោយចង្វារ</tspan>
                        </text>
                      </g>
                      <g id="number-2" data-name="number" transform="translate(289.828 344.614)">
                        <ellipse id="Ellipse_1-2" data-name="Ellipse 1" cx="42.117" cy="42.959" rx="42.117" ry="42.959"
                          transform="translate(0 0)" fill="#fff" v-if="result_district[11]" />
                        <text id="_2-3" data-name="2" transform="translate(30.316 54.777)" font-size="40"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[11]">{{ result_district[11] }}</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="por_senchey" data-name="por senchey" class="path_zoon" transform="translate(359.742 765.938)"
                      @click.prevent="lans_popup(6)">
                      <path id="Path_12" data-name="Path 12"
                        d="M590.35,1059.693l16.109,6.653h17.16l28.716,1.059L680,1065.3l4.553,60.936,35.37,3.155,21.712,5.947,92.1,8.054,23.814-2.45,30.117-3.5,21.712-2.107-4.552,39.928v33.618l-12.607,53.93,5.6,22.761,2.452,28.719,1.051,24.163,1.051,17.157,2.451,26.269,33.268,15.06H958.76l26.265,9.1,7,24.163-15.059,16.108-16.108,11.562-31.868,6.653-22.063,11.552-14.708,9.1-19.611,5.957-21.712-1.4-25.214-2.1H802.572l-32.219,3.5-9.1,13.659-3.5,19.607-23.814-3.5H692.609l-32.218-1.048-5.953-19.616,3.5-26.612,3.5-25.211V1419.7l-24.164-5.6-28.717-3.5-34.319-4.556-19.61,2.45-17.16,5.6-18.21,2.449-14.009-35.715,17.511-5.6-1.4-24.163,22.063-7.006-5.953-20.665-9.105-19.606-5.6-15.766-3.5-17.51-5.953-25.211-29.767,1.4-26.266-2.449-9.1-19.617,3.152-30.816,8.054-23.114,10.506-23.81,4.552-22.066-20.662-17.157,1.051-18.215,14.008-15.05,3.152-20.665,1.4-35.372,1.05-22.063,2.452-22.762,1.05-24.164,1.052-25.214,3.5-26.615,13.658,10.506,17.16,10.156,17.51,16.108,9.106,33.269,3.5,32.218,10.155,29.771,16.109,11.552,20.662,18.558"
                        transform="translate(-448.17 -897.555)" fill="#5ba3d0" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g transform="matrix(1, 0, 0, 1, -560.78, -765.72)">
                        <text id="_13" data-name="ពោធិ៍សែនជ័យ" transform="translate(636 1113)" fill="#090909"
                          font-size="56" font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">ពោធិ៍សែនជ័យ</tspan>
                        </text>
                      </g>
                      <g id="number-3" data-name="number" transform="translate(238.371 353.735)">
                        <ellipse id="Ellipse_1-3" data-name="Ellipse 1" cx="42.117" cy="42.959" rx="42.117" ry="42.959"
                          transform="translate(0 0)" fill="#fff" v-if="result_district[6]" />
                        <text id="_2-4" data-name="2" transform="translate(30.316 54.777)" font-size="40"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[6]">{{ result_district[6] }}</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="mean_chey" data-name="mean chey" class="path_zoon" transform="translate(803.778 1104.601)"
                      @click.prevent="lans_popup(7)">
                      <path id="Path_9" data-name="Path 9"
                        d="M1217.864,1365.522l19.616,8.054,19.607-12.611,12.61-13.659,28.719-5.948,21.713,25.212,12.6,55.331,27.318,81.248,4.909,20.664,19.254,33.265-65.13,23.114-81.6,29.767-44.827,11.552-7.006-113.807,8.054-16.108-1.049-29.768v-25.22L1188.1,1411.4l-23.1-4.557-27.318-7-18.558-5.6-18.215-4.556-21.713,5.6-27.661-3.5-37.822,2.45-32.222,9.1-26.265-9.1H934.558l-33.268-15.06-2.451-26.27-1.05-17.157-1.052-24.163-2.452-28.719-5.6-22.761,19.26-1.4,22.063-3.5,25.214-5.6,28.717-1.048,24.166-3.508,30.816-3.5,18.205-8.054,31.168,3.5,14.718,8.054,15.05,19.616,27.671,6.653,21.712,16.108,11.553,24.162,14.707,17.156,9.455,26.27,14.707,10.5"
                        transform="translate(-888.682 -1233.5)" fill="#73b3d8" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g transform="matrix(1, 0, 0, 1, -1004.88, -1104.43)">
                        <text id="_14" data-name="មានជ័យ" transform="translate(1047.64 1209.97)" font-size="56"
                          font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">មានជ័យ</tspan>
                        </text>
                      </g>
                      <g id="number-4" data-name="number" transform="translate(307.346 159.046)">
                        <ellipse id="Ellipse_1-4" data-name="Ellipse 1" cx="42.117" cy="42.959" rx="42.117" ry="42.959"
                          transform="translate(0 0)" fill="#fff" v-if="result_district[7]" />
                        <text id="_2-5" data-name="2" transform="translate(30.316 54.777)" font-size="40"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[7]">{{ result_district[7] }}</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="russey_keo" data-name="russey keo" class="path_zoon" transform="translate(779.629 483.699)"
                      @click.prevent="lans_popup(8)">
                      <path id="Path_13" data-name="Path 13"
                        d="M1175.689,1042.337l-18.557,8.054-38.88-17.156-21.713-27.671-32.217-44.824-30.816-57.432-3.5-19.261-15.06-17.51-11.562-15.759-11.2-16.109-5.954-18.561L958.914,804.9l-1.4-31.168,8.054-27.666-10.156-22.762L941.4,708.246l-17.16-4.552-21.712-5.6-10.156-19.611-27.665-8.054,71.091-52.88,12.607,20.662,12.607,18.56,16.109,18.21,17.159,19.611,20.664,11.557,24.163,10.155,28.719,11.557,18.205,7,16.108,6.654,24.163,10.506,16.109,16.11,25.22,14.708,18.206,18.56,10.513,13.658,12.6,15.059,10.161,17.16,8.054,21.712,4.546,24.163,3.508,17.16v21.712l-6.653,31.167,6.653,27.316v22.063l-24.162,5.6-13.659,12.6-21.713,11.562"
                        transform="translate(-864.711 -617.545)" fill="#1f6fb4" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g transform="matrix(1, 0, 0, 1, -980.72, -483.44)">
                        <text id="_15" data-name="ឫស្សីកែវ" transform="translate(1099 683)" fill="#090909" font-size="57"
                          font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">ឫស្សីកែវ</tspan>
                        </text>
                      </g>
                      <g id="number-5" data-name="number" transform="translate(203.479 227.956)">
                        <ellipse id="Ellipse_1-5" data-name="Ellipse 1" cx="42.117" cy="42.959" rx="42.117" ry="42.959"
                          transform="translate(0 0)" fill="#fff" v-if="result_district[8]" />
                        <text id="_2-6" data-name="2" transform="translate(30.316 54.777)" font-size="40"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[8]">{{ result_district[8] }}</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="prek_pnov" data-name="prek pnov" class="path_zoon" transform="translate(65.9 143.974)"
                      @click.prevent="lans_popup(12)">
                      <path id="Path_10" data-name="Path 10"
                        d="M718.766,290.015l17.51,1.05h19.26l19.612,7L785.3,329.938l7,22.063,7,22.762,4.553,19.611,4.552,17.16-1.051,17.16,23.813,28.716,12.957,23.113,9.106,19.262,17.16,12.607,10.156,27.665,17.51,21.713,15.759,22.062,12.607,19.261,15.059,17.16L870.4,673.133l-81.6,53.931-35.37,27.666,12.608,84.748-16.109,10.507L738.376,878.7l-3.5,21.712v21.712l-2.1,26.615,3.5,29.767-3.5,21.712-5.954,19.615-8.054,14.707L700.554,1053.1l-18.21,17.157-27.666,2.107-28.716-1.058H608.8l-16.109-6.653-20.662-18.558-16.109-11.552-10.155-29.771-3.5-32.218-9.106-33.269-17.51-16.109L498.49,913.02l-13.658-10.506-21.712-5.6-16.109,18.561-17.16,11.207L421.8,942.786l-1.4,18.561-15.759,5.6-15.059,17.159-23.113,9.106-21.712,3.5-18.21,5.953-28.717,3.152-32.218,1.4-29.767,3.5-19.61,4.556-9.106,13.659-8.054,17.157-23.113,11.562L165.8,1040.489l-9.106-23.114V996.717l11.557-17.16,9.1-16.109v-18.21l-7-24.164,5.6-20.662,15.058-33.268,8.055-20.662,5.6-20.662,2.452-18.211,5.6-19.61,5.952-33.269,5.6-33.269-8.054-32.218,24.164-40.273L253,631.809l-8.054-24.164,10.506-22.764,17.16-8.054,27.316-1.051,28.716-1.4,27.666-2.1,16.109-8.055,38.872-8.054L433,548.112l25.564-3.5,19.262-3.5,23.113-1.052,22.763,2.452,20.662-2.452,19.611-3.5,18.21-1.05h18.56l21.713-4.552-14.708-17.16,3.151-26.615,9.455-13.658,13.658-13.658,8.055-17.16L630.515,423.09l3.5-26.614,2.1-19.262-2.1-18.56L644.173,345l12.608-12.607,23.112-2.452-2.451-25.214L696,280.56l22.763,9.455"
                        transform="translate(-156.695 -280.56)" fill="#3182be" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g transform="matrix(1, 0, 0, 1, -266.9, -143.67)">
                        <text id="_16" data-name="ព្រែកព្នៅ" transform="translate(432 613)" font-size="57"
                          font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">ព្រែកព្នៅ</tspan>
                        </text>
                      </g>
                      <g id="number-6" data-name="number" transform="translate(393.519 363.939)">
                        <ellipse id="Ellipse_1-6" data-name="Ellipse 1" cx="42.117" cy="42.959" rx="42.117" ry="42.959"
                          transform="translate(0 0)" fill="#fff" v-if="result_district[12]" />
                        <text id="_2-7" data-name="2" transform="translate(30.316 54.777)" font-size="40"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[12]">{{ result_district[12] }}</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="dangkao" class="path_zoon" transform="translate(413.643 1260.777)"
                      @click.prevent="lans_popup(2)">
                      <path id="Path_6" data-name="Path 6"
                        d="M1122.228,1393l18.558,5.605,27.318,7,23.1,4.556,4.556,25.211v25.221l1.049,29.767-8.054,16.109,7.006,113.807,44.827-11.552-1.048,20.665-7.006,21.713-10.5,59.878,5.947,19.264,12.61,14.012-2.449,18.2,4.546,41.33,7.006,19.607-2.1,18.215-2.45,86.147-20.665-1.048-15.06-7.006-18.215-9.113-23.1-17.157-23.82-4.9-23.115,3.5-18.2-2.1-20.665,9.1-16.109,11.552-17.157,8.055-14.012-18.558-20.665-14.707-14.7,11.209-12.607,26.612-12.608,24.162-12.607,22.762-18.56-16.109L930.664,1939.3l-26.264,9.1-23.113,8.054-18.21-10.5-16.109-22.762-24.164-4.556H796.538l-75.993-4.547-15.759-23.114-8.055-20.665-12.606-13.659-9.456-15.05-18.211,7L641.4,1866.814l11.556,18.558-9.1,23.81-25.214,9.456H594.473l-23.112-8.055-17.16-15.05-19.262-26.27-2.452-31.169-21.712,4.557-9.1-19.264,13.657-32.217,12.608-25.211,18.21-18.568h30.117l19.261,8.054,15.058-23.81,7-24.162,4.553-17.157,1.05-18.569,4.552-24.162,2.452-23.81,8.054-26.612,5.6-17.168,12.606-15.06-9.1-25.211,12.608-23.809,32.218,1.048H733.5l23.814,3.5,3.5-19.606,9.1-13.659,32.219-3.5h23.113l25.214,2.1,21.712,1.4,19.611-5.958,14.708-9.1,22.063-11.552,31.868-6.653,16.108-11.562,15.059-16.108-7-24.163,32.222-9.1,37.822-2.449,27.661,3.5,21.713-5.6,18.215,4.556"
                        transform="translate(-501.671 -1388.44)" fill="#bed8ed" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g id="number-7" data-name="number" transform="translate(263.212 177.507)">
                        <ellipse id="Ellipse_1-7" data-name="Ellipse 1" cx="42.117" cy="42.959" rx="42.117" ry="42.959"
                          transform="translate(0 0)" fill="#fff" v-if="result_district[2]" />
                        <text id="_2-8" data-name="2" transform="translate(30.316 54.777)" font-size="40"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[2]">{{ result_district[2] }}</tspan>
                        </text>
                      </g>
                      <g transform="matrix(1, 0, 0, 1, -614.69, -1260.63)">
                        <text id="_17" data-name="ដង្កោ" transform="translate(871 1614)" font-size="58"
                          font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">ដង្កោ</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="kamboul" class="path_zoon" transform="translate(-45.462 760.317)"
                      @click.prevent="lans_popup(14)">
                      <path id="Path_8" data-name="Path 8"
                        d="M458.055,1089.5,457,1107.72l20.662,17.157-4.552,22.066-10.506,23.81-8.054,23.114-3.152,30.816,9.1,19.616,26.265,2.45,29.767-1.4,5.953,25.211,3.5,17.51,5.6,15.766,9.106,19.606,5.953,20.665-22.063,7.006,1.4,24.163-17.511,5.6,14.009,35.715,18.21-2.45,17.16-5.6,19.61-2.45,34.32,4.556,28.717,3.5,24.164,5.6v28.719l-3.5,25.211-3.5,26.612,5.953,19.617-12.607,23.81,9.1,25.211-12.607,15.06-5.6,17.167-8.054,26.612-2.452,23.81-4.552,24.162-1.05,18.568-4.553,17.157-7,24.163-15.058,23.81-19.262-8.054H549.806L531.6,1769.95l-12.608,25.211-13.657,32.217-25.215,6.653-18.56-6.653-12.608-15.061,10.506-24.162-14.008-12.611L423.735,1771l-23.113-10.161-6.654-20.655-26.615-3.508-22.763-1.048,3.5,15.756-7,15.06-27.666-2.449-20.662,4.9,11.556,13.659,11.556,16.109-29.766-3.5L277,1774.5l-23.113,4.556-24.164,1.048,1.05-25.211v-82.649l4.9-25.211,3.152-22.771,15.058-18.558,8.055-20.665,21.712,1.4,27.666-7,22.764-13.659,3.5-18.568,8.054-16.109,12.608-14.707,8.054-22.056,1.05-22.771-1.05-34.313-7-28.719-30.818-17.157-18.21-19.617-16.109-21.713-23.113,5.6-21.712,8.054-19.611-15.06-6.653-24.163,2.1-28.366-11.556-24.163-19.262-9.1-22.062,9.1-19.262-1.4-19.611-3.145-1.05-20.664-2.452-17.51-5.6-15.756-7-17.509-15.059-15.766-13.658-10.5,15.059-16.108-1.4-18.215-9.105-23.1-24.164-2.107-18.21-5.958-4.553-19.253,20.662,3.145,19.611,2.46,27.316,1.048,27.666-2.107,20.662-26.612,14.708-10.151,23.113-11.562,8.054-17.157,9.106-13.659,19.61-4.556,29.767-3.5,32.218-1.4,28.717-3.152,18.21-5.953,21.712-3.5,23.113-9.106,15.059-17.159,15.759-5.6,1.4-18.561,8.054-16.108,17.16-11.207L464.008,892l21.712,5.6-3.5,26.615-1.051,25.214-1.05,24.164-2.452,22.762-1.05,22.063-1.4,35.372-3.152,20.665-14.008,15.05"
                        transform="translate(-46.22 -891.996)" fill="#8fc2de" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g id="number-8" data-name="number" transform="translate(356.907 519.722)">
                        <ellipse id="Ellipse_1-8" data-name="Ellipse 1" cx="42.117" cy="42.959" rx="42.117" ry="42.959"
                          transform="translate(0 0)" fill="#fff" v-if="result_district[14]" />
                        <text id="_2-9" data-name="2" transform="translate(30.316 54.777)" font-size="40"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[14]">{{ result_district[14] }}</tspan>
                        </text>
                      </g>
                      <g transform="matrix(1, 0, 0, 1, -155.52, -760.09)">
                        <text id="_18" data-name="កំបូល" transform="translate(435 1214)" font-size="57"
                          font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">កំបូល</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="sensok" class="path_zoon" transform="translate(591.572 536.566)"
                      @click.prevent="lans_popup(9)">
                      <path id="Path_14" data-name="Path 14"
                        d="M1045.166,1120.008l12.6,31.874,11.562,18.558-20.665,14.707-7.006,26.612,17.157,26.27-18.205,8.054-30.816,3.5-24.166,3.508-28.717,1.048-25.214,5.6-22.063,3.5-19.26,1.4,12.607-53.93v-33.618l4.552-39.928-21.712,2.107-30.117,3.5-23.814,2.449-92.1-8.054-21.712-5.947-35.37-3.155-4.553-60.936,18.21-17.157,18.211-18.558,8.054-14.707,5.953-19.615,3.5-21.712-3.5-29.767,2.1-26.615V897.282l3.5-21.712,11.557-28.716,16.109-10.507L749.244,751.6l35.37-27.665L866.211,670l27.665,8.054,10.156,19.611,21.712,5.6,17.16,4.553,14.008,15.059,10.156,22.763-8.054,27.665,1.4,31.168,27.316,11.206,5.954,18.561,11.2,16.109,11.562,15.759,15.06,17.51,3.5,19.262,30.816,57.432,32.217,44.824-20.654,12.611-10.5,15.06-19.264,9.1-2.449,78.093"
                        transform="translate(-678.153 -670.003)" fill="#105ca5" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g id="number-9" data-name="number" transform="translate(153.47 338.797)">
                        <ellipse id="Ellipse_1-9" data-name="Ellipse 1" cx="42.5" cy="43" rx="42.5" ry="43"
                          transform="translate(0.021 0.012)" fill="#fff" v-if="result_district[9]" />
                        <text id="_2-10" data-name="2" transform="translate(31.025 59.02)" font-size="44"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[9]">{{ result_district[9] }}</tspan>
                        </text>
                      </g>
                      <g transform="matrix(1, 0, 0, 1, -792.64, -536.31)">
                        <text id="_19" data-name="សែនសុខ" transform="translate(889 813)" fill="#fff" font-size="57"
                          font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">សែនសុខ</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="toul_kork" class="path_zoon" data-name="toul kork" transform="translate(789.413 871.73)"
                      @click.prevent="lans_popup(4)">
                      <path id="Path_15" data-name="Path 15"
                        d="M1114.756,1234.3l-12.953,12.61-14.718-8.054-31.168-3.5-17.157-26.269,7.006-26.612,20.665-14.708-11.562-18.558-12.6-31.874,2.45-78.093,19.263-9.1,10.5-15.06,20.654-12.61,21.713,27.671,38.88,17.157-10.161,16.109-12.611,14.707,4.556,21.714,9.1,18.558,4.375,15.091,1.714,5.9.917,3.175-.4,2.319-.786,4.466-4.415,25.09,11.209,19.606-13.659,11.562-17.157,17.156-13.659,11.552"
                        transform="translate(-873.064 -1002.47)" fill="#08478e" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <g id="number-10" data-name="number" transform="translate(172.184 38.641)">
                        <circle id="Ellipse_1-10" data-name="Ellipse 1" cx="42.5" cy="42.5" r="42.5"
                          transform="translate(0.469 -0.037)" fill="#fff" v-if="result_district[4]" />
                        <text id="_2-11" data-name="2" transform="translate(31.473 57.971)" font-size="44"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[4]">{{ result_district[4] }}</tspan>
                        </text>
                      </g>
                      <g transform="matrix(1, 0, 0, 1, -990.51, -871.52)">
                        <text id="_20" data-name="ទួលគោក" transform="translate(1018.37 1076.94)" fill="#fff"
                          font-size="56" font-family="KhmerOS, Khmer OS" font-weight="700">
                          <tspan x="0" y="0">ទួលគោក</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="boeng_kengkang" class="path_zoon" data-name="boeng kengkang"
                      transform="translate(1017.4 1058.744)" @click.prevent="lans_popup(13)">
                      <path id="Path_3" data-name="Path 3"
                        d="M1343.118,1271.687l-34.324,16.109-15.05,19.606,1.048,17.167,1.4,17.157-28.719,5.948-12.61,13.658-19.606,12.611-19.617-8.054-14.708-10.5-9.455-26.27-14.707-17.157L1165.22,1287.8l8.054-22.761,17.157,3.145,18.215,8.054,31.168-10.151,33.266-4.557,2.45-25.211,2.106-22.066-1.058-17.157,22.762-3.5h24.172l83.7-5.6-42.378,67.589-21.713,16.108"
                        transform="translate(-1100.038 -1187.99)" fill="#3182be" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <text id="_21" data-name="បឹងកេងកង" transform="translate(222.673 631.672)" font-size="56"
                        font-family="KhmerOS, Khmer OS" font-weight="700">
                        <tspan x="0" y="0">បឹងកេងកង</tspan>
                      </text>
                      <line id="Line_4" data-name="Line 4" x2="113" y2="413" transform="translate(144.162 141.104)"
                        fill="none" stroke="#000" stroke-width="4" />
                      <g id="number-11" data-name="number" transform="translate(541.465 571.806)"
                        v-if="result_district[13]">
                        <g id="Ellipse_1-11" data-name="Ellipse 1" transform="translate(0.253 -0.142)" fill="#fff"
                          stroke="#000" stroke-width="1">
                          <ellipse cx="42" cy="43.5" rx="42" ry="43.5" stroke="none" />
                          <ellipse cx="42" cy="43.5" rx="41.5" ry="43" fill="none" />
                        </g>
                        <text id="_2-12" data-name="2" transform="translate(30.257 59.867)" font-size="44"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[13]">{{ result_district[13] }}</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="doun_penh" class="path_zoon" data-name="doun penh" transform="translate(1049.303 751.393)"
                      @click.prevent="lans_popup(3)">
                      <path id="Path_7" data-name="Path 7"
                        d="M1288.405,1101.172l10.151,17.157,13.659,19.616,12.611,12.6,14,10.161,17.167,5.958,19.607-5.958,13.658,15.06,18.216,13.659-83.7,5.6H1299.6l-22.762,3.5-25.211,1.4-27.671,2.1,12.61-18.2-14.707-18.558-3.5-10.332-1.683-4.97-1.824-5.362,5.383-3.992-1.875-4.062-2.46,2.449-.09-.221,4.646-28.5-18.2,4.556-56.39,3.5-9.1-18.558-4.556-21.713,12.611-14.707,10.161-16.108,18.558-8.054,21.713-11.562,13.659-12.6,24.163-5.6,10.5,21.713,16.108,24.162,12.611,22.762,16.109,23.114"
                        transform="translate(-1132.21 -882.066)" fill="#a8cfe5" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <line id="Line_2" data-name="Line 2" y1="114.526" x2="185.263" transform="translate(84.511 61.26)"
                        fill="none" stroke="#000" stroke-width="4" />
                      <g id="number-12" data-name="number" transform="translate(509.425 0)" v-if="result_district[3]">
                        <g id="Ellipse_1-12" data-name="Ellipse 1" transform="translate(0.385 -0.065)" fill="#fff"
                          stroke="#000" stroke-width="1">
                          <circle cx="42.5" cy="42.5" r="42.5" stroke="none" />
                          <circle cx="42.5" cy="42.5" r="42" fill="none" />
                        </g>
                        <text id="_2-13" data-name="2" transform="translate(30.39 57.943)" font-size="44"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[3]">{{ result_district[3] }}</tspan>
                        </text>
                      </g>
                      <text id="_22" data-name="ដូនពេញ" transform="translate(277.384 63.166)" font-size="56"
                        font-family="KhmerOS, Khmer OS" font-weight="700">
                        <tspan x="0" y="0">ដូនពេញ</tspan>
                      </text>
                    </g>
                    <g id="_7makara" data-name="7makara" class="path_zoon" transform="translate(1062.952 866.554)"
                      @click.prevent="lans_popup(5)">
                      <path id="Path_11" data-name="Path 11"
                        d="M1215.708,1137.967l.091.221,2.459-2.449,1.875,4.062-5.383,3.992,1.825,5.363,1.684,4.969,3.5,10.333,14.707,18.558-12.611,18.2-24.163-7-29.767,11.552-11.552-12.611-11.209-19.606,4.415-25.09.787-4.466.4-2.319-.918-3.175-1.714-5.9-4.375-15.09,56.39-3.5,18.205-4.556-4.647,28.5"
                        transform="translate(-1145.76 -996.421)" fill="#4494c7" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <line id="Line_1" data-name="Line 1" y1="94.424" x2="318.556" transform="translate(45.352 43.783)"
                        fill="none" stroke="#000" stroke-width="4" />
                      <g id="number-13" data-name="number" transform="translate(669.791 0)">
                        <g id="Ellipse_1-13" data-name="Ellipse 1" transform="translate(0.393 -0.226)" fill="#fff"
                          stroke="#000" stroke-width="1" v-if="result_district[5]">
                          <ellipse cx="42.5" cy="43.5" rx="42.5" ry="43.5" stroke="none" />
                          <ellipse cx="42.5" cy="43.5" rx="42" ry="43" fill="none" />
                        </g>
                        <text id="_2-14" data-name="2" transform="translate(30.397 59.783)" font-size="44"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[5]">{{ result_district[5] }}</tspan>
                        </text>
                      </g>
                      <text id="_23" data-name="ប្រាំពីរមករា" transform="translate(376.581 60.835)" font-size="56"
                        font-family="KhmerOS, Khmer OS" font-weight="700">
                        <tspan x="0" y="0">ប្រាំពីរមករា</tspan>
                      </text>
                    </g>
                    <g id="chamkar_mon" data-name="chamkar mon" class="path_zoon" transform="translate(1018.15 985.47)"
                      @click.prevent="lans_popup(1)">
                      <path id="Path_2" data-name="Path 2"
                        d="M1158.728,1192.5l11.552,12.61,29.767-11.552,24.162,7,27.671-2.1,25.211-1.4,1.058,17.157-2.107,22.066-2.449,25.211-33.265,4.556L1209.16,1276.2l-18.215-8.054L1173.788,1265l-8.054,22.761-21.713-16.109L1116.35,1265l-15.05-19.617,12.953-12.611,13.659-11.552,17.157-17.157,13.659-11.562"
                        transform="translate(-1101.3 -1114.67)" fill="#8fc2de" stroke="#000" stroke-linecap="square"
                        stroke-linejoin="bevel" stroke-width="3.071" fill-rule="evenodd" />
                      <line id="Line_3" data-name="Line 3" y1="67.387" x2="205.532" transform="translate(142.621 51.158)"
                        fill="none" stroke="#000" stroke-width="4" />
                      <text id="_24" data-name="ចំការមន" transform="translate(368.944 61.867)" font-size="57"
                        font-family="KhmerOS, Khmer OS" font-weight="700">
                        <tspan x="0" y="0">ចំការមន</tspan>
                      </text>
                      <g id="number-14" data-name="number" transform="translate(635.728)" v-if="result_district[1]">
                        <g id="Ellipse_1-14" data-name="Ellipse 1" transform="translate(0.253 -0.142)" fill="#fff"
                          stroke="#000" stroke-width="1">
                          <ellipse cx="42" cy="43.5" rx="42" ry="43.5" stroke="none" />
                          <ellipse cx="42" cy="43.5" rx="41.5" ry="43" fill="none" />
                        </g>
                        <text id="_2-15" data-name="2" transform="translate(30.257 59.867)" font-size="44"
                          font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
                          <tspan x="0" y="0" v-if="result_district[1]">{{ result_district[1] }}</tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
                </svg>
              </template>
              <template v-else>
              <table class="table table-bordered table-hover m-0" v-if="district != ''">
                  <thead>
                    <tr >
                      <th class="font-weight-bold">#</th>
                      <th class="font-weight-bold">ស្រុក/ខណ្ឌ</th>
                      <th class="font-weight-bold">ចំនួន</th>
                      <th class="font-weight-bold">{{ $t('title.action') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(dis, index) in district" :key="index">
                      <td>{{ index+1 }}</td>
                      <td v-if="dis.lands_count">
                        <p class="mb-md-0"> {{ dis[`name_${$i18n.locale}`] }}</p>
                      </td>
                      <td v-if="dis.lands_count">
                        {{ dis.lands_count }}
                      </td>
                      <td v-if="dis.lands_count">
                        <b-button @click="lans_popup(dis.id)" class="btn btn-secondary text-white mr-2 py-1 rounded-pill"
                          v-if="hasPer('detail-land')"
                          >
                          {{ $t("title.detail") }}
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
        </section>
    </div>
</template>
<script>

import BackIcon from "@/components/my/icons/BackIcon"

export default {
    components:{BackIcon},
  data() {
    return {
      district: [],
      oneDistrict: [],
      popupID: '',
      isLoading: false,
      isLoader1: false,
      is_pp: false,
      result_district: {},
      district_loading: false,
      getOwner: localStorage.getItem('owner')
    }
  },

  mounted() {
    
    if ($cookies.get("token")) {
        this.clicked(this.$route.params.id)
    }
  },

  computed: {
    /******
     * permssion data
     */
    permission() {
        return this.$store.state.permission;
    },
    /******
     * land data by district
     */
    associateLand() {
        return this.$store.state.lands.associateLand;
    },
    /******
     * province data
     */
    province() {
        return this.$store.state.province.province;
    },
  },

  methods: {

    hasPer(per) {
      if (this.permission.length) {
        return this.permission.includes(per);
      }
    },

    clicked(id) {
    //   this.$refs['lists_popUp'].show();
      this.getOneProvince(id);
      this.getByProvince(id);

      this.isProvince = true;
      if (id == 9) {
        this.is_pp = true;
      }
      let isProvince = this.hasPer('detail-province');
    },

    getOneProvince(id) {
      this.$store.dispatch("province/getProvince", id);
    },

    getByProvince(id) {
      this.$store.dispatch('loading', true);
      axios.get(`show-district/${id}?owner=${this.getOwner}`).then((res) => {
        this.district = res.data;
        var datas = res.data.map((pro) => ({ [pro.id]: pro.lands_count }));

        let newData = {};
        datas.forEach((item) => {
          let key = Object.keys(item)[0];
          newData[key] = item[key];
        });
        this.result_district = newData;
      })
        .catch((err) => { })
        .finally(() => {
          this.$store.dispatch('loading', false);
        })
    },

    lans_popup(id) {

      let isLand = this.hasPer('detail-land');
      if (isLand) {
        if(this.result_district[id]){
          this.$router.push({name:"DashboardSubDetail",params:{id:id}})
        }else{
          alert("No record.")
        }
      }     
    },

    getAssociate(id) {
      this.$store.dispatch("lands/getAssociateLand", id);
    },

    getOneDistrict(id) {
      this.$store.dispatch('loading', true);
      axios.get(`districts/${id}?owner=${this.getOwner}`).then((res) => {
        this.oneDistrict = res.data;
      })
        .catch((err) => { })
        .finally(() => {
          this.$store.dispatch('loading', false);
        });
    }

  }
}
</script>