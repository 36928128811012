<template>
  <div class="position-relative">
    <div class="content-header">
      <div class="container-xl">
        <div class="row">
          <div class="col-sm-6 d-flex align-items-center">
            <router-link to="/lands"><BackIcon/></router-link>
            <h4 class="m-0 text-dark">{{ $t("title.list_land") }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i> {{ $t('title.home') }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/lands" class="text-dark font-weight-bold">
                  {{ $t('title.list_land') }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t('title.update') }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <section class="content position-relative mt-4">
      <div class="container-xl">
        <div id="tabs" class="border rounded-3">
          <div class="tabs bg-gray-light gap-1 px-2 py-1">
            <a class="rounded-pill" v-on:click="activetab = '1'"
              v-bind:class="[activetab === '1' ? 'active' : '']" @click="active">
              <!-- {{ $t("land-detail.document") }} -->
              ព័ត៌មានដី
            </a>
            <a class="rounded-pill" v-on:click="activetab = '2'"
              v-bind:class="[activetab === '2' ? 'active' : '']" @click="active">
              {{ $t("land-detail.document") }}
            </a>
            <a class="rounded-pill" v-on:click="activetab = '3'"
              v-bind:class="[activetab === '3' ? 'active' : '']" @click="active">
              Slides
            </a>
          </div>
          <div class="content px-2">
            <div v-if="activetab === '1'">
              <form @submit.prevent="onUpdate">
                <div class="card card-dark">
                  <div class="card-header">
                    <h3 class="card-title">
                      {{ land[`land_name_${$i18n.locale}`] }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <input type="hidden" v-model="land.user_role">
                      <div class="col-md-6">
                        <label for="">{{ $t('main_land') }}</label>
                        <select class="custom-select rounded-0" v-model="land.associate" @change="changeMain($event)">
                          <option selected value="main">
                            {{ $t('main_land') }}
                          </option>
                          <option v-for="land in mainlLands" :key="land.id" :value="land.id"
                            :selected="land.id == land.associate">
                            {{ land[`land_name_${$i18n.locale}`] }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-3" v-if="hasPermission('md-owner') && hasPermission('ceo-owner')">
                        <label for="">{{ $t('Land Owner') }}</label>
                        <select class="custom-select rounded-0" v-model="land.user_role">
                          <option value="md" :selected="land.user_role == 'md'">JENNY CHEA</option>
                          <option value="ceo" :selected="land.user_role == 'ceo'">CHEA HUY</option>
                        </select>
                        <span class="text-danger" v-if="errors.user_role">
                          {{ errors.user_role[0] }}
                        </span>
                      </div>
                      <div class="col-md-3">
                        <label for="">លេខប័ណ្ណកាន់កាប់</label>
                        <input type="text" class="form-control" v-model="land.land_title_number">
                        <span class="text-danger" v-if="errors.land_title_number">
                          {{ errors.land_title_number[0] }}
                        </span>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label for="">ប្រភេទប័ណ្ណកាន់កាប់</label>
                        <select class="form-control" v-model="land.type_land_title">
                          <option value="">choose</option>
                          <option value="ប្លង់រឹងQR">ប្លង់រឹងQR</option>
                          <option value="ប្លង់រឹង">ប្លង់រឹង</option>
                          <option value="ប្លង់ទន់">ប្លង់ទន់</option>
                          <option value="ប្លង់ស្ត្រាតា">ប្លង់ស្ត្រាតា</option>
                        </select>
                        <span class="text-danger" v-if="errors.type_land_title">
                          {{ errors.type_land_title[0] }}
                        </span>
                      </div>
                      <div class="col-md-8 mt-3">
                        <label for="">{{ $t("land-detail.land_name") }}</label>
                        <input type="text" class="form-control" v-model="land[`land_name_${$i18n.locale}`]" />
                        <span class="text-danger" v-if="errors.land_name">
                          {{ errors.land_name[0] }}
                        </span>
                      </div>


                      <div class="col-md-4 mt-3">
                        <label for="">{{ $t("land-detail.province") }}</label>
                        <select class="custom-select rounded-0" v-model="land.province_id" v-bind:class="{ isMainLand }"
                          @change="getDisByProvince($event)">
                          <option disabled value="null">{{ $t('title.select_province') }}</option>
                          <option v-for="p in provinces" :key="p.id" :value="p.id">
                            {{ p[`name_${$i18n.locale}`] }}
                          </option>
                        </select>
                        <span class="text-danger" v-if="errors.province_id">
                          {{ errors.province_id[0] }}
                        </span>
                      </div>

                      <div class="col-md-4 mt-3">
                        <label for="">{{ $t('title.districts') }}</label>
                        <select class="custom-select rounded-0" v-bind:class="{ isProvince }" v-model="land.districts_id">
                          <option :value="null" selected v-if="isProvince">{{ $t("title.no_result") }}</option>
                          <option selected disabled :value="null" v-else>{{ $t('title.select_district') }}</option>
                          <option v-for="d in districts" :key="d.id" :value="d.id" :selected="d.id == land.districts_id">
                            {{ d[`name_${$i18n.locale}`] }}
                          </option>
                        </select>
                        <span class="text-danger" v-if="errors.districts_id">
                          {{ errors.districts_id[0] }}
                        </span>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label for="">
                          {{ $t("land-detail.description.size") }} m<sup>2</sup>
                        </label>
                        <input type="number" class="form-control" v-model="land.square_meter" />
                        <span class="text-danger" v-if="errors.square_meter">
                          {{ errors.square_meter[0] }}
                        </span>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="">{{ $t("land-detail.localtion") }}</label>
                        <textarea class="form-control" rows="2" v-model="land[`location_${$i18n.locale}`]"></textarea>
                        <span class="text-danger" v-if="errors.localtion">
                          {{ errors.localtion[0] }}
                        </span>
                      </div>
                      <div class="col-12 mt-3">
                        <label for="">Google Map</label>
                        <input type="text" class="form-control" v-model="land.link_google_map" />
                      </div>
                      <div class="col-12 mt-3">
                        <label for="">{{ $t("title.description") }}</label>
                        <textarea class="form-control" rows="2" v-model="land[`desc_${$i18n.locale}`]"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary mr-2">
                      {{ $t("title.update") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <Document v-if="activetab === '2'" class="tabcontent" v-bind:docTitle="doc_titles" />
            <Slide v-if="activetab === '3'" class="tabcontent" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Slide from "../../components/slide-img.vue";
import Document from "../Lands/Document_land/Create.vue";
import UrlForShow from "../../services/show-url";
import { mapState } from "vuex";
import hasPer from "../../services/permission";
import PrimaryButton from "../../components/my/Buttons/PrimaryButton.vue";
import BackIcon from "../../components/my/icons/BackIcon.vue";


export default {
  components: {
    Slide,
    Document,
    PrimaryButton,
    BackIcon
},

  data() {
    return {
      doc_titles: [],
      path: UrlForShow(),
      activetab: "1",
      isProvince: false,
      isMainLand: false
    };
  },


  mounted() {

    if (this.$route.query.tab) {
      this.activetab = this.$route.query.tab;
    }

    this.getland();
    this.getMainLand();
    this.getProvinces();

    if (this.permission.length) {
      if (!this.hasPermission("update-land")) {
        this.$router.push({ path: "/dashboard" });
      }
    }


    // axios.get(`lands/${this.$route.params.id}`)
    //   .then((res) => {
    //     var province_id = res.data.province_id;
    //     this.$store.dispatch('district/getDisByProvince', province_id);
    //   })
      // this.$store.dispatch('district/getDisByProvince', province_id);
      // console.log(this.land);
  },
  computed: {

    ...mapState({
      errors: state => state.lands.errors,
      permission: state => state.permission,
      land: state => state.lands.land,
      mainlLands: state => state.lands.mainLands,
      provinces: state => state.province.provinces,
      districts: state => state.district.disByProvince,
    }),

  },


  methods: {
    /****
     * method check permission
     */
    hasPermission(per) {
      return hasPer(this.permission, per)
    },

    active() {
      localStorage.setItem("activetab", this.activetab)
      if (this.activetab != this.$route.query.tab) {
        this.activetab;
        this.$router.push(`/land/edit/${this.$route.params.id}?tab=${this.activetab}`)
      }

    },

    /*******
     * get main land
    */
    getMainLand() {
      this.$store.dispatch('lands/getMainLands');
    },

    /*******
     * get land
    */
    getland() {
      this.$store.dispatch('lands/getLand');
    },

    /*******
     * get provinces
    */
    getProvinces() {
      this.$store.dispatch('province/getProvinces');
    },

    /*******
     * get district by change province
    */
    getDisByProvince(event) {
      var id = event.target.value;

      if (id == 'all' || !id) {
        this.isProvince = true;
      } else {
        this.isProvince = false;
      }
      this.land.districts_id = null;
      this.getDistrict(id);

    },

    onUpdate() {
      var land = {
        land_name: this.land[`land_name_${this.$i18n.locale}`],
        location: this.land[`location_${this.$i18n.locale}`],
        desc: this.land[`desc_${this.$i18n.locale}`],
        user_role: this.land.user_role,
        province_id: this.land.province_id,
        districts_id: this.land.districts_id,
        square_meter: this.land.square_meter,
        associate: this.land.associate,
        link_google_map: this.land.link_google_map,
        land_title_number: this.land.land_title_number,
        type_land_title: this.land.type_land_title
      };
      // console.log(land);
      this.$store.dispatch("lands/onUpdate", [land, this.$i18n.locale]);
    },

    /*******
     * on change main land
    */
    changeMain(event) {
      var id = event.target.value;
      axios.get(`/lands/${id}`).then((res) => {
        if (id != 'main') {
          this.isMainLand = true;
          this.isProvince = true;
          this.land.province_id = res.data['province_id'];
          this.land.districts_id = res.data['districts_id'];
          this.getDistrict(this.land.province_id);
        } else {
          this.isProvince = true;
          this.isMainLand = false;
          this.land.province_id = null;
          this.land.districts_id = null;
        }
      })
        .catch((err) => { })
    },

    /*******
     * get district by change main
    */
    getDistrict(id) {
      this.$store.dispatch('district/getDisByProvince', id);
    }
  },
};
</script>

<style lang="scss">
.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  a {
    width: 120px;
    height: 38px;
    color: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #bbc4ce;
    cursor: pointer;

    &:hover {
      border: 1px solid #343a40;
      color: #343a40;
    }

    &.active {
      color: #fff;
      background: #343a40;
    }
  }
}

.preview {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.isProvince,
.isMainLand {
  opacity: 0.6;
  pointer-events: none;
}
</style>