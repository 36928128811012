<template>
  <div class="text-center mt-5" v-if="isLoading">
    <b-spinner label="Spinning"></b-spinner>
  </div>
  <div v-else>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ province[`name_${$i18n.locale}`] }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i> {{ $t('title.home') }}</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/provinces" class="text-dark font-weight-bold">
                  {{ $t('title.province') }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t('title.detail') }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-12 mx-auto mt-5">
            <div id="tabs">
              <div class="tabs">
                <a
                  class="btn btn-sm"
                  v-on:click="activetab = '1'"
                  v-bind:class="[activetab === '1' ? 'active' : '']"
                >
                  {{ $t("title.land_news") }}
                </a>
                <a
                  class="btn btn-sm"
                  v-on:click="activetab = '2'"
                  v-bind:class="[activetab === '2' ? 'active' : '']"
                >
                  {{ $t("title.districts") }}
                </a>
              </div>
            </div>
            <div class="content">
              <landByProvince
                v-if="activetab === '1'"
                class="tabcontent"
              />
              <OneDistrict v-if="activetab === '2'" class="tabcontent" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import landByProvince from '../../components/landBy-province.vue';
import OneDistrict from '../../components/one-district.vue';

export default {
  components: {
    landByProvince,
    OneDistrict,
  },
  data() {
    return {
      isLoading: false,
      activetab: "1"
    }
  },

  computed: {
    /******
     * province data
     */
    province: {
      get() {
        return this.$store.state.province.province;
      },
    },

    /******
     * permssion data
     */
    permission: {
      get() {
        return this.$store.state.permission;
      },
    },
  },

  mounted(){
    this.getProvince();
    if($cookies.get("token")) {
      axios.get("/user").then((res) => {
        var permiss = res.data['roles'][0]['permissions'];

        var permission = permiss.map((per) => ({[per.name]: per.name }));
        let newPer = {};
        permission.forEach((item) => {
          let key = Object.keys(item)[0];
          newPer[key] = item[key];
        });

        var exists = Object.keys(newPer).some((k) => {
          return newPer[k] == "detail-province";
        });

        if(exists == false) {
          this.$router.push({ path: '/dashboard' });
        }
      })
      .catch((err) => { });
    }
  },

  methods: {
    getProvince() {
      this.$store.dispatch("province/getProvince");
    }
  },
 
}
</script>

<style>

</style>