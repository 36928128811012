<template>
  <div class="text-center mt-5" v-if="isLoading">
    <b-spinner label="Spinning"></b-spinner>
  </div>
  <div v-else>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">{{ $t("title.land_type") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">{{
                  $t("title.home")
                }}</router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ $t("title.land_type") }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <section class="content position-relative mt-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-12 mx-auto">
            <p v-if="permission['create-land-type'] == 1">
              <router-link to="/landType/create" class="btn btn-primary rounded-pill">
                <i class="fa fa-plus"></i> {{$t('button.add_new')}}
              </router-link>
            </p>
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th class="w-25">{{ $t("land-detail.no") }}</th>
                  <th class="w-50" v-column-sortable:[`name_${$i18n.locale}`]>
                    {{ $t("title.name") }}
                  </th>
                  <th class="w-25"  v-if="permission['update-land-type'] == 1 || permission['delete-land-type'] == 1">
                     {{ $t("title.action") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-for="(l_type, index) in landType"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ l_type[`name_${$i18n.locale}`] }}</td>
                  <td v-if="permission['update-land-type'] == 1 || permission['delete-land-type'] == 1">
                    <router-link
                      :to="{ name: 'landTypeEdit', params: { id: l_type.id } }"
                      class="btn btn-primary btn-sm"
                      v-if="permission['update-land-type'] == 1"
                    >
                      <i class="fas fa-edit"></i>
                    </router-link>
                    <button
                      class="btn btn-danger btn-sm ml-2 show_confirm"
                      @click="deleteLandType(l_type.id)"
                      :disabled="l_type.doc_titles != 0"
                      v-if="permission['delete-land-type'] == 1"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr v-if="!landType.length" class="text-center">
                  <td colspan="3" v-if="isLoading">{{ $t("title.no_result") }}</td>
                  <td colspan="3" v-else><b-spinner label="Spinning"></b-spinner></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import columnSortable from 'vue-column-sortable';

export default {
  data() {
    return {
      landType: [],
      isLoading:false,
      permission: {}
    };
  },

  mounted() {
    this.getLandTypes();
    if($cookies.get("token")) {
      axios.get("/user").then((res) => {
        let no_role = res.data['roles'].length == 0;
        if(no_role) {
          this.$router.push({ path: '/dashboard' });
        }

        var permiss = res.data['roles'][0]['permissions'];
        var datas = permiss.map((per) => ({[per.name]: 1 }));
        let newData = {};
        datas.forEach((item) => {
          let key = Object.keys(item)[0];
          newData[key] = item[key];
        });
        
        this.permission = newData;

        var permission = permiss.map((per) => ({[per.name]: per.name }));
        let newPer = {};
        permission.forEach((item) => {
          let key = Object.keys(item)[0];
          newPer[key] = item[key];
        });

        var exists = Object.keys(newPer).some((k) => {
          return newPer[k] == "land-type-lists";
        });

        if(exists == false) {
          this.$router.push({ path: '/dashboard' });
        }
      })
      .catch((err) => { });
    }
  },

  methods: {
    getLandTypes() {
      axios.get(`/land-type`)
      .then((res) => {
        if(res.data != 'no-permission') {
          this.landType = res.data;
        }else {
          this.$router.push({ path: '/dashboard' });
        }
      })
      .catch((err) => { })
      .finally(() => {
        this.isLoading = false;
      });
    },

    deleteLandType(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios.delete(`/land-type/${id}`).then((res)=>{
            let i = this.landType.map(data => data.id).indexOf(id);
            this.landType.splice(i, 1);
          });
        }
      });
    },

    orderBy(fn) {
      this.landType.sort(fn);
    },
  },
  
  directives: {
    columnSortable,
  },
};
</script>

<style>
</style>