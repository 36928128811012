<template>
  <div>
    <table class="table table-bordered table-striped">
      <thead>
        <tr class="text-center">
          <th>{{ $t('land-detail.no') }}</th>
          <th v-column-sortable:[`name_${$i18n.locale}`]>
            {{ $t("title.location_land") }}
          </th>
          <th> {{ $t('title.land_count') }}</th>
          <th>{{ $t('title.action') }}</th>
        </tr>
      </thead>
      <tbody class="text-center">
        <tr v-for="(d, index) in districts" :key="d.id">
          <td>{{ index+1 }}</td>
          <td>
            {{ d[`name_${$i18n.locale}`] }}
          </td>
          <td>
            {{ d.lands_count}}
          </td>
          <td>
            <router-link
              :to="{ name: 'districtShow', params: { id: d.id } }"
              class="btn btn-info btn-sm mr-2"
            >
              {{ $t("title.detail") }}
            </router-link>
            <router-link :to="{ name: 'districtEdit', params: {id: d.id} }" class="btn btn-primary btn-sm mr-2">
              <i class="fas fa-edit"></i>
            </router-link>
            <button class="btn btn-sm btn-danger" @click="deleteDis(d.id)" :disabled="d.lands_count != 0">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
        <tr v-if="districts == ''">
          <td colspan="6" class="text-center" v-if="isLoading">
            <b-spinner label="Spinning"></b-spinner>
          </td>
          <td colspan="6" class="text-center" v-else>
            {{ $t('title.no_result') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import columnSortable from 'vue-column-sortable';

export default {
  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    /******
     * districts data
     */
    districts: {
      get() {
        return this.$store.state.province.districts;
      },
    },
  }, 

  mounted() {
    this.getDistrict();
  },

  methods: {
    getDistrict(){
      this.$store.dispatch("province/getDistrictByProID");
    },

    deleteDis(id) {
      this.$store.dispatch("district/onDelete", id);
    },
    
    orderBy(fn) {
      this.districts.sort(fn);
    }
  },
  directives: {
    columnSortable,
  }
}
</script>

<style>

</style>