<template>
  <div class="404-page">
    <div class="container">
      <div class="row">
        <div class="col-12 my-5 text-center">
          <h1>Sorry! Page not found.</h1>
          <p>Unfortunately the page you are looking for has been moved or deleted.</p>
          <div class="my-5">
            <img src="../assets/images/200w.gif" alt="" />
          </div>
          <router-link to="/dashboard" class="btn btn-success"><i class="fas fa-home mr-1"></i>GO TO HOME PAGE</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      currentUrl: "",
    };
  },
  created() {
    this.currentUrl = window.location.href;
  },
};
</script>

<style>
</style>