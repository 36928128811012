import router from '@/router';
const province = {
    namespaced: true,
    state: {
        provinces: [],
        province: [],
        lands: [],
        errors: [],
        districts: [],
        provinces_map: []
    },
    mutations: {
        /**
         * set data to provice
         */
        setProvinces(state, provinces) {
            state.provinces = provinces
        },
        /**
         * set single data to provice
         */
        setProvince(state, province) {
            state.province = province
        },
        /**
         * set all land data by provice
         */
         setLands(state, lands) {
            state.lands = lands
        },
        /**
         * set district data by provice id
         */
         setDistricts(state, districts) {
            state.districts = districts
        },
        /**
         * set all province with map
         */
         setProvinces_map(state, provinces_map) {
            state.provinces_map = provinces_map
        },
        /*****
        * set error 
        */
        setErrors(state, error) {
            state.errors = error;
        }
    },
    getters: {},
    actions: {
        hasPermission(per){
            try {
                 this.permission.contains(per);
             } catch (error) {
               console.log(error);
             }
        },
        /***************
         * get all provices
         */
        getProvinces({ commit }) {
            this.dispatch("loading", true);
            this.dispatch("storage/getOwner");
            var owner = this.state.storage.owner;

            axios
                .get(`/provinces?owner=${owner}`)
                .then((res) => {
                    var datas = res.data.map((pro) => ({ [pro.id]: pro.lands_count }));
                    let newData = {};
                    datas.forEach((item) => {
                     let key = Object.keys(item)[0];
                     newData[key] = item[key];
                    });
                    commit('setProvinces_map', newData);
                    commit('setProvinces', res.data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.dispatch("loading", false);
                });
        },
        /*************
         * Add new record
         */
        onCreate({ commit }, [data, locale]) {
            this.dispatch('loading', true);
            axios.post(`/provinces?lang=${locale}`, data)
                .then((res) => {
                    router.push({ path: "/provinces" })
                    commit('setErrors', []);
                })
                .catch((err) => {
                    commit('setErrors', err.response.data.errors);
                })
                .finally(() => {
                    this.dispatch('loading', false);
                });
        },
        /******
         * show one record
         */
        getProvince({ commit }, id) {
            if(id) {
                var pro_id = id;
            } else {
                var pro_id = router.currentRoute.params.id;
            }
            this.dispatch('loading', true);
            this.dispatch("storage/getOwner");
            var owner = this.state.storage.owner;

            axios.get(`/provinces/${pro_id}?owner=${owner}`)
                .then((res) => {
                    commit('setProvince', res.data);
                    commit('setLands', res.data['list_land']);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.dispatch('loading', false);
                });
        },
        /******
         * show all district by province id
         */
        getDistrictByProID({ commit }) {
            this.dispatch('loading', true);
            this.dispatch("storage/getOwner");
            var owner = this.state.storage.owner;
            axios.get(`/district/${router.currentRoute.params.id}?owner=${owner}`)
            .then((res) => {
                commit('setDistricts', res.data);
            })
            .catch((err) => {

            }).finally(() => {
                this.dispatch('loading', false);
            })
        },
        /*********
         * Change row record
         */
        onUpdate({ commit }, [data, locale]) {
            this.dispatch('loading', true);
            axios.put(`/provinces/${router.currentRoute.params.id}?lang=${locale}`, data)
                .then((res) => {
                    router.push({ path: "/provinces" })
                    this.dispatch('province/getProvinces');
                    commit('setErrors', []);
                })
                .catch((err) => {
                    commit('setErrors', err.response.data.errors);
                })
                .finally(() => {
                    this.dispatch('loading', false);
                });
        },
        /************************
         * distroy provice one record
         */
        onDelete({ commit }, id) {
            this.dispatch("loading", true);
            axios.delete(`/provinces/${id}`).then((res) => {
                this.dispatch('province/getProvinces');
            }).finally(() => {
                this.dispatch("loading", false);
            });

        }
    },
};
export default province;