<template>
  <div>
    <div class="content-header">
      <div class="container-xxl">
        <div class="row">
          <div class="col-sm-6 d-flex align-items-center">
            <router-link :to="{name:'DashboardSubDetail',params:{id:land['districts_id']}}"><BackIcon/></router-link>
            <h4 class="m-0 text-dark">{{ land[`land_name_${$i18n.locale}`] }}</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="loader" v-if="isLoading"></div> -->
    <loader v-if="isLoading"></loader>

     <!-- Main content -->
    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row no-gutters">
          <div class="col-12" v-if="land.link_google_map">
            <iframe id="map" :src="`https://www.google.com/maps/d/embed?mid=${land.url_map}&hl=${$i18n.locale}&ehbc=${land.ehbc}&ll=${land.ll}&z=19`" ></iframe>
          </div>
          <div class="col-12 " v-else>
            {{ $t('title.no_map') }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import loader from '../components/loader.vue';
import BackIcon from "@/components/my/icons/BackIcon"
export default {
  components: { loader,BackIcon }, 
  data() {
    return {
      land: {
        url_map: '',
        ll:'',
        ehbc:'',
      },
      isLoading: false
    }
  },

  async mounted() {
    this.getLand();
    var iframe = document.getElementById('map');
    console.log(this.land);
  },

  methods: {
    async getLand() {
      this.$store.dispatch('loading', true);
      await axios.get(`/lands/${this.$route.params.id}`).then((res)=>{
        this.land = res.data;
        var map_url = res.data['link_google_map'];
        let params = new URL(map_url).searchParams;
        this.land.ll = params.get('ll');
        this.land.ehbc = params.get('ehbc');
        var map_parameter = map_url.split("mid=")[1].split('&');
        this.land.url_map = map_parameter[0];
      })
      .catch((err) => {})
      .finally(() => {
        this.$store.dispatch('loading', false);
      });
    }
  }
};
</script>

<style land="scss">

  #map {
    width: 100%;
     height: 82vh;
  }
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: #fff !important;
  }
</style>