import UrlForShow from '../../services/show-url'
import router from '@/router'
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import hasPer from '../../services/permission';

var incrype = Vue.use(VueCryptojs).CryptoJS;

const auth = {
    namespaced: true,
    state: {
        errors: [],
        status:"",
    },
    mutations: {
        /******
         * set errors
         */
        setErrors(state, errors) {
            state.errors = errors;
        },
        /*******
         * set status
         */
        setStatus(state,status){
            state.status=status
        }
    },
    getters: {
    },
    actions: {
        /***
         * login page
         */
        onLogin({ commit }, formData) {
            this.dispatch('loading', true);
            axios.post("login", formData).then((res) => {
                const encryptedText = incrype.AES.encrypt(
                    res.data,
                    "@@cmed//99"
                ).toString();
                $cookies.set("token", encryptedText, "1day");
                if (res.status == 200) {
                    if (router.currentRoute.query.redirect) {
                        router.push(router.currentRoute.query.redirect);
                    } else {
                        router.push({ path: "/dashboard" });
                    }
                    this.dispatch('getUser');
                }
                commit('setErrors', [])
            }).catch((err) => {
                commit('setErrors', err.response.data.errors)
            }).finally(() => { 
                this.dispatch('loading', false);
            });
                
        },
        /****
         * forget password form
         */
        onForgetPassword({ commit }, data) {
            this.dispatch('loading', true);
            axios.post('/forgot-password', data).then((res) => {
                if (res.status === 200) {
                    commit('setStatus',res.data.status);
                    commit('setErrors', [])
                }
            }).catch((err) => {
                commit('setErrors', err.response.data.errors)
            }).finally((ready) => {
                this.dispatch('loading', false);
            })
        },
        /*****
         * reset password send link with roken
         */
        onResetPassword({commit},data){
            this.dispatch('loading', true);
            axios.post("/reset-password", data).then((res) => {
              if(res.status==200){
                $cookies.set("token", res.data, "1d");
                this.formReset="";
                commit('setErrors', [])
                router.push({name:"Dashboard"});
              }
            })
            .catch((err) => {
                commit('setErrors', err.response.data.errors)
            }).finally(()=>{
                this.dispatch('loading', false);
            });
        },
    }
}
export default auth;