<template>

  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t("title.districts") }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i> {{ $t('title.home') }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/districts" class="text-dark font-weight-bold">
                  {{ $t('title.districts') }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t('title.update') }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <section class="content position-relative mt-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">{{ $t('district_name') }}</h3>
              </div>
              <form @submit.prevent="onUpdate">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label for="">{{ $t('district_name') }}</label>
                      <input type="text" class="form-control" v-model="district[`name_${$i18n.locale}`]">
                      <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                    </div>
                    <div class="col-md-6">
                      <label for="">{{ $t('land-detail.province') }}</label>
                      <select name="" class="custom-select rounded-0" v-model="district.province_id">
                        <option selected disabled :value="null">{{ $t('title.select_province') }}</option>
                        <option v-for="(p, index) in provinces" :key="index" :value="p.id"
                          :selected="p.id == district.province_id">{{ p[`name_${$i18n.locale}`] }}</option>
                      </select>
                      <span class="text-danger" v-if="errors.province_id">{{ errors.province_id[0] }}</span>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary">{{ $t('title.update') }}</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import hasPer from '../../services/permission';

export default {
  data() {
    return {
    }
  },
  computed: {
    permission: {
      get() {
        return this.$store.state.permission;
      }
    },
    provinces: {
      get() {
        return this.$store.state.province.provinces;
      }
    },
    district: {
      get() {
        return this.$store.state.district.district;
      }
    },
    errors: {
      get() {
        return this.$store.state.district.errors;
      }
    }

  },

  mounted() {
    this.getProvinces();
    this.getDistrict();
    /*******
  * check permission
  */
    if (this.permission.length) {
      if (!this.hasPermission('create-district')) {
        this.$router.push({ path: "/dashboard" });
      }
    }
  },

  methods: {
    /*******
    * get a permission for check
    */
    hasPermission(per) {
      return hasPer(this.permission, per);
    },
    /******
     * get all data province
     */
    getProvinces() {
      this.$store.dispatch('province/getProvinces');
    },
    /****
     * get a record district
     */
    getDistrict() {
      this.$store.dispatch('district/getDistrict');
    },
    /****
     * change one record
     */
    onUpdate() {
      var district={
        name:this.district[`name_${this.$i18n.locale}`],
        province_id:this.district.province_id
      }
      this.$store.dispatch('district/onUpdate', [this.$i18n.locale, district]);
    },
  }

}
</script>

<style>
</style>