<template>

  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ $t('title.user') }}</h4>
            <div class="d-block">
              <router-link to="/roles" class="btn btn-sm btn-primary mt-2 mr-2" v-if="hasPermission('role-lists')">
                <i class="nav-icon fa fa-user mr-2"></i> User Role
              </router-link>
              <router-link to="/user/create" class="btn btn-sm btn-primary mt-2" v-if="hasPermission('create-user')">
                <i class="nav-icon fa fa-plus-square mr-2"></i> {{ $t('button.add_new') }}
              </router-link>
            </div>
          </div>
          <div class="col-sm-6 text-right">
            <ol class="breadcrumb justify-content-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>{{ $t('title.home') }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t('title.user') }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-12  mt-5 mx-auto">
            <table id="user_list" class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th>{{ $t('land-detail.no') }}</th>
                  <th v-column-sortable:name>{{ $t('title.name') }}</th>
                  <th v-column-sortable:email>{{ $t('title.email') }}</th>
                  <th>Role</th>
                  <th>Active</th>
                  <th>Amount Login</th>
                  <th>Last Login</th>
                  <th v-if="hasPermission('edit-user') || hasPermission('delete-user')">
                    {{ $t('title.action') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="(u, index) in users" :key="u.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ u.name }}</td>
                  <td>{{ u.email }}</td>
                  <td>
                    <span v-if="u.roles == ''">Guest</span>
                    <span v-else v-for="r in u.roles" :key="r.id">
                      {{ r.name }}
                    </span>
                  </td>
                  <td>
                    <span class="text-success" v-if="u.isOnline">Online</span>
                    <span class="text-secondary" v-else>Offline</span>
                  </td>
                  <td> {{ u.user_login_count }}</td>
                  <td> {{ u.last_active }}</td>
                  <td v-if="hasPermission('edit-user') || hasPermission('delete-user')">
                    <router-link :to="{ name: 'userEdit', params: { id: u.id } }" class="btn btn-primary btn-sm"
                      v-if="hasPermission('edit-user')">
                      <i class="fas fa-edit"></i>
                    </router-link>
                    <button class="btn btn-danger btn-sm ml-2 show_confirm" @click="onDelete(u.id)"
                      v-if="hasPermission('delete-user')">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import columnSortable from 'vue-column-sortable';
import hasPer from '../../services/permission';

export default {
  data() {
    return {

    }
  },

  computed: {
    permission: {
      get() {
        return this.$store.state.permission;
      }
    },
    users: {
      get() {
        return this.$store.state.users.users;
      }
    },
   
  },

  mounted() {
    this.getUsers();
    /***
     * check permission redirect
     */
    if (this.permission.length) {
      if (!this.hasPermission('user-lists')) {
        this.$router.push({ name: "Dashboard" });
      }
    }
  },

  methods: {
    /***
     * Check permission
     */
    hasPermission(per) {
      return hasPer(this.permission, per);
    },
    /****
     * get all users from state 
     */
    getUsers() {
      this.$store.dispatch('users/getUsers');
    },
    /****
     * remove a record
     */
    onDelete(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            this.$store.dispatch('users/onDelete', id);
          }
        });
    },
    /******
     * short record
     */
    orderBy(fn) {
      this.users.sort(fn);
    }
  },

  directives: {
    columnSortable,
  }
};
</script>

<style>
</style>