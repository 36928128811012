<template>
  <div>
    <div class="card card-gray-dark">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title align-items">Slides {{sortable ?"sort position":""}}</h3>
        <div class="ml-auto d-flex gap-2">
          <template v-if="sortable">
            <b-button pill variant="outline-light" size="sm" @click="onCancelSort"><i class="fa fa-times"></i> Cancel</b-button>
            <b-button pill variant="primary" size="sm" @click="onSaveSort"><i class="fa fa-save"></i>  Save</b-button>
          </template>
          <template v-else>
            <b-button pill variant="outline-light" size="sm" @click="onSort"><i class="fa fa-sort"></i> Sort Position</b-button>
            <b-button pill @click="show" size="sm" data-backdrop="static" data-keyboard="false">
              <i class="fa fa-plus-square mr-2"></i>{{ $t('add_slide') }}
            </b-button>
          </template>
         
        </div>
      </div>
      <div class="card-body overflow-auto">
        <div class="text-center" v-if="isLoading">
          <b-spinner label="Text Centered"></b-spinner>
        </div>
        <template v-else>
        <table class="table table-bordered table-striped " 
        v-if="!sortable"
        >
          <thead>
            <tr class="text-center">
              <th>{{ $t('land-detail.no') }}</th>
              <th>{{ $t('title.name') }}</th>
              <th>{{ $t('title.image') }}</th>
              <th>{{ $t('title.path_image') }}</th>
              <th>{{ $t('title.action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, index) in slides" :key="index" class="text-center align-items-center">
              <td class="align-middle">{{ index + 1 }}</td>
              <td class="align-middle">{{ s.name }}</td>
              <td class="align-middle">
                <a class="elem" :href="path + s.file_url" :data-lcl-thumb="path + s.file_url">
                  <ImgCrop :src="path + s.file_url" />
                </a>

              </td>
              <td class="align-middle">
                <a class="elem1" :href="path + s.file_url" :data-lcl-thumb="path + s.file_url">
                  {{ s.file_url }}
                </a>
              </td>
              <td class="align-middle">
                <button class="btn btn-outline-danger btn-sm show_confirm" @click="deleteSlide(s.id)">
                  <i class="fa fa-times-circle"></i> Remove File
                </button>
              </td>
            </tr>
            <tr v-if="slides == 0">
              <td colspan="5" class="text-center">{{ $t('title.no_result') }}</td>
            </tr>
          </tbody>
        </table>
        <draggable v-else v-model="slides">
            <div class="row gap-2 align-items-center ring-1 ring-gray-300 cursor-move border my-1 shadow-sm" style="cursor: move; " v-for="(s, index) in slides" :key="index">
              <div class="col-1 py-1">
                <svg   viewBox="0 0 512 512" class="w-4 h-4" style="width: 1em;height: 1em;">
                <path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8h32v96H128V192c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V288h96v96H192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H288V288h96v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6v32H288V128h32c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-64-64z"/>
              </svg>
              </div>
              <div class="col-1 py-1"><ImgCrop :src="path + s.file_url" /></div>
              <div class="col py-1">{{ s.name }}</div>
            </div>
        </draggable>
      </template>
      </div>

      <b-modal ref="slideShow" id="modal-slide" title="Add Slide" hide-footer centered size="lg"
        :no-close-on-backdrop="true">
        <div class="text-center" v-if="isLoading">
          <b-spinner label="Text Centered"></b-spinner>
        </div>
        <div class="card card-primary" v-else>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="custom-file mb-4">
                  <input type="hidden" v-model="slide.base64image">
                  <b-form-file v-model="slide.base64image" multiple ref="file" accept="image/*" v-on:change="uploadImage"
                    placeholder="Choose a file" drop-placeholder="Drop file here..."></b-form-file>
                  <span class="text-danger">{{ err_img }}</span>
                </div>
                <div class="d-flex flex-column gap-1" >
                  <div class="w-100 bg-light" v-if="img_src" v-for="(item, index) in img_src" :key="index">
                    <div class="d-flex px-2">
                      <ImgCrop :src="item"/>
                      <b-button variant="light" class="ml-auto"
                      @click="remove_img(index)"
                      >
                        <svg style="width:20px;height:20px" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#C34A2C">
                          <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg></b-button>
                    </div>
                      
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" @click="saveImg" :disabled="!img_src">
              {{ $t("title.save") }}
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import UrlForShow from '../services/show-url';
import ImgCrop from './my/ImgCrop.vue';

import draggable from 'vuedraggable'

export default {
  components: {
    Cropper,
    ImgCrop,
    draggable
  },

  data() {
    return {
      land: [],
      slides: [],
      dataSlides:[],
      path: UrlForShow(),
      slide: {
        base64image: []
      },
      img_src: [],
      err_img: '',
      img_upload: false,
      isLoading: false,
      sortable: false,
    }
  },

  computed:{
    sortSlides(){
      return this.slides;
    }
  },

  mounted() {
    this.getland();
    this.getSlides();

    lc_lightbox('.elem', {
      skin: 'minimal',
      radius: 0,
      padding: 0,
      border_w: 0,
    });
    lc_lightbox('.elem1', {
      skin: 'minimal',
      radius: 0,
      padding: 0,
      border_w: 0,
    });

  },

  methods: {
    remove_img(i){
      this.img_src.splice(i,1);
    },
    onSort(){
      this.sortable=true;
    },
    onCancelSort(){
      this.slides=this.dataSlides;
      this.sortable=false;
    },
    onSaveSort(){
      this.isLoading = true;
      const dataSort= this.slides.map((item)=>{
        return item.id;
      })
      axios.post('/image-slide/sort',{"data":dataSort}).then((res)=>{
        this.sortable=false;
      }).catch((err)=>{
        alert(err)
      }).finally(()=>{
        this.isLoading = false;
      })
      
    },
    show() {
      this.$refs['slideShow'].show();
      this.img_src = [];
    },

    getland() {
      this.$store.dispatch('loading', true);
      axios.get(`/lands/${this.$route.params.id}`,)
        .then((res) => {
          this.land = res.data;
        })
        .catch((err) => { })
        .finally(()=>{
          this.$store.dispatch('loading', false);
        });
    },

    getSlides() {
      this.$store.dispatch('loading', true);
      axios.get(`/image-slide/${this.$route.params.id}/slide`)
        .then((res) => {
          this.slides = res.data['slides'];
          this.dataSlides = res.data['slides'];
        })
        .catch((err) => { })
        .finally(() => {
          this.$store.dispatch('loading', false);
        })
    },

    uploadImage(e) {

      this.img_upload = true;
      const image = e.target.files;
      for (let index = 0; index < image.length; index++) {
        const element = image[index];
        var reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onload = (i)=> {
            this.img_src.push(i.target.result);
            
          };
      }
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        return reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },

    saveImg() {
      // let formData = new FormData();
      // formData.append("base64image", this.img_src);
      this.$store.dispatch('loading', true);
      axios.post(`/image-slide/${this.$route.params.id}/slide`, {base64image:this.img_src})
        .then((res) => {
          this.$refs["slideShow"].toggle();
          this.getSlides();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch('loading', false);
        });
    },

    deleteSlide(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.dispatch('loading', true);
          axios.delete(`/image-slide/${this.$route.params.id}/slide/${id}`)
            .then((res) => {
              let i = this.slides.map((data) => data.id).indexOf(id);
              this.slides.splice(i, 1);
            }).finally(()=>{
              this.$store.dispatch('loading', false);
            });
        }
      });
    }
  }
}
</script>

<style land="scss">
.lb-outerContainer img {
  width: 850px !important;
  height: 800px !important;
  overflow: hidden;
  object-fit: contain;
}

.table td,
.table th {
  padding: 5px !important;
}

.image-preview {
  width: 100px;
  height: 100px;
}
</style>