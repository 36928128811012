import Vue from "vue";
import VueRouter from "vue-router";

import VueCommaFilter from "vue-comma-filter";

// login page
import Login from "../views/Atuh/Login.vue";
import ForgetPassword from "../views/Atuh/ForgetPassword.vue";
import ResetPassword from "../views/Atuh/ResetPassword.vue";

// lands page
import IndexApp from "../views/IndexApp.vue";
import Dashboard from "../views/Dashboard.vue";
import IndexLand from "../views/Lands/Index.vue";
import LandShow from "../views/Lands/Show.vue";
import CreateLand from "../views/Lands/Create.vue";
import EditLand from "../views/Lands/Edit.vue";
import LandTypeIndex from "../views/Lands/Land_type/Index.vue";
import LandTypeCreate from "../views/Lands/Land_type/Create.vue";
import LandTypeEdit from "../views/Lands/Land_type/Edit.vue";
import DocTitleCreate from "../views/Lands/Document_title/Create.vue";
import DocTitleEdit from "../views/Lands/Document_title/Edit.vue";
import Mapview from '../views/map-view.vue';
import Landview from '../views/Land_guest_view.vue';
import ViewDocument from '../views/Lands/view-document.vue';

// users page 
import UserIndex from "../views/Users/Index.vue";
import UserCreate from "../views/Users/Create.vue";
import UserEdit from "../views/Users/Edit.vue";
import ProfileEdit from "../views/Users/Profile-edit.vue";

import RoleIndex from "../views/Users/Roles/Index.vue";
import RoleCreate from "../views/Users/Roles/Create.vue";
import RoleEdit from "../views/Users/Roles/Edit.vue";


// countries page 
import CountryIndex from "../views/Countries/Index.vue";
import CountryCreate from "../views/Countries/Create.vue";
import CountryEdit from "../views/Countries/Edit.vue";
import CountryShow from "../views/Countries/Show.vue";

// provinces page 
import ProvincesIndex from "../views/Provinces/Index.vue";
import ProvinceCreate from "../views/Provinces/Create.vue";
import ProvinceEdit from "../views/Provinces/Edit.vue";
import ProvinceShow from "../views/Provinces/Show.vue";

// district page 
import DistrictsIndex from "../views/Districts/Index.vue";
import DistrictsCreate from "../views/Districts/Create.vue";
import DistrictsEdit from "../views/Districts/Edit.vue";
import DistrictsShow from "../views/Districts/Show.vue";

// other page
import Notfound from "../views/404.vue";
import SearchData from "../views/SearchData.vue";
import Setting from "../views/setting.vue";

import DashboardDetail from "../views/DashboardDetail.vue"
import DashboardSubDetail from "../views/DashboardSubDetail.vue"

Vue.use(VueRouter);

Vue.use(VueCommaFilter);

// import VueCryptojs from "vue-cryptojs";
// var crypeJs= Vue.use(VueCryptojs);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { guest: true },
    component: Login,
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    meta: { guest: true },
    component: ForgetPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    meta: { guest: true },
    component: ResetPassword,
  },
  {
    path: "/show/:token/:id",
    name: "ShowLand",
    meta: { guest: true },
    component: Landview,
  },
  {
    path: "/",
    component: IndexApp,
    meta: { requiresAuth: true },
    children: [
      {
        path:"/",
        redirect:'/dashboard'
      },

      {
        path: "/setting",
        name: "Setting",
        components: {
          default: IndexApp,
          MainView: Setting
        },
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        components: {
          default: IndexApp,
          MainView: Dashboard
        },
      },
      {
        path:'/dashboard-detail/:id',
        name: "DashboardDetail",
        components: {
          default: IndexApp,
          MainView: DashboardDetail
        },
      },
      {
        path:'/dashboard-sub-detail/:id',
        name: "DashboardSubDetail",
        components: {
          default: IndexApp,
          MainView: DashboardSubDetail
        },
      },
      {
        path: "/lands",
        name: IndexLand,
        components: {
          default: IndexApp,
          MainView: IndexLand,

        },
        meta:{type:"lands"}
      },
      {
        path: "/land/create",
        name: 'land',
        components: {
          default: IndexApp,
          MainView: CreateLand,
        },
        meta:{type:"lands"}
      },
      {
        path: '/land/detail/:id',
        name: 'detailLand',
        components: {
          default: IndexApp,
          MainView: LandShow,
        },
        meta:{type:"lands"}
      },
      {
        path: '/land/edit/:id',
        name: 'landEdit',
        components: {
          default: IndexApp,
          MainView: EditLand,
        },
        meta:{type:"lands"}
      },
      {
        path: "/land/:landID/doc/:id",
        name: "ViewDoc",
        components: {
          default: IndexApp,
          MainView: ViewDocument,
        },
        meta:{type:"lands"}
      },
      {
        path: "/landTypes",
        name: 'landTypes',
        components: {
          default: IndexApp,
          MainView: LandTypeIndex,
        },
        meta:{type:"landTypes"}
      },
      {
        path: "/landType/create",
        name: 'landType',
        components: {
          default: IndexApp,
          MainView: LandTypeCreate,
        },
        meta:{type:"landTypes"}
      },
      {
        path: '/landType/edit/:id',
        name: 'landTypeEdit',
        components: {
          default: IndexApp,
          MainView: LandTypeEdit,
        },
        meta:{type:"landTypes"}
      },
      {
        path: "/doc_title/:id/create",
        name: 'DocTitleCreate',
        components: {
          default: IndexApp,
          MainView: DocTitleCreate,
        }
      },
      {
        path: '/doc_title/:id/edit/:Did',
        name: 'DocTitleEdit',
        components: {
          default: IndexApp,
          MainView: DocTitleEdit,
        },
      },
      {
        path: "/users",
        name: 'Users',
        components: {
          default: IndexApp,
          MainView: UserIndex,
        },
        meta: { 
          type:"users"
        }
      },
      {
        path: "/user/create",
        name: 'User',
        components: {
          default: IndexApp,
          MainView: UserCreate,
        },
         meta:{
          type:"users"
        }
      },
      {
        path: "/user/edit/:id",
        name: 'userEdit',
        components: {
          default: IndexApp,
          MainView: UserEdit,
        },
        meta:{
          type:"users"
        }
      },
      {
        path: "/profile",
        name: 'profileEdit',
        components: {
          default: IndexApp,
          MainView: ProfileEdit,
        },
        meta:{
          type:"users"
        }
      },
      {
        path: "/roles",
        name: 'Roles',
        components: {
          default: IndexApp,
          MainView: RoleIndex,
        },
        meta: { 
          type:"roles"
        }
      },
      {
        path: "/role/create",
        name: 'Role',
        components: {
          default: IndexApp,
          MainView: RoleCreate,
        },
         meta:{
          type:"roles"
        }
      },
      {
        path: "/role/edit/:id",
        name: 'roleEdit',
        components: {
          default: IndexApp,
          MainView: RoleEdit,
        },
        meta:{
          type:"roles"
        }
      },
      {
        path: "/countries",
        name: 'countries',
        components: {
          default: IndexApp,
          MainView: CountryIndex,
        },
        meta:{
          type:"countries",
        }
      },
      {
        path: "/country/create",
        name: 'Country',
        components: {
          default: IndexApp,
          MainView: CountryCreate,
        },
        meta:{
          type:"countries",
          requiresAdmin: false
        }
      },
      {
        path: "/country/edit/:id",
        name: 'country',
        components: {
          default: IndexApp,
          MainView: CountryEdit,
        },
        meta:{
          type:"countries",
          requiresAdmin: false
        }
      },
      {
        path: "/country/detail/:id",
        name: 'countryDetail',
        components: {
          default: IndexApp,
          MainView: CountryShow,
        },
        meta:{
          type:"countries",
          requiresAdmin: false
        }
      },
      {
        path: "/provinces",
        name: 'provinces',
        components: {
          default: IndexApp,
          MainView: ProvincesIndex,
        },
        meta:{ type:"provinces" }
      },
      {
        path: "/province/create",
        name: 'Province',
        components: {
          default: IndexApp,
          MainView: ProvinceCreate,
        },
        meta:{ type:"provinces" }
      },
      {
        path: "/province/edit/:id",
        name: 'province',
        components: {
          default: IndexApp,
          MainView: ProvinceEdit,
        },
        meta:{ type:"provinces" }
      },
      {
        path: "/province/detail/:id",
        name: 'provinceDetail',
        components: {
          default: IndexApp,
          MainView: ProvinceShow,
        },
        meta:{ type:"provinces" }
      },
      {
        path: "/districts",
        name: 'districts',
        components: {
          default: IndexApp,
          MainView: DistrictsIndex,
        },
        meta:{
          type:"districts",
          requiresAdmin: false
        }
      },
      {
        path: "/district/create",
        name: 'districtCreate',
        components: {
          default: IndexApp,
          MainView: DistrictsCreate,
        },
        meta:{
          type:"districts",
          requiresAdmin: false
        }
      },
      {
        path: "/district/edit/:id",
        name: 'districtEdit',
        components: {
          default: IndexApp,
          MainView: DistrictsEdit,
        },
        meta:{
          type:"districts",
          requiresAdmin: false
        }
      },
      {
        path: "/district/detail/:id",
        name: 'districtShow',
        components: {
          default: IndexApp,
          MainView: DistrictsShow,
        },
        meta:{
          type:"districts",
          requiresAdmin: false
        }
      },
      {
        path: "/search/keywords=:title",
        name: 'search',
        components: {
          default: IndexApp,
          MainView: SearchData,
        }
      },
      {
        path: "/map-view/:id",
        name: 'map-view',
        components: {
          default: IndexApp,
          MainView: Mapview,
        }
      },
      {
        path: "*",
        name: 'notfound',
        components: {
          default: IndexApp,
          MainView: Notfound,
        }
      }
    ],
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  linkExactActiveClass: "menu-open",
  routes,
});
function isLogged(){
  if($cookies.isKey("token") ==true){
    return true;
  }else{
    return false
  }
  
}

router.beforeEach((to, from, next) => {
  const isLogin = $cookies.isKey("token");
  const isAuth = to.matched.some(record => record.meta.requiresAuth);
  const isGuest = to.matched.some(record => record.meta.guest);
  const isUserAdmin = to.matched.some(record => record.meta.requiresAdmin);

  if(isAuth && !isLogged()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  else if (isGuest && isLogged()) {
    next({
      path: '/dashboard',
      query: { redirect: to.fullPath }
    })
  } 
  else {
    next()
  }
})


export default router;
