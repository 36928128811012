<template>
  <div class="col-lg-8 col-12" v-if="slides.length">
     <div class="landSlide">
      <SlickCarousel v-bind="landSlide">
        <div class="landSlide_item" v-for="s in slides" :key="s.id">
          <a :href="path+s.file_url" data-lightbox="landImg">
            <img :src="path+s.file_url" alt="" class="wm-100">
          </a>
        </div>
      </SlickCarousel>
    </div>
  </div>
</template>

<script>
import UrlForShow from '../services/show-url';
import SlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import lightbox from '../assets/js/lightbox.min.js';

export default {
  components: { 
    SlickCarousel
  },

  data() {
    return {
      landSlide: {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        arrow: true,
        dots: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
      slides: [],
      path: UrlForShow()
    }
  },

  created() {
    this.getSlides();
  },

  mounted() {
    lightbox.option({
      'resizeDuration': 200,
      'wrapAround': true,
      disableScrolling:true,
      positionFromTop:	50
    })
  },

  methods: {
    getSlides() {
      axios.get(`/image-slide/${this.$route.params.id}/slide`)
      .then((res)=>{
        this.slides = res.data['slides'];
      })
      .catch((err) => { });
    },
  }
}
</script>

<style>

</style>