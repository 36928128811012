
import router from "@/router"
const district = {
    namespaced: true,
    state: {
        districts: [],
        district: [],
        disByProvince:[],
        errors: [],
    },
    mutations: {
        /****
         * set all data in districts
         */
        setDistricts(state, districts) {
            state.districts = districts;
        },
        /****
         * set all data in districts
         */
        setDistrict(state, district) {
            state.district = district;
        },
        /****
         * set all districts by province
         */
         setDisByProvince(state, disByProvince) {
            state.disByProvince = disByProvince;
        },
        /*****
       * set error 
       */
        setErrors(state, errors) {
            state.errors = errors;
        }
    },
    getters: {
    },
    actions: {
        /*******
         * get all data districts
         */
        getDistricts({ commit }) {
            this.dispatch("loading", true);
            this.dispatch("storage/getOwner");
            var owner = this.state.storage.owner;
            axios.get(`/districts?owner=${owner}`)
                .then((res) => {
                    commit('setDistricts', res.data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.dispatch("loading", false);
                });
        },
        /*******
         * add new record
         */
        onCreate({ commit }, data) {
            this.dispatch("loading", true);
            axios.post(`/districts`, data)
                .then((res) => {
                    commit('setErrors', []);
                    router.push({ path: "/districts" });
                }).catch((err) => {
                    commit('setErrors', err.response.data.errors);
                })
                .finally(() => {
                    this.dispatch("loading", false);
                });
        },
        /*****
         * get a distric
         */
        getDistrict({ commit }) {
            this.dispatch("loading", true);
            this.dispatch("storage/getOwner");
            var owner = this.state.storage.owner;
            axios.get(`/districts/${router.currentRoute.params.id}?owner=${owner}`)
                .then((res) => {
                    commit('setDistrict', res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(()=>{
                    this.dispatch("loading", false);
                });
        },
        /*****
         * get a distric by province id
         */
         getDisByProvince({ commit }, id) {
            this.dispatch("loading", true);
            this.dispatch("storage/getOwner");
            var owner = this.state.storage.owner;

            axios.get(`/district/${id}?owner=${owner}`)
                .then((res) => {
                    commit('setDisByProvince', res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(()=>{
                    this.dispatch("loading", false);
                });
        },
        /******
         * Change record
         */
        onUpdate({ commit }, [local,data]) {
            this.dispatch("loading", true);
            axios.put(`/districts/${router.currentRoute.params.id}?lang=${local}`, data)
                .then((res) => {
                    commit('setErrors', []);
                    router.push({ path: "/districts" });
                }).catch((err) => {
                    commit('setErrors', err.response.data.errors);
                })
                .finally(() => {
                    this.dispatch("loading", false);
                });
        },
        /***
         * Delete one record
         */
        onDelete({ commit }, id) {
            this.dispatch("loading", true);
            swal({
                title: "Are you sure?",
                text: "If you delete this, it will be gone forever.",
                icon: "warning",
                buttons: true,
                dangerMode: true
              })
              .then((willDelete) => {
                if (willDelete) {
                    axios.delete(`/districts/${id}`).then((res) => {
                        this.dispatch('district/getDistricts');
                    }).catch(err => {
                        console.log(err);
                    }).finally(() => {
                        this.dispatch("loading", false);
                    });
                }
              });
        }
    }
}

export default district;