"use strict";

import Vue from "vue";
import axios from "axios";
import VueCryptojs from "vue-cryptojs";
var incrype=Vue.use(VueCryptojs).CryptoJS;
import router from "../router";
let config = {
  baseURL:process.env.VUE_APP_API_URL,
  
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  async function (config) {
    
    var decryptedText;
    if ($cookies.get("token")) {
      decryptedText = incrype.AES.decrypt($cookies.get("token"), "@@cmed//99")
        .toString(incrype.enc.Utf8);
    }
    // Do something before request is sent
    config.headers.common["Authorization"] = `Bearer +${decryptedText}`;
    config.headers.common["X-Requested-With"] = "XMLHttpRequest";
    // alert('test');
    return await config;
  },
  async function (error) {
    return await Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  async function (response) {
    // Do something with response data
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      $cookies.remove('token');
      router.push('login');
    }
    return await Promise.reject(error);
   
  }
);

Plugin.install = async function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  await Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);
export default Plugin;
