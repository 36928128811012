<template>

  <div>
    <div class="content-header">
      <div class="container-xl">
        <div class="row">
          <div class="col-sm-8 col-12">
            <h4 class="m-0 text-dark font-weight-bold">{{ $t("title.home") }}</h4>
          </div>
        </div>
      </div>
    </div>
    <section class="content position-relative mt-4" >
     <div class="container-xl">
      <ProvincesSVG />
    </div>
    </section>
    
  </div>
</template>

<script>

import ProvincesSVG from '../components/provinces-svg.vue';

export default {
  components: { 
    ProvincesSVG
  },
};
</script>

<style lang="scss">
.modal-title{
  font-weight: 700;
}
  svg {
    height: 100%;
    margin-bottom: 50px;
    .province{
      text{
        font-size: 30px !important;
        
      }
    }
  }
  .disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .svg_zoon {
    cursor: pointer;
    &:hover {
      path {
        fill: #fff;
        transition: 0.5s;
      }
    }
  }
  .popup {
    overflow: hidden;
    @media (max-width: 468px) {
      width: 100%;
    }
    &.show {
      display: block;
    }
    &_title {
      border-bottom: 2px solid #142a4e;
    }
    &_content {
      max-width: 100%;
      overflow: hidden;
      background: #fff;
    }
    &_item {
      display: flex;
      padding: 10px;
      flex-direction: column;
      background: none;
      &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.22);
      }
      
      @media (min-width: 576px) {
        align-items: center;
        flex-direction: row;
        p {
          width: calc(100% - 100px);
          padding-right: 10px;
          text-align: center;
        }
      }
    }
    svg {
      height: auto;
    }
  }

  .popup_item {
    a {
      text-align: center;
      font-size: 16pt;
      font-weight: 700;
      padding-top: 5px;
      color: #142a4e;
      border: 1px solid #142a4e;
      font-family: "khmer os";
    }
  }

  th {
    font-weight: normal;
  }

.content-header {
  padding: 10px 0.5rem !important;
  background: #ededed;
}

@media (min-width: 800px) {
  .col-xxl-8 {
    flex: 0 0 66.666667% !important;
    max-width: 66.666667% !important;
  }
  .btn-block {
    width: 20px !important;
  }
}
.modal-body {
  ul {
    list-style: upper-roman;
  }
}
</style>