import router from '@/router';

const lands = {
  namespaced: true,
  state: {
    lands: [],
    land: [],
    allLands: {},
    mainLands: [],
    districts: [],
    associateLand: [],
    documents: [],
    errors: []
  },
  mutations: {
    /*****
     * set land 
     */
    setLand(state, land) {
      state.land = land
    },
    /*****
     * set lands 
     */
    setLands(state, lands) {
      state.lands = lands
    },
    /*****
     * set all land without sub land 
     */
    setAllLands(state, allLands) {
      state.allLands = allLands;
    },
    /*****
     * set mainLand 
     */
    setMainLand(state, mainLands) {
      state.mainLands = mainLands;
    },
    /*****
     * set districts 
     */
    setDistrict(state, districts) {
      state.districts = districts;
    },
    /*****
     * set land by province with sub land
     */
    setAssociateLand(state, associateLand) {
      state.associateLand = associateLand;
    },
    /*****
     * set document 
     */
    setDocLand(state, documents) {
      state.documents = documents
    },
    /*****
     * set error 
     */
    setErrors(state, error) {
      state.errors = error;
    }
  },
  getters: {
    province: (state) => state.province
  },
  actions: {
    /***************
     * get all lands with child
     */
    getLands({
      commit
    }, locale) {
      this.dispatch("loading", true);
      this.dispatch("storage/getOwner");
      var owner = this.state.storage.owner;
      axios.get(`/lands?owner=${owner}&lang=${locale}`)
        .then((res) => {
          commit('setLands', res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dispatch("loading", false);
        });
    },
    /***************
     * get all main land
     */
    getMainLands({
      commit
    }) {
      this.dispatch("loading", true);
      this.dispatch("storage/getOwner");
      var owner = this.state.storage.owner;
      axios.get(`/mainLands?owner=${owner}`)
        .then((res) => {
          commit('setMainLand', res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dispatch("loading", false);
        });
    },
    /***************
     * get all lands
     */
    getAllLands({
      commit
    }) {
      this.dispatch("loading", true);
      axios.get(`allLands`).then((res) => {
          var datas = res.data.map((item) => ({
            [item.id]: {
              name: item.land_name_kh,
              status: item.status
            }
          }));
          let newData = {};
          datas.forEach((item) => {
            let key = Object.keys(item)[0];
            newData[key] = item[key];
          });
          commit('setAllLands', newData);
        })
        .catch((err) => {})
        .finally(() => {
          this.dispatch("loading", false);
        })
    },
    /***************
     * search lands
     */
    seachLand({
      commit
    }, [province_id, districtID, keyword, locale]) {
      this.dispatch("loading", true);
      this.dispatch("storage/getOwner");
      var owner = this.state.storage.owner;
      if (province_id == "all" || !province_id) {
        var province = 0;
        var province_link = "all";
      } else {
        var province = province_id;
        var province_link = province_id;
      }
      if (districtID == "all" || !districtID) {
        var district = 0;
        var district_link = "all";
      } else {
        var district = districtID;
        var district_link = districtID;
      }
      var provinceID = province;
      var districtID = district;
      axios.get(`/lands?lang=${locale}&province=${provinceID}&district=${districtID}&keyword=${keyword}&owner=${owner}`)
        .then((res) => {
          commit('setLands', res.data);
          router.push(`?province=${province_link}&district=${district_link}&keyword=${keyword}`).catch(() => {});
        })
        .catch((err) => {})
        .finally(() => {
          this.dispatch("loading", false);
        });
    },
    /***************
     * get single land
     */
    getLand({
      commit
    }) {
      this.dispatch("loading", true);
      this.dispatch("storage/getOwner");
      var owner = this.state.storage.owner;
      axios.get(`/lands/${router.currentRoute.params.id}?owner=${owner}`)
        .then((res) => {
          commit('setLand', res.data);
          commit('setDocLand', res.data.doc_files);
          this.dispatch('district/getDisByProvince', res.data.province_id);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dispatch("loading", false);
        });
    },
    /***************
     * get province by on change province
     */
    onChangeProvince({
      commit
    }, id) {
      this.dispatch("storage/getOwner");
      var owner = this.state.storage.owner;

      axios.get(`/district/${id}?owner=${owner}`)
        .then((res) => {
          commit('setDistrict', res.data);
        })
        .catch((err) => {
          this.error = true;
        });
    },
    /***************
     * get land by province with sub land
     */
    getAssociateLand({
      commit
    }, id) {
      this.dispatch("loading", true);
      this.dispatch("storage/getOwner");
      var owner = this.state.storage.owner;
      axios.get(`land/associate-land/${id}?owner=${owner}`)
        .then((res) => {
          commit('setAssociateLand', res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dispatch("loading", false);
        });
    },
    /*************
     * Add new record
     */
    onCreate({
      commit
    }, [data, locale]) {
      console.log(data);
      this.dispatch('loading', true);
      axios.post(`/lands?lang=${locale}`, data)
        .then((res) => {
          let lastItem = res.data[0].id;
          router.push({
            path: `/land/edit/${lastItem}`
          });
          commit('setErrors', []);
        })
        .catch((err) => {
          commit('setErrors', err.response.data.errors);
        })
        .finally(() => {
          this.dispatch('loading', false);
        });
    },
    /*********
     * update record
     */
    onUpdate({
      commit
    }, [data, locale]) {
      this.dispatch('loading', true);
      axios.put(`/lands/${router.currentRoute.params.id}?lang=${locale}`, data)
        .then((res) => {
          router.push({
            path: "/lands"
          })
          this.dispatch('lands/getLands');
          commit('setErrors', []);
        })
        .catch((err) => {
          commit('setErrors', err.response.data.errors);
        })
        .finally(() => {
          this.dispatch('loading', false);
        });
    },
    /************************
     * distroy provice one record
     */
    onDelete({
      commit
    }, id) {
      swal({
        content: {
          element: "input",
          attributes: {
            type: "password",
            placeholder: "Type your password"
          },
        },
        inputPlaceholder: "Enter your password",
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
      }).then((value) => {
        if(value) {
          this.dispatch("loading", true);
          axios.delete(`/lands/${id}?password=${value}`).then((res) => {
            if (res.data.status == 200) {
              swal({
                icon: "success",
                dangerMode: true,
                title: "Deleted Successfully"
              })
              this.dispatch('lands/getLands');
            } else {
              swal({
                icon: "error",
                dangerMode: true,
                title: "Deleted Fail",
                text: "Wrong Password"
              })
            }

          }).finally(() => {
            this.dispatch("loading", false);
          });
        }
      });
    }
  },
};
export default lands;