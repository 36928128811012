const documents = {
  namespaced: true,
  state: {
    document: [],
    errors: [],
    progress: 0,
    max: 100,
    isUpload: false,
    popup:false,

  },
  mutations: {
    /*****
    * set land 
    */
    setDocument(state, document) {
      state.document = document
    },
    /*****
    * set error 
    */
    setErrors(state, error) {
      state.errors = error;
    },
    /*****
     * set loading in loader
     */
    setUpload(state, data) {
      state.isUpload = data;
    },
    /*****
     * set progress
     */
    setProgress(state, progress) {
      state.progress = progress;
    },
    /*****
     * set max
     */
    setMax(state, max) {
      state.max = max;
    },
    /******
     * set modal show or hide
     */
    setPopup(state,popup){
      state.popup=popup;
    }
  },
  getters: {
  },
  actions: {
    upload({ commit }, upload) {
      commit("setUpload", upload);
    },
    process({ commit }, process) {
      commit("setProgress", process);
    },
    /*****
     * on click popup
     */
    onPopup({commit},popup){
      commit("setPopup",popup);
    },

    /***************
     * get documents
    */
    getDocument({ commit }, id) {
      this.dispatch("loading", true);
      this.dispatch("storage/getOwner");
      var owner = this.state.storage.owner;
      axios.get(`document-files/${id}?owner=${owner}`)
        .then((res) => {
          commit('setDocument', res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dispatch("loading", false);
        });
    },

    /***************
     * add new record
    */
    onCreate({ commit }, data) {
      commit("setUpload", true);
      axios.post(`document-files`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (e) {
          commit("setProgress", parseInt(Math.round(e.loaded / e.total) * 100));
        }
      }).then((res) => {
        this.dispatch('lands/getLand');
        commit('setPopup',false);
        commit('setErrors', []);
      }).catch((err) => {
        commit('setErrors', err.response.data.errors);
      }).finally(() => {
        commit("setUpload", false);
        commit("setProgress", 0);
      })
    },
    /***************
     * update record
    */
    onUpdate({ commit }, [id, formData, locale]) {
      commit("setUpload", true);
      axios.post(`document-files/update-file/${id}?lang=${locale}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (e) {
          commit("setProgress", parseInt(Math.round(e.loaded / e.total) * 100));
        }.bind(this)
      }).then((res) => {
        this.dispatch('lands/getLand');
        commit('setPopup',false);
        commit('setErrors', []);
      }).catch((err) => {
        commit('setErrors', err.response.data.errors);
      }).finally(() => {
        commit("setUpload", false);
        commit("setProgress", 0);
      })
    },

    saveMultiFile({ commit }, [id, formData]) {
      // this.dispatch("loading", true);
      axios.post(`document-item/${id}`, formData).then(() => {
        // this.dispatch('lands/getLand');
        // commit('setPopup',false);
        // location.reload();
        commit('setErrors', []);
      }).catch((err) => {
        console.log("error");
        // commit('setErrors', err.response.data.errors);
      }).finally(() => {
        this.dispatch("loading", false);
      })
    },
    /************************
     * distroy provice one record
     */
    onDelete({ commit }, id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.dispatch("loading", true);
          axios.delete(`/document-files/${id}`).then((res) => {
            this.dispatch('lands/getLand');
          }).catch((err) => {
            console.log(err);
          }).finally(() => {
            this.dispatch("loading", false);
          });
        }
      });
    }
  }
}
export default documents;