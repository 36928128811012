<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6 mt-2">
            <h4 class="m-0 text-dark">{{ dis_name }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>
                  {{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/districts" class="text-dark">
                  {{ $t("title.districts") }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("title.detail") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-12 mx-auto mt-5">
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th>{{ $t('land-detail.no') }}</th>
                  <th v-column-sortable:[`land_name_${$i18n.locale}`]>
                    {{ $t("title.name") }}

                  </th>
                  <th v-column-sortable:square_meter>
                    {{ $t('land-detail.description.size') }}
                  </th>
                  <th v-if="hasPermission('update-land') || hasPermission('delete-land')">{{ $t('title.action') }}</th>
                </tr>
              </thead>
              <tbody class="text-center" v-for="(l, index) in district.list_land" :key="l.id">
                <tr>
                  <td class="text-left">{{ index + 1 }}</td>
                  <td>
                    {{ l[`land_name_${$i18n.locale}`] }}
                  </td>
                  <td>
                    <span v-if="l.square_meter">
                      {{ l.square_meter | comma }}
                    </span>
                  </td>
                  <td v-if="hasPermission('update-land') || hasPermission('delete-land')">
                    <router-link :to="{ name: 'landEdit', params: { id: l.id } }" class="btn btn-primary btn-sm mr-2"
                      v-if="hasPermission('update-land')">
                      <i class="fas fa-edit"></i>
                    </router-link>
                    <button class="btn btn-sm btn-danger" @click="deleteLand(l.id)" :disabled="l.children != 0"
                      v-if="hasPermission('delete-land')">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr v-for="(c, c_index) in l.children" :key="c_index">
                  <td class="text-left">{{ (index + 1) }}.{{ c_index + 1 }}</td>
                  <td>
                    {{ c[`land_name_${$i18n.locale}`] }}
                  </td>
                  <td>
                    <span v-if="c.square_meter">
                      {{ c.square_meter | comma }}
                    </span>
                  </td>
                  <td v-if="hasPermission('update-land') || hasPermission('delete-land')">
                    <router-link :to="{ name: 'landEdit', params: { id: c.id } }" class="btn btn-primary btn-sm mr-2"
                      v-if="hasPermission('update-land')">
                      <i class="fas fa-edit"></i>
                    </router-link>
                    <button class="btn btn-sm btn-danger" @click="deleteLand(c.id)" v-if="hasPermission('delete-land')">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="district.length == 0">
                <tr>
               
                  <td colspan="4" class="text-center">
                    {{ $t('title.no_result') }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import columnSortable from 'vue-column-sortable';
import hasPer from '../../services/permission';
export default {
  data() {
    return {
      lands: [],
      dis_name: '',
    }
  },
  computed: {
    permission: {
      get() {
        return this.$store.state.permission
      }
    },
    district: {
      get() {
        return this.$store.state.district.district;
      }
    },
  },

  mounted() {
    this.getDistrict();
    /*******
    * check permission
    */
    if (this.permission.length) {
      if (!this.hasPermission('detail-district')) {
        this.$router.push({ path: "/dashboard" });
      }
    }

  },

  methods: {
    /*******
     * get a permission for check
     */
    hasPermission(per) {
      return hasPer(this.permission, per);
    },
    getDistrict() {
      this.$store.dispatch('district/getDistrict');
    },

    async deleteLand(id) {
      await swal({
        content: {
          element: "input",
          attributes: {
            placeholder: "Type your password"
          },
        },
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((value) => {
        var data = { password: value };
        axios.delete(`/lands/${value}/${id}`, data).then((res) => {
          if (res.data.status == 200) {
            swal({
              icon: "success",
              dangerMode: true,
              title: "Deleted Successfully"
            }).then((willDelete) => {
              if (willDelete) {
                this.getDistrict();
              }
            })

          } else {
            swal({
              icon: "error",
              dangerMode: true,
              title: "Deleted Fail",
              text: "Wrong Password"
            })
          }

        });
      });
    },
    orderBy(fn) {
      this.lands.sort(fn);
    }
  },
  directives: {
    columnSortable,
  }
}
</script>

<style>
</style>