
import Vue from "vue";
import Vuex from "vuex";
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);
import router from '@/router';
import lands from "./modules/lands";
import province from "./modules/province";
import country from "./modules/country";
import storage from "./modules/localStorage";
import district from "./modules/district";
import documents from "./modules/documents";
import user from "./modules/user";
import role from "./modules/role";
import auth from "./modules/auth";
import permissions from "./modules/permissions";
import hasPer from "../services/permission";

Vue.use(Vuex);


export default new Vuex.Store({

  state: {
    //loader
    isLoading: false,
    //user login
    user: [],
    //user permission
    permission: {},
    errors:[],
    role:'',
    isUpdate: false,
    message:'',
    owner:'',
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    /**************
     * set loading in loader
     */
    setLoading(state, data) {
      state.isLoading = data;
    },
    /**************
     * set loading for update profile
     */
    setUdateProfile(state, data) {
      state.isUpdate = data;
    },
    /**************
     * set loading for update profile
     */
     setMessage(state, data) {
      state.message = data;
    },
    /*******
     * Set User permission
     */
    setPermission(state, permission) {
      state.permission = permission;
    },
    /**************
     * Set Error respone data
     */
    setErrors(state,error){
      state.errors=error;
    },
    setRole(state, role) {
      state.role = role;
    },
    setOwner(state){
      state.owner=localStorage.getItem('owner')
    }
  },
  actions: {
    loading({ commit }, loading) {
      commit("setLoading", loading);
    },
    updating({ commit }, updating) {
      commit("setUdateProfile", updating);
    },
    // Logout remove cookies
    onLogout({ commit }) {
      this.dispatch("loading", true);
      axios.post("/logout")
        .then((res) => {
          $cookies.remove("token");
          router.push({ path: "/login" });
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.dispatch("loading", false);
          localStorage.removeItem('owner');
        });
    },
    /********
     * Get a user logged
     */
    getUser({ commit }) {
      this.dispatch("loading", true);
      axios.get("user")
        .then((res) => {
          commit("setUser", res.data.user);
          commit("setRole", res.data.role);
          commit("setPermission", res.data.permission);

          var md_permission = hasPer(res.data.permission, 'md-owner');
          var ceo_permission = hasPer(res.data.permission, 'ceo-owner');
          if (md_permission && ceo_permission) {
            if(!localStorage.getItem('owner')){
              localStorage.setItem('owner', 'md');
            }
          }
          commit('setOwner');
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          this.dispatch("loading", false);
        });
    },
    onUpdateProfile({ commit }, data) {
      axios.post(`/profile`, data)
        .then((res) => {
          this.dispatch('getUser');
          commit('setErrors', []);
          commit('setMessage', res.data.success);
          this.dispatch("updating", false);
          setTimeout(() => {
            commit('setMessage', '');
          }, 800);
        })
        .catch((err) => {
          commit('setErrors', err.response.data.errors);
        });
    }
  },
  /********
   * import from folder modules
   */
  modules: {
    lands:lands,
    province:province,
    country:country,
    storage:storage,
    district:district,
    documents:documents,
    users:user,
    roles:role,
    auth:auth,
    permissions:permissions
  },
});
