<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0">{{ $t('Add Land Type') }}</h4>
          </div>
            <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">{{
                  $t("title.home")
                }}</router-link>
              </li>
               <li class="breadcrumb-item">
                <router-link to="/landTypes">
                {{$t("title.land_type")}}</router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ $t('Add Land Type') }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <section class="content position-relative mt-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-10 mx-auto">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">{{ $t('title.land_type') }}</h3>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-6">
                    <label for="">{{ $t('title.name') }}</label>
                    <input type="text" class="form-control" v-model="landType.name">
                    <span class="text-danger">{{ err_name }}</span>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary" @click="createLandType">{{ $t('title.save')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      landType: {
        name:''
      },
      err_name: ''
    }
  },

  mounted() {
    if($cookies.get("token")) {
      axios.get("/user").then((res) => {
        let no_role = res.data['roles'].length == 0;
        if(no_role) {
          this.$router.push({ path: '/dashboard' });
        }

        var permiss = res.data['roles'][0]['permissions'];
        var datas = permiss.map((per) => ({[per.name]: per.name }));
      
        let newData = {};
        datas.forEach((item) => {
          let key = Object.keys(item)[0];
          newData[key] = item[key];
        });

        var exists = Object.keys(newData).some((k) => {
          return newData[k] == "create-land-type";
        });
        
        if(exists == false) {
          this.$router.push({ path: '/dashboard' });
        }
        
      })
      .catch((err) => { });
    }
  },

  methods: {
    createLandType() {
      axios.post(`/land-type`, this.landType)
      .then((res) => {
        let lastItem = res.data['id'];
        this.$router.push({ path: `/landType/edit/${lastItem}` });
      })
      .catch((err) => {
        if(err.response.data.errors.name){
          this.err_name = err.response.data.errors.name[0];
        }
      })
    }
  }

}
</script>

<style>

</style>