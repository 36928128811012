<template>
  <div v-if="isLoading" class=" text-center mt-5">
    <b-spinner  label="Text Centered"></b-spinner>
  </div>
  <div v-else>
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm fixed-top">
      <div class="container-fluid mx-3">
        <router-link to="/dashboard" class="navbar-brand ">
          <img alt="Logo" src="../assets/images/logo_main.png" />
        </router-link>
      </div>
    </nav>

    <section class="content position-relative pt-5 mt-5">
      <div class="open-doc" v-if="isOpenDoc">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div class="">
                  <p class="mt-2">{{ $t('land-detail.land_name') }} : {{ land[`land_name_${$i18n.locale}`] }}</p>
                  <p class="mt-2">{{ $t('land-detail.document') }} : {{ doc_link[`title_${$i18n.locale}`] }}</p>
                </div>
                <a @click="close"><i class="fa fa-times-circle" aria-hidden="true"></i></a>
              </div>
              
              <button @click="download({ id: doc_link.id, label: doc_link[`title_${$i18n.locale}`] + `.pdf` })"
                  class="btn btn-primary btn-sm mb-3 pdf-download">
                  <i class="fa fa-download" aria-hidden="true"></i> Download
                  <span v-if="isDownload">
                    <b-spinner label="Text Centered"></b-spinner>
                  </span>
                </button>
              <embed :src='path + doc_link.link_file + `#toolbar=0`' type="application/pdf" width="100%" height="800px">  
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" v-else>
        <div class="row my-5">
          <Slide />
          <div class="col-lg-4 col-12 mt-lg-0 mt-4">
            <h4 class="mb-2 pb-2">
              <strong>{{ $t("title.land_news") }}</strong>
            </h4>
            <table class="table">
              <tr>
                <th class="mr-1">
                  លេខប័ណ្ណកាន់កាប់ :
                </th>
                <td v-if="land.land_title_number">
                  {{ land.land_title_number }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("Name") }} : </th>
                <td v-if="[`location_${$i18n.locale}`]">
                 {{ land[`land_name_${$i18n.locale}`] }}
                </td>
              </tr>
              <tr >
                <th class="mr-1">
                  {{ $t("land-detail.description.size") }} : 
                </th>
                <td v-if="land.square_meter">
                  {{ land.square_meter | comma }} ម៉ែត្រការ៉េ
                </td>
              </tr>
            </table>
            <div class="content"></div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-lg-8 col-12 overflow-auto">
            <table class="table table-striped table-bordered">
              <thead>
                <tr class="text-center">
                  <th style="width: 100px">{{ $t("land-detail.no") }}</th>
                  <th>{{ $t("title.land_reference") }}</th>
                  <th style="width: 100px">{{ $t("land-detail.download") }}</th>
                </tr>
              </thead>
              
              <tbody>
                <tr v-for="(doc, index) in landType" :key="doc.id">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="td-name">{{ doc[`title_${$i18n.locale}`] }}</td>
                  <td>
                    <!-- <router-link :to="{ path: `/show/${token}/${land.id}/doc/${index}`}" class="btn btn-primary btn-sm">
                    {{ $t('button.open') }}
                    </router-link> -->
                    <button @click="openDoc(index)" class="btn btn-primary btn-sm">
                      {{ $t('button.open') }}
                    </button>
                  </td>
                </tr>
              </tbody>
              
              <tbody v-if="landType == 0">
                <tr>
                  <td colspan="5" class="text-center">
                    {{ $t("title.no_result") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-if="land.link_google_map">
            <iframe id="map" :src="`https://www.google.com/maps/d/embed?mid=${land.url_map}&hl=${$i18n.locale}&ehbc=${land.ehbc}&ll=${land.ll}&z=19`" ></iframe>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Slide from "../components/land-slide.vue";
import UrlForShow from "../services/show-url";

export default {
  components: {
    Slide
  },
  data() {
    return {
      land: {
        url_map: '',
        ll:'',
        ehbc:'',
      },
      doc_link:[],
      landType: [],
      token: 'TTL0rfxOQ6PCgFVEvK24Bm6dhDYGilV',
      path: UrlForShow(),
      isLoading: false,
      isOpenDoc: false,
      isDownload:false
    }
  }, 

  mounted() {
    this.getOneLand();
  },

  methods: {
    getOneLand() {
      this.isLoading = true;
      axios.get(`/guestShow/${this.$route.params.id}`).then((res) => {
        if(res.data == 'no-data') {
          this.$router.push({ path: '/login' });
        }
        this.land = res.data;
        this.landType = res.data.doc_files;
        
        var map_url = res.data['link_google_map'];
        let params = new URL(map_url).searchParams;
        this.land.ll = params.get('ll');
        this.land.ehbc = params.get('ehbc');
        var map_parameter = map_url.split("mid=")[1].split('&');
        this.land.url_map = map_parameter[0];
      })
      .catch((err) => {})
      .finally(() => {
        this.isLoading = false;
      })
    },

    close() {
      this.isOpenDoc = false;
    },

    openDoc(index) {
      this.isOpenDoc = true;
      var land_doc = this.landType[index];
      this.doc_link = land_doc;
    },

    download({ id, label }) {
      this.isDownload = true;
      axios.get(`/download/${id}`, {
        responseType: "blob"
      }).then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        this.$refs['preview'].hide();
      })
      .catch((err) =>{})
      .finally(() => {
        this.isDownload = false
      })
    },

    dateTime(value) {
      return moment(value).format("DD-MMM-YY");
    },
  }
}
</script>

<style>
  .open-doc {
    width: 100%;
    display: block;
    background: #fff;
    z-index: 9;
    position: absolute;
    top: 100%;
    transition:top 1s linear;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 0px 7px 0px rgb(0 0 0);
  }
</style>