<template>
  <div class="position-relative">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">{{ country[`name_${this.$i18n.locale}`] }}</h4>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                <i class="fas fa-home mr-1"></i> {{ $t('title.home')}}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/countries" class="text-dark font-weight-bold">
                  {{ $t('title.country') }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t('title.detail') }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="loader" v-if="isLoading"></div>

    <section class="content position-relative">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-12 mx-auto mt-5">
             <table class="table table-bordered table-striped">
               <thead>
                <tr class="text-center">
                  <th style="width: 100px">{{ $t('land-detail.no') }}</th>
                  <th v-column-sortable:name_en>{{ $t('district_name') }}</th>
                  <th>{{ $t('title.land_count') }}</th>
                  <th class="w-25" v-if="hasPermission('detail-province') || hasPermission('update-province') || hasPermission('delete-province')">
                    {{ $t('title.action') }}
                  </th>
                </tr>
               </thead>
               <tbody>
                <tr class="text-center" v-for="(p, index) in provinces" :key="p.id">
                  <td>{{ index+1 }}</td>
                  <td>{{ p[`name_${$i18n.locale}`] }}</td>
                  <td>{{ p.lands_count }}</td>
                  <td v-if="hasPermission('detail-province') || hasPermission('update-province') || hasPermission('delete-province')">
                    <router-link :to="{ name: 'provinceDetail', params: {id: p.id} }" 
                      class="btn btn-info btn-sm mr-2"
                      v-if="hasPermission('detail-province')"
                    >
                      {{ $t('title.detail') }}
                    </router-link>
                    <router-link :to="{ name: 'province', params: {id: p.id} }" 
                      class="btn btn-primary btn-sm"
                      v-if="hasPermission('update-province')"
                    >
                      <i class="fas fa-edit"></i>
                    </router-link>
                    <button class="btn btn-danger btn-sm ml-2 show_confirm" 
                      @click="deleteProvince(p.id)" :disabled="p.lands_count != 0"
                      v-if="hasPermission('delete-province')"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
                <!-- <tr v-if="!provinces.length" class="text-center">
                  <td colspan="3">{{ $t('title.no_result') }}</td>
                </tr> -->
               </tbody>
             </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import columnSortable from 'vue-column-sortable';
import hasPer from "../../services/permission";

export default {
  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    /******
     * permission data
     */
    permission: {
      get() {
        return this.$store.state.permission;
      },
    },

    /******
     * country data
     */
    country: {
      get() {
        return this.$store.state.country.country;
      },
    },

    /******
     * provinces data
     */
    provinces: {
      get() {
        return this.$store.state.country.provinces;
      },
    }
  },

  mounted() {
    this.getProvinces();
    if($cookies.get("token")) {
      axios.get("/user").then((res) => {
        let no_role = res.data['roles'].length == 0;
        if(no_role) {
          this.$router.push({ path: '/dashboard' });
        }
        
        var permiss = res.data['roles'][0]['permissions'];
        var datas = permiss.map((per) => ({[per.name]: 1 }));
        let newData = {};
        datas.forEach((item) => {
          let key = Object.keys(item)[0];
          newData[key] = item[key];
        });
        
        this.permission = newData;

        var permission = permiss.map((per) => ({[per.name]: per.name }));
        let newPer = {};
        permission.forEach((item) => {
          let key = Object.keys(item)[0];
          newPer[key] = item[key];
        });

        var exists = Object.keys(newPer).some((k) => {
          return newPer[k] == "detail-country";
        });

        if(exists == false) {
          this.$router.push({ path: '/dashboard' });
        }
      })
      .catch((err) => { });
    }
  },

  methods: {
    /***
     * Method for check permission
     */
    hasPermission($per) {
      return hasPer(this.permission,$per);
    },

    /**********
     * get provinces by country id
     */

    getProvinces() {
      this.$store.dispatch("country/getCountry");
    },

    deleteProvince(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete(`/provinces/${id}`).then((res)=>{
            let i = this.provinces.map(data => data.id).indexOf(id);
            this.provinces.splice(i, 1);
          })
        }
      });
    },

    orderBy(fn) {
      this.provinces.sort(fn);
    }
  },
  directives: {
    columnSortable,
  }
}
</script>

<style>

</style>