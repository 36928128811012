<template>
  <div class="login">
    <div class="login_content">
      <div class="logo text-center">
        <img alt="Logo" src="../../assets/images/logo_main.png">
      </div>
      <div class="text-center pt-3">
        <label for="">Reset Password</label>
      </div>
      <div class="login_form">
        <div class="alert alert-success" v-if="status">{{status}}</div>
        <div class="form-group">
          <label for="email" class="d-block">{{ $t('title.email')}}</label>
          <input type="email" v-model="email" placeholder="user@example.com" autocomplete="off">
          <p class="text-center text-danger" v-if="errors.email">{{errors.email[0]}}</p>
        </div>
        <div class="mt-4 text-center">
          <button type="submit" @click="forgetPassword" class="w-auto">Send Password Reset Link</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      email:"",
    }
  },
  computed:{
    errors:{
      get(){
        return this.$store.state.auth.errors;
      }
    },
    status:{
      get(){
        return this.$store.state.auth.status;
      }
    }
  },
  methods:{
    forgetPassword(){
      this.$store.dispatch('auth/onForgetPassword',{email:this.email});
    }
  }

}
</script>

<style>

</style>