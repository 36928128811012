<template>
  <div class="login">
    <!-- <loader v-if="isLoader1"></loader> -->
    <div class="login_content">
      <div class="logo text-center">
        <img alt="Logo" src="../../assets/images/logo_main.png" />
      </div>
      <div class="login_form">
        <form @submit.prevent="doLogin">
          <div class="form-group">
            <label for="email" class="d-block">Email</label>
            <input type="email" placeholder="user@example.com" v-model="formLogin.email" />
              <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span>
          </div>
          <div class="form-group position-relative">
            <label for="password" class="d-block">Password</label>
            <input v-if="showPassword" type="text" class="input" v-model="formLogin.password" />
            <input v-else type="password" class="input" v-model="formLogin.password" placeholder="******" />
            <span class="text-danger" v-if="errors.password">{{ errors.password[0] }}</span>
            <div class="control">
              <button class="button w-auto" type="button" @click="toggleShow">
                <span class="icon is-small is-right">
                  <i class="fas" :class="{
                    'fa-eye-slash': showPassword,
                    'fa-eye': !showPassword,
                  }"></i>
                </span>
              </button>
            </div>
          </div>
          <div class="mt-4 text-center">
            <p>
              <router-link to="/forget-password">Forget Password</router-link>
            </p>
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UrlForShow from "../../services/show-url";
import loader from "../../components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      showPassword: false,
      password: null,
      formLogin: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    permission:{
      get(){
        return this.$store.state.permission;
      }
    },
    role: {
      get() {
        return this.$store.state.role;
      }
    },
    errors:{
      get(){
        return this.$store.state.auth.errors;
      }
    }
  },

  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    doLogin() {
      this.$store.dispatch('auth/onLogin',this.formLogin);
    },
  },
};
</script>

<style lang="scss">
.login {
  height: 100vh;
  position: relative;
  background: #fff;

  &_content {
    max-width: 100%;
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &_form {
    padding: 20px;
    margin-top: 30px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(20, 42, 78, 0.2);

    input {
      width: 100%;
      padding: 5px 15px;
      border-radius: 20px;
      border: 1px solid #444;
      background: #fff;
    }

    button {
      width: 150px;
      padding: 5px 15px;
      outline: none;
      border: 1px solid #444;
      background: #fff;
      border-radius: 20px;

      &:hover {
        color: #fff;
        background: #142a4e;
      }
    }
  }

  .control {
    position: absolute;
    top: 40px;
    right: 0;
  }
}
</style>