<template>
  <div v-if="isLoading" class="text-center">
    <b-spinner label="Text Centered"></b-spinner>
  </div>
  <div v-else>
    <ul class="navbar-nav mr-auto ml-4">
      <li class="nav-item">
        <router-link to="/dashboard" class="nav-link">{{ $t("title.home") }}</router-link>
      </li>
      <li class="nav-item" v-if="hasPermission('land-lists')">
        <router-link to="/lands" class="nav-link">{{ $t("title.list_land") }}</router-link>
      </li>
      <!-- <li class="nav-item" v-if="permission['land-type-lists'] == 1">
        <router-link to="/landTypes" class="nav-link">{{ $t("title.land_type") }}</router-link>
      </li> -->
      <!-- <li class="nav-item" v-if="hasPermission('country-lists')">
        <router-link to="/countries" class="nav-link">{{ $t("title.country") }}</router-link>
      </li>
      <li class="nav-item" v-if="hasPermission('province-lists')">
        <router-link to="/provinces" class="nav-link">{{ $t("title.province") }}</router-link>
      </li>
      <li class="nav-item" v-if="hasPermission('district-lists')">
        <router-link to="/districts" class="nav-link">{{ $t("title.districts") }}</router-link>
      </li>
      <li class="nav-item" v-if="hasPermission('user-lists')">
        <router-link to="/users" class="nav-link">{{ $t("title.user") }}</router-link>
      </li> -->
    </ul>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      // permission:{},
    }
  },
  computed: {
    permission() {
        return this.$store.state.permission;
    },
    user() {
        return this.$store.state.user;
    },

  },
  mounted() {

  },


  methods: {
    hasPermission(per) {
      if (this.permission.length) {
        return this.permission.includes(per);
      }
    }
  }

};
</script>

<style>
</style>