<template>
  <div class="position-relative ">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-sm-12 ">
            <h4 class="m-0 font-weight-bold">{{ $t("title.list_land") }}</h4>
          </div>
          <div class="col-lg-6 col-sm-12 ml-auto">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link class="text-dark font-weight-bold" :to="{name:'Dashboard'}">{{$t("title.home")}}</router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ $t("title.list_land") }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <section class="content my-4">
      <div class="shadow m-2 p-2">
        <div class="row d-flex align-items-center">
          <div class="col-sm-2 col-12 mr-auto">
            <div class="form-group mb-0 my-2" v-if="hasPermission('create-land')">
              <router-link to="/land/create" class="btn btn-sm btn-primary rounded-pill px-4 py-2">
                <i class="nav-icon fa fa-plus-square mr-2"></i>
                {{ $t("button.add_new") }}
              </router-link>
            </div>
          </div>

          <div class="col-sm-10 d-flex justify-content-end">
            <div class="search d-flex align-items-center overflow-auto">
              <h4 class="text-dark mr-4 align-items-center pt-2">{{ $t('title.search') }}: </h4>
              <select class="custom-select search_item rounded-pill" v-model="searchForm.id"
                @change.prevent="getDisByProvince($event)">
                <option selected disabled :value="null">
                  {{ $t("title.select_province") }}
                </option>
                <option value="all">{{ $t("title.all_province") }}</option>
                <option v-for="(p, index) in provinces" :key="index" :value="p.id">
                  {{ p[`name_${$i18n.locale}`] }}
                </option>
              </select>

              <select class="custom-select search_item rounded-pill mx-2" v-bind:class="{ isProvince }"
                v-model="searchForm.districts_id">
                <option :value="null" selected v-if="isProvince">{{ $t("title.no_result") }}</option>
                <option selected disabled :value="null" v-else>{{ $t('title.select_district') }}</option>
                <option value="all">{{ $t("title.all_district") }}</option>
                <option v-for="(d, index) in districts" :key="index" :value="d.id">
                  {{ d[`name_${$i18n.locale}`] }}
                </option>
                <option :value="district_ID" v-if="isProvince">{{ $t("title.no_result") }}</option>
              </select>
              <input type="text" class="form-control search_item rounded-pill mr-2" placeholder="ស្វែងរក"
                v-model="searchForm.keyword" />
              <div>
                <button class="btn btn-primary rounded-pill px-4 mw-50" @click.prevent="search">
                  {{ $t("title.search") }}
                </button>
              </div>
            </div>
          </div>
        </div>

          <div class="overflow-auto table-relative">
            <table class="table table-bordered">
              <thead>
                <tr class="text-center">
                  <th style="width: 100px">{{ $t("land-detail.no") }}</th>
                  <th style="width: 100px">លេខប័ណ្ណកាន់កាប់</th>
                  <th v-column-sortable:[`land_name_${$i18n.locale}`]>
                    {{ $t("title.name") }}
                  </th>
                  <th v-column-sortable:[`location_${$i18n.locale}`]>
                    {{ $t("land-detail.localtion") }}
                  </th>
                  <th v-column-sortable:province_id>
                    {{ $t("land-detail.province") }}
                  </th>
                  <th v-column-sortable:districts_id>
                    {{ $t("title.districts") }}
                  </th>
                  <th v-column-sortable:square_meter>
                    {{ $t("land-detail.description.size") }}
                  </th>
                  <th v-if="hasPermission('detail-land','update-land','delete-land')">
                    {{ $t("title.action") }}
                  </th>
                </tr>
              </thead>
              <tbody v-for="(l, cc) in lists" :key="cc">
                <tr class="text-center">
                  <td class="text-left">{{ currentPage * 10 - 10 + (cc + 1) }}</td>
                  <td class="text-left">{{ l.land_title_number }}</td>
                  <td class="text-left">
                    <span v-if="[`land_name_${$i18n.locale}`]">
                      {{ l[`land_name_${$i18n.locale}`] }}
                    </span>
                  </td>
                  <td class="text-left">
                    <span v-if="[`location_${$i18n.locale}`]">
                      {{ l[`location_${$i18n.locale}`] }}
                    </span>
                  </td>
                  <td>
                    {{ l[`province_name_${$i18n.locale}`] }}
                  </td>
                  <td>
                    {{ l[`district_name_${$i18n.locale}`] }}
                  </td>
                  <td>
                    <span v-if="l.square_meter">
                      {{ l.square_meter | comma }} m<sup>2</sup>
                    </span>
                  </td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Action
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div class="dropdown-item">
                          <span v-if="l.status == 0">
                            <button class="btn btn-sm btn-primary w-100" @click.prevent="popupShare(l.id, 0)">
                              {{ $t('title.share') }}
                            </button>
                          </span>
                          <span v-else>
                            <button class="btn btn-sm btn-success w-100" @click.prevent="popupShare(l.id, 1)">
                              {{ $t('title.stop-share') }}
                            </button>
                          </span>
                        </div>
                        <div class="dropdown-item" v-if="hasPermission('detail-land')">
                          <router-link :to="{ name: 'detailLand', params: { id: l.id } }"
                            class="btn btn-info btn-sm mr-2 w-100" v-if="hasPermission('detail-land')">
                            {{ $t("title.detail") }}
                          </router-link>
                        </div>
                        <div class="dropdown-item" v-if="hasPermission('update-land')">
                          <router-link :to="{ name: 'landEdit', params: { id: l.id } }"
                            class="btn btn-primary btn-sm mr-2 w-100" v-if="hasPermission('update-land')">
                            {{ $t('title.update') }}
                          </router-link>
                        </div>
                        <div class="dropdown-item" v-if="hasPermission('delete-land')">
                          <button class="btn btn-sm btn-danger w-100" @click="deleteLand(l, 1)"
                            v-if="hasPermission('delete-land')">
                            {{ $t('title.delete') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="text-center" v-for="(c, index) in l.children" :key="index">
                  <td class="text-left">{{  currentPage * 10 - 10 + (cc + 1) }}.{{ index + 1 }}</td>
                  <td class="text-left">{{ c.land_title_number }}</td>
                  <td class="text-left">
                    <span v-if="[`land_name_${$i18n.locale}`]">
                      {{ c[`land_name_${$i18n.locale}`] }}
                    </span>
                  </td>
                  <td class="text-left">
                    <span v-if="[`location_${$i18n.locale}`]">
                      {{ c[`location_${$i18n.locale}`] }}
                    </span>
                  </td>
                  <td>
                    {{ c[`province_name_${$i18n.locale}`] }}
                  </td>
                  <td>
                    {{ c[`district_name_${$i18n.locale}`] }}
                  </td>
                  <td>
                    <span v-if="c.square_meter">
                      {{ c.square_meter | comma }} m<sup>2</sup>
                    </span>
                  </td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Action
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div class="dropdown-item">

                          <span v-if="c.status == 0">
                            <button class="btn btn-sm btn-primary w-100" @click.prevent="popupShare(c.id, 0)">
                              {{ $t('title.share') }}
                            </button>
                          </span>
                          <span v-else>
                            <button class="btn btn-sm btn-success w-100" @click.prevent="popupShare(c.id, 1)">
                              {{ $t('title.stop-share') }}
                            </button>
                          </span>
                        </div>
                        <div class="dropdown-item" v-if="hasPermission('detail-land')">
                          <router-link :to="{ name: 'detailLand', params: { id: c.id } }"
                            class="btn btn-info btn-sm mr-2 w-100" v-if="hasPermission('detail-land')">
                            {{ $t("title.detail") }}
                          </router-link>
                        </div>
                        <div class="dropdown-item" v-if="hasPermission('update-land')">
                          <router-link :to="{ name: 'landEdit', params: { id: c.id } }"
                            class="btn btn-primary btn-sm mr-2 w-100" v-if="hasPermission('update-land')">
                            {{ $t('title.update') }}
                          </router-link>
                        </div>
                        <div class="dropdown-item" v-if="hasPermission('delete-land')">
                          <button class="btn btn-sm btn-danger w-100" @click="deleteLand(c, 0)"
                            v-if="hasPermission('delete-land')">
                            {{ $t('title.delete') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="!lists.length">
                <tr class="text-center">
                  <td colspan="11">
                    <div class="text-center" v-if="isLoading">
                      <b-spinner label="Text Centered"></b-spinner>
                    </div>
                    <div v-else>
                      {{ $t("title.no_result") }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="lists != ''">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"  @input="redirectPage">
              </b-pagination>
            </div>
          </div>

      </div>
      <b-modal ref="land_share" :title="land_name" hide-footer centered>
        <span v-if="isStopshare" style="word-break: break-all;">{{ host }}/show/{{ token }}/{{ land_ID }}</span>

        <div class="d-flex justify-content-end mt-4">
          <p class="mb-0 text-right" v-if="!isStopshare">
            <span class="btn btn-sm btn-info" @click.prevent="shareURL(land_ID)">Share <i class="fas fa-share"></i></span>
          </p>
          <p class="mb-0 text-right" v-if="isStopshare">
            <span class="btn btn-sm btn-info" @click.prevent="copyURL">Copy <i class="far fa-copy ml-1"></i></span>
          </p>
          <div class="ml-2" v-if="isStopshare">
            <button class="btn btn-sm btn-success" @click="onStopSheare(land_ID)">{{ $t('title.stop-share') }}</button>
          </div>
        </div>
        <p style="opacity:0; height: 0">
          <input type="hidden" id="copy_url" :value="`${host}/show/${token}/${land_ID}`">
        </p>
        <span class="text-success bg-success text-center d-block rounded" v-if="isMessage">
          {{ message }}
        </span>
      </b-modal>
    </section>
  </div>
</template>

<script>
import columnSortable from "vue-column-sortable";
import hasPer from "../../services/permission";
import { mapState } from "vuex";

export default {
  data() {
    return {
      listLands: [],
      currentPage: localStorage.getItem("page-land")?localStorage.getItem("page-land"):1,
      perPage: 10,
      isLoading: false,
      isProvince: true,
      district_ID: null,
      land: {
        status: null
      },
      searchForm: {
        id: null,
        districts_id: null,
        keyword: ""
      },
      host: '',
      land_ID: null,
      land_name: '',
      message: '',
      token: 'TTL0rfxOQ6PCgFVEvK24Bm6dhDYGilV',
      isStopshare: false,
      isMessage: false,
      provinceID: null,
      getOwner: localStorage.getItem('owner')
    };
  },

  computed: {
    ...mapState({
      permission: state => state.permission,
      lands: state => state.lands.lands,
      allLands: state => state.lands.allLands,
      provinces: state => state.province.provinces,
      districts: state => state.lands.districts,
    }),


    lists() {
      const items = this.lands;
      return items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },

    totalRows() {
      return this.lands.length;
    }
  },

  mounted() {
    this.getProvinces();
    this.getAllLands();

    this.host = window.location.origin;
    let params = new URL(location.href).searchParams;
    this.searchForm.id = params.get("province");
    this.searchForm.districts_id = params.get("district");
    this.searchForm.keyword = params.get("keyword");
    if (
      this.searchForm.id ||
      this.searchForm.districts_id ||
      this.searchForm.keyword
    ) {
      this.search();
    } else {
      this.getLands();
    }

    if (this.permission.length) {
      if (!this.permission.includes('land-lists')) {
        this.$router.push({ path: '/dashboard' });
      }
    }
  },

  methods: {

    redirectPage(v){
      localStorage.setItem("page-land",v);
    },

    hasPermission(per) {
      return hasPer(this.permission, per)
    },

    getProvinces() {
      this.$store.dispatch('province/getProvinces');
    },

    getLands() {
      this.$store.dispatch('lands/getLands', this.$i18n.locale);
    },

    getAllLands() {
      this.$store.dispatch('lands/getAllLands');
    },

    getDisByProvince(event) {
      var id = event.target.value;
      this.searchForm.districts_id = null;
      if (id == 'all' || !id) {
        this.isProvince = true;
        this.provinceID = this.$route.query['province'];
      } else {
        this.isProvince = false;
        this.provinceID = id;
      }

      this.$store.dispatch('lands/onChangeProvince', this.provinceID);
    },

    search() {
      if (this.searchForm.keyword) {
        var keyword = this.searchForm.keyword;
      } else {
        var keyword = "";
      }

      if (this.searchForm.id == "all" || !this.searchForm.id) {
        var province = 0;
      } else {
        var province = this.searchForm.id;
      }

      if (this.searchForm.districts_id == "all" || !this.searchForm.districts_id) {
        var district = 0;
      } else {
        var district = this.searchForm.districts_id;
      }

      var province_id = province;
      var districtID = district;
      this.$store.dispatch('lands/seachLand', [province_id, districtID, keyword, this.$i18n.locale]);
    },

    popupShare(id, status) {
      this.land_ID = id;
      this.land_name = this.allLands[id]['name'];
      if (status == 1) {
        this.isStopshare = true;
      } else {
        this.isStopshare = false;
      }
      this.$refs['land_share'].show();
      this.message = "";

    },

    shareURL(id) {
      this.land_name = this.allLands[id]['name'];
      axios.post(`landShare/${id}`, { status: 1 }).then((res) => {
        this.isStopshare = true;
        this.getLands();
        this.getAllLands()
      }).catch((err) => { })
        .finally(() => {
          this.isLoading = false
        })
    },

    /**
     * Stop Sharing for client
     */
    onStopSheare(id) {
      this.land_ID = id;
      this.land_name = this.allLands[id]['name'];
      this.$refs['land_share'].show();
      axios.post(`landShare/${id}`, { status: 0 }).then((res) => {
        this.getLands();
        this.getAllLands()
        this.isStopshare = false;
        this.$refs['land_share'].hide();
      }).catch((err) => {

      }).finally(() => {
        this.isLoading = false;
      })
    },

    copyURL() {
      let copy_url = document.querySelector('#copy_url');
      copy_url.setAttribute('type', 'text');
      copy_url.select();
      try {
        var successful = document.execCommand('copy');
        this.message = successful ? 'Copied' : 'unsuccessful';
        setTimeout(() => {
          this.isStopshare = true;
          this.getLands();
          this.$refs['land_share'].hide();

        }, 2000);

      } catch (err) {
        alert('Oops, unable to copy');
      }
      copy_url.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    deleteLand(items, main) {
      if (main) {
        if (items.children.length == 0) {
          this.$store.dispatch('lands/onDelete', items.id);
        } else {
          swal({
            title: "Cannot delete this land !",
            text: "You must to remove the land child.",
            icon: "error",
          })
        }
      } else {
        this.$store.dispatch('lands/onDelete', items.id);
      }
    },

    async orderBy(fn) {
      await this.lands.sort(fn);
    },
  },

  directives: {
    columnSortable,
  },
};
</script>

<style lang="scss">

.my-maps {
  iframe {
    width: 100%;
    height: 700px;
  }
}

svg {
  height: 100%;
  margin-bottom: 50px;

  @media (min-width: 992px) {
    height: 80vh;
    margin-bottom: 0;
  }
}

th {
  white-space: pre;
}

#land_list th {
  white-space: pre;
}

.isProvince {
  opacity: 0.6;
  pointer-events: none;
}

.search {

  .form-control,
  .custom-select {
    border: 1px solid #444;
  }
}

.div-table {
  border: 1px solid #444;
}

.div-tr {
  display: flex;
}

.div-th {
  // flex: 150px 2 0;
  padding: 10px;
  text-align: center;
  border: 1px solid #444;

  &:first-child {
    width: 150px;
  }

  &:nth-child(2) {
    width: 570px;
  }

  &:nth-child(n+3) {
    width: 200px;
  }

  &:last-child {
    width: 250px;
  }
}
</style>