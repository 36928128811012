import router from "@/router";
const user = {
    namespaced: true,
    state: {
        users: [],
        user: [],
        errors: []
    },
    mutations: {
        /****
         * set all data in users state
         */
        setUsers(state, users) {
            state.users = users
        },
        /****
         * set a data in user state
         */
        setUser(state, user) {
            state.user = user;
        },
        /***
         * set Error is stat
         */
        setErrors(state, errors) {
            state.errors = errors
        }
    },
    getters: {
    },
    actions: {
        /***
         * get all data from users
         */
        getUsers({ commit }) {
            this.dispatch("loading", true);
            axios.get('/users').then((res) => {
                commit('setUsers', res.data)
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.dispatch("loading", false);
            })
        },
        /******
         * add new record to users
         */
        onCreate({ commit }, data) {
            this.dispatch("loading", true);
            axios.post('/users', data).then((res) => {
                if (res.status == 200) {
                    router.push({ path: "/users" })
                    commit('setErrors', []);
                }
            }).catch((err) => {
                commit('setErrors', err.response.data.errors);
            }).finally(() => {
                this.dispatch("loading", false);
            });
        },
        /***
         * get single data from user
         */
        getUser({ commit }) {
            this.dispatch("loading", true);
            axios.get(`/users/${router.currentRoute.params.id}`).then((res) => {
                var user={
                    name:res.data.name,
                    email:res.data.email,
                    roles:res.data.roles[0].id,
                }
                commit('setUser', user)
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.dispatch("loading", false);
            })
        },
        /*****
         * update a record
         */
        onUpdate({ commit }, data) {
            this.dispatch("loading", true);
            axios.put(`/users/${router.currentRoute.params.id}`, data).then((res) => {
                commit('setErrors', []);
                router.push({ name: 'Users' });
            }).catch((err) => {
                commit('setErrors', err.response.data.errors);
            }).finally(() => {
                this.dispatch("loading", false);
            });
        },
        /*****
         * update a record
         */
        onDelete({ commit }, id) {
            this.dispatch("loading", true);
            axios.delete(`/users/${id}`).then((res) => {
                this.dispatch('users/getUsers');
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.dispatch("loading", false);
            })
        }


    }
}
export default user;