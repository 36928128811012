<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="m-0 text-dark">User Role</h4>
            <div class="d-block">
              <router-link to="/role/create" class="btn btn-sm btn-primary mt-2" v-if="hasPermission('create-role')">
                <i class="nav-icon fa fa-plus-square mr-2"></i>
                {{ $t("button.add_new") }}
              </router-link>
            </div>
          </div>
          <div class="col-sm-6 text-right">
            <ol class="breadcrumb justify-content-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard" class="text-dark font-weight-bold">
                  <i class="fas fa-home mr-1"></i>{{ $t("title.home") }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/users" class="text-dark font-weight-bold">
                  <i class="fas fa-users mr-1"></i>{{ $t("title.user") }}
                </router-link>
              </li>
              <li class="breadcrumb-item active">User Role</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-12 mx-auto mt-5">
            <table id="user_list" class="table table-bordered table-striped">
              <thead>
                <tr class="text-center">
                  <th>{{ $t("land-detail.no") }}</th>
                  <th v-column-sortable:name>{{ $t("title.name") }}</th>
                  <th v-if="
                    hasPermission('edit-role') ||
                    hasPermission('delete-role')
                  ">
                    {{ $t("title.action") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="(r, index) in roles" :key="r.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ r.name }}</td>
                  <td v-if="hasPermission('edit-role') || hasPermission('delete-role')">
                    <router-link :to="{ name: 'roleEdit', params: { id: r.id } }" class="btn btn-primary btn-sm"
                      v-if="hasPermission('edit-role')">
                      <i class="fas fa-edit"></i>
                    </router-link>
                    <button class="btn btn-danger btn-sm ml-2 show_confirm" @click="deleteRole(r.id)" v-if="
                      hasPermission('delete-role') &&
                      r.display_name != 'super-user'
                    ">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr class="text-center" v-if="!roles.length">
                  <td colspan="11" v-if="isLoading">
                    <b-spinner label="Spinning"></b-spinner>
                  </td>
                  <td colspan="4" v-else>{{ $t("title.no_result") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import columnSortable from "vue-column-sortable";
import hasPer from "../../../services/permission";

export default {
  data() {
    return {
      isLoading: false
    };
  },

  computed: {
    permission: {
      get() {
        return this.$store.state.permission;
      }
    },
    roles: {
      get() {
        return this.$store.state.roles.roles;
      }
    },
  },

  mounted() {
    this.getRoles();
    /******
     * check permission
     */
    if (this.permission.length) {
      if (!this.hasPermission('role-lists')) {
        this.$router.push({ path: "/dashboard" });
      }
    }
  },

  methods: {
    hasPermission(per) {
      return hasPer(this.permission, per)
    },

    getRoles() {
      this.$store.dispatch('roles/getRoles');
    },

    deleteRole(id) {
      swal({
        title: "Are you sure?",
        text: "If you delete this, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.dispatch('roles/onDelete',id);
        }
      });
    },

    orderBy(fn) {
      this.roles.sort(fn);
    },
  },

  directives: {
    columnSortable,
  },
};
</script>

<style>
</style>