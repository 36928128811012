<template>
  <div >
    <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm fixed-top">
      <div class="container-fluid mx-3">
        <router-link to="/dashboard" class="navbar-brand ">
          <img alt="Logo" src="../assets/images/logo_main.png" />
        </router-link>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Left Side Of Navbar -->
          <menu-bar />

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav d-flex gap-2" style="margin-left: auto;">
            <li class="nav-link">
              <router-link to="/setting" title="Setting" v-if="hasPermission('country-lists','province-lists','district-lists','user-lists')"> 
                <svg xmlns="http://www.w3.org/2000/svg" style="height: 20px;" height="1em" viewBox="0 0 512 512">
                  <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
              </router-link>
            </li>
            <li class="nav-item d-flex align-items-center" v-if="hasPermission('md-owner') && hasPermission('ceo-owner')">
              {{ $t('Land Owner') }}:
              <b-dropdown id="dropdown-1" :text="select_owner" class="m-md-2" variant="outline-primary">
                <div v-for="o in Owner" :key="o.id">
                  <b-dropdown-item @click=onChangeOwner(o.name)>{{ o.display_name }}</b-dropdown-item>
                </div>
              </b-dropdown>
            </li>
            <li class="d-flex align-items-center gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" style="height: 20px; width:20px; margin:0" height="1em" viewBox="0 0 512 512">
                <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/></svg>
              <b-dropdown id="dropdown-1" :text="lang" class="m-md-2" variant="outline-primary">
                <b-dropdown-item @click="changeLang('kh')">ខ្មែរ </b-dropdown-item>
                <b-dropdown-item @click="changeLang('en')">EN </b-dropdown-item>
              </b-dropdown>
            </li>
            <li class="nav-item dropdown">
              <b-dropdown :text="`${user.name}`" class="m-md-2">
                <b-dropdown-item @click="onLogout">Logout</b-dropdown-item>
                <b-dropdown-item :to="{ path: `/profile` }">Profile</b-dropdown-item>
              </b-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <main class="py-4 mt-5">
      <router-view name="MainView"></router-view>
    </main>
  </div>
</template>

<script>
import MenuBar from '../components/menu-bar.vue';
import Sidebar from "../components/sidebar-menu.vue";
import hasPer from '../services/permission';

export default {

  components: {
    Sidebar,
    MenuBar,
  },

  data() {
    return {
      langs: {
        kh: "ខ្មែរ",
        en: "en",
      },
      search: null,
      checkOwner: '',
      Owner: [
        { id: 1, name: 'ceo', display_name: 'Chea Huy' },
        { id: 2, name: 'md', display_name: 'Jenny Chea' },

      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user
      }
    },
    permission() {
        return this.$store.state.permission
    },
    role: {
      get() {
        return this.$store.state.role
      }
    },
    lang: {
      get() {
        if (this.$i18n.locale == 'kh') {
          return 'ខ្មែរ'
        } else {
          return 'EN'
        }

      }
    },
    select_owner: {
      get() {
        if (this.$store.state.owner == 'ceo') {
          return "Chea Huy"
        } if (this.$store.state.owner == 'md') {
          return "Jenny Chea"
        }
      }
    },
  },

  mounted() {
    // this.getUser()
    if (localStorage.currentLang != null) {
      this.$i18n.locale = localStorage.currentLang;
    }
    this.checkOwner = localStorage.getItem('owner');
    this.$store.commit('setOwner');
  },

  methods: {
    /***
     * Check permission
     */
    hasPermission(per) {
      return hasPer(this.permission, per);
    },

    onLogout() {
      this.$store.dispatch('onLogout');
    },

    onChangeOwner(item) {
      if (item != localStorage.getItem('owner')) {
        this.$store.dispatch('loading', true);
        if (item != '') {
          this.checkOwner = item;
        } else {
          this.checkOwner = ''
        }
        localStorage.setItem('owner', this.checkOwner);
        location.reload();
      }
    },

    changeLang(lang) {
      if (lang != localStorage.currentLang) {
        this.$store.dispatch('loading', true);
        localStorage.currentLang = lang;
        location.reload();
      }
    },

    openOwner() {
      this.$refs["owner"].toggle();
    },
  },
};
</script>

<style lang="scss">
main{
  min-height:70vh;
}
.wrapp{
  max-height:100vh;
  background:gray;
}

.collapse1:not(.show) {
    display: none !important
}

nav.navbar.navbar-expand-md.navbar-light.bg-white.shadow-sm.fixed-top {
  z-index: 99999;
}

.content-header {
  position: sticky;
  top: 70px;
  // background-color:#fff !important;
  z-index: 9;
  // border: 1px #111 solid !important;
}

.lang-en {
  display: flex !important;
  align-items: center;
  margin-right: 10px;
  background: url(../assets/langs/icon_lang_en.png) no-repeat left center;
  background-size: 15px 15px;
}

.lang-kh {
  display: flex !important;
  align-items: center;
  margin-right: 10px;
  background: url(../assets/langs/icon_lang_km.png) no-repeat left center;
  background-size: 15px 15px;
}

.navbar-brand {
  img {
    width: 100px;
  }
}

.fa-sort,
.fa-sort-up,
.fa-sort-down {
  margin-left: 10px;
}

.logo {
  background: #fff;
  text-align: center;

  img {
    width: 150px;
    margin: 10px auto;
  }
}

.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover {
  .logo img {
    width: 150px;
  }
}

.sidebar-collapse {
  .logo img {
    width: 50px;
  }
}

body,
.content-wrapper {
  background: #ededed;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../assets/images/loading.jpg");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center 0;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
</style>